<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { debounce } from 'perfect-debounce';
import { computed, onMounted, reactive, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useTitle } from '@vueuse/core';
import { DateTime } from 'luxon';
import { Decimal } from 'decimal.js';
import {
  AppButton,
  AppLoader,
  FormTextarea,
  ProjectStatus,
  ProjectCheckpoints,
  ClientHeader,
  ClientNavigation,
  AppBox,
  AppBoxBody,
  FontIcon,
  AppTable,
  AppTableHead,
  AppTableTr,
  AppTableTh,
  AppTableBody,
  AppTableTd,
  UserTodos,
} from '@/components';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useClientStore from '@/store/ClientStore';
import useProjectStore from '@/store/ProjectStore';
import useProject from '@/composables/useProject';
import useInvoice from '@/composables/useInvoice';
import useAuthStore from '@/store/AuthStore';
import useTime from '@/composables/useTime';
import { IProjectDetailResource, IProjectRequestNotes, ProjectStatusType } from '@/types/Project';

const { uuid: clientUuid, id: projectId } = defineProps<{
  uuid: string;
  id: number;
}>();

const { convertMinutesToTime } = useTime();
const clientStore = useClientStore();
const { client, isUserPartOfTeam } = storeToRefs(clientStore);
const statusBar = useLoader({ useProgress: true });
const loader = useLoader();
const { t, d, n } = useI18n({ useScope: 'global' });
const { openReactivateProjectModal, openCancelOrRolloverProjectModal, openCreateFinalInvoiceModal } = useProject();
const { openFetchNotInvoicedModal } = useInvoice();

const form = reactive<IProjectRequestNotes>({
  notes: '',
});

const { authenticatedUser } = useAuthStore();
const projectStore = useProjectStore();
const { setProject } = projectStore;
const { project, isDraft, isActive, isCancelled } = storeToRefs(projectStore);

const noteAbortController = ref<AbortController | null>(null);

const details = ref<IProjectDetailResource>();

const onNotesChange = debounce(async () => {
  try {
    statusBar.start();
    if (noteAbortController.value) {
      noteAbortController.value.abort();
    }
    noteAbortController.value = new AbortController();
    await api.projects.update(form, clientUuid, projectId, { signal: noteAbortController.value.signal });
  } catch (error) {
    console.error(error);
  } finally {
    noteAbortController.value = null;
    statusBar.finish();
  }
}, 500);

async function getProject() {
  try {
    const response = await api.projects.get(clientUuid, projectId);
    setProject(response.data);
  } catch (error) {
    console.error(error);
  }
}

async function getProjectDetails() {
  try {
    const response = await api.projects.details(clientUuid, projectId);
    details.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  loader.start();
  await Promise.all([getProject(), getProjectDetails()]);
  if (project.value) {
    form.notes = project.value.notes;
  }
  loader.finish();
});

const title = useTitle(computed(() => project.value?.name ?? ''));

function onCancelOrRollover(type: 'cancel' | 'rollover') {
  openCancelOrRolloverProjectModal(
    type,
    {
      id: project.value.id,
      name: project.value.name,
      has_unfinished_tasks: project.value.has_unfinished_tasks,
      service: project.value.service,
    },
    clientUuid,
    {
      onDone(updatedProject) {
        if (updatedProject) {
          setProject(updatedProject);
        }
      },
    },
  );
}

function onReactivate() {
  openReactivateProjectModal({ id: project.value.id, name: project.value.name }, project.value.client.uuid, {
    onDone(reactivatedProject) {
      setProject(reactivatedProject);
    },
  });
}

function onFetchNotInvoiced() {
  openFetchNotInvoicedModal(
    {
      client: clientUuid,
      selectedProjects: [projectId],
    },
    {
      async onFetched() {
        //
      },
    },
  );
}

const showCheckpoints = computed(() => project.value?.checkpoints.length > 0 && !isDraft.value);

const avgRevenuePerHourPlanned = computed(() => {
  const result = Decimal.div(
    details.value?.invoiced.planned ?? 0,
    Decimal.div(Decimal.sum(details.value?.base_plan.planned, details.value?.additional.planned), 60),
  );
  if (result.isNaN() || !result.isFinite()) {
    return 0;
  }
  return result.toDecimalPlaces(2).toNumber();
});

const avgRevenuePerHourActual = computed(() => {
  const result = Decimal.div(
    details.value?.invoiced.actual ?? 0,
    Decimal.div(Decimal.sum(details.value?.base_plan.actual, details.value?.additional.actual), 60),
  );
  if (result.isNaN() || !result.isFinite()) {
    return 0;
  }
  return result.toDecimalPlaces(2).toNumber();
});

const preliminaryMarginPlanned = computed(() => {
  return Decimal.sum(details.value?.invoiced.planned ?? 0, details.value?.reported_time_cost.planned ?? 0)
    .toDecimalPlaces(2)
    .toNumber();
});

const preliminaryMarginActual = computed(() => {
  return Decimal.sum(details.value?.invoiced.actual ?? 0, details.value?.reported_time_cost.actual ?? 0)
    .toDecimalPlaces(2)
    .toNumber();
});

const preliminaryMarginPercentagePlanned = computed(() => {
  const result = Decimal.div(preliminaryMarginPlanned.value, details.value?.invoiced.planned ?? 0);
  if (result.isNaN() || !result.isFinite()) {
    return 0;
  }
  return result.mul(100).toDecimalPlaces(2).toNumber();
});

const preliminaryMarginPercentageActual = computed(() => {
  const result = Decimal.div(preliminaryMarginActual.value, details.value?.invoiced.actual ?? 0);
  if (result.isNaN() || !result.isFinite()) {
    return 0;
  }
  return result.mul(100).toDecimalPlaces(2).toNumber();
});

const totalMarginPlanned = computed(() => {
  return Decimal.sum(
    preliminaryMarginPlanned.value,
    details.value?.billed_expenses.planned ?? 0,
    details.value?.expenses.planned ?? 0,
    details.value?.estimated_invoices.planned ?? 0,
    details.value?.estimated_remaining_time_cost.planned ?? 0,
  )
    .toDecimalPlaces(2)
    .toNumber();
});

const totalMarginActual = computed(() => {
  return Decimal.sum(
    preliminaryMarginActual.value,
    details.value?.billed_expenses.actual ?? 0,
    details.value?.expenses.actual ?? 0,
    details.value?.estimated_invoices.actual ?? 0,
    details.value?.estimated_remaining_time_cost.actual ?? 0,
  )
    .toDecimalPlaces(2)
    .toNumber();
});

const totalMarginPercentagePlanned = computed(() => {
  const result = Decimal.div(
    totalMarginPlanned.value,
    Decimal.sum(details.value?.invoiced.planned ?? 0, details.value?.estimated_invoices.planned ?? 0),
  );
  if (result.isNaN() || !result.isFinite()) {
    return 0;
  }
  return result.mul(100).toDecimalPlaces(2).toNumber();
});

const totalMarginPercentageActual = computed(() => {
  const result = Decimal.div(
    totalMarginActual.value,
    Decimal.sum(details.value?.invoiced.actual ?? 0, details.value?.estimated_invoices.actual ?? 0),
  );
  if (result.isNaN() || !result.isFinite()) {
    return 0;
  }
  return result.mul(100).toDecimalPlaces(2).toNumber();
});
</script>

<template>
  <div class="container-fluid px-md-5">
    <ClientHeader :client="client" />
    <ClientNavigation :client="client" :is-user-part-of-team="isUserPartOfTeam" />
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <div v-else class="mb-4">
      <template v-if="project && details">
        <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-lg-between">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center mb-2 mt-4">
              <h2 class="mb-0" v-text="title" />
              <ProjectStatus class="mx-3" :status="project.status" />
            </div>
            <p>{{ client.name }}: <strong v-text="client.org_number" /></p>
          </div>
          <div class="d-flex flex-column flex-lg-row gap-2">
            <template v-if="isActive">
              <AppButton @click.prevent="onFetchNotInvoiced" light>
                <FontIcon name="receipt" />
                {{ t('project.index.invoice_until_today') }}
              </AppButton>
              <AppButton
                @click.prevent="
                  openCreateFinalInvoiceModal(
                    {
                      id: project.id,
                    },
                    project.client.uuid,
                  )
                "
                light
              >
                <FontIcon name="checklist" />
                {{ t('project.index.create_final_invoice') }}
              </AppButton>
              <AppButton color="danger" @click.prevent="onCancelOrRollover('cancel')">
                <FontIcon name="ban" />
                {{ t('project.index.cancel') }}
              </AppButton>
              <AppButton color="secondary" @click.prevent="onCancelOrRollover('rollover')">
                <FontIcon name="reload" />
                {{ t('project.index.rollover') }}
              </AppButton>
            </template>
            <AppButton color="secondary" v-if="isCancelled" @click.prevent="onReactivate">
              <FontIcon name="check" />
              {{ t('project.index.reactivate') }}
            </AppButton>
          </div>
        </div>

        <div class="row mb-4 d-flex">
          <!-- General -->
          <div :class="showCheckpoints ? 'col-md-6' : 'col-12'">
            <AppBox style="height: 100%">
              <AppBoxBody>
                <h2>{{ t('project.view.general') }}</h2>
                <div class="d-flex flex-column gap-2">
                  <!-- Status -->
                  <div class="row align-items-start">
                    <div class="col-3">
                      {{ t('project.attributes.status') }}
                    </div>
                    <div class="col">
                      {{ t(`project.statuses.${project.status}`) }}
                    </div>
                  </div>
                  <!-- Price type -->
                  <div class="row align-items-start">
                    <div class="col-3">
                      {{ t('project.attributes.price_type') }}
                    </div>
                    <div class="col">
                      {{ project.price_type ? t(`service.price_type.${project.price_type}`) : t('common.none') }}
                    </div>
                  </div>
                  <!-- Deadline -->
                  <div class="row align-items-start">
                    <div class="col-3">
                      {{ t('project.attributes.deadline_date') }}
                    </div>
                    <div
                      class="col"
                      :class="{
                        'text-danger':
                          project.deadline_date && DateTime.fromISO(project.deadline_date) < DateTime.now(),
                      }"
                    >
                      {{ project.deadline_date ? d(project.deadline_date) : t('common.none') }}
                    </div>
                  </div>
                  <!-- Tasks -->
                  <div class="row align-items-start" v-if="project.status === ProjectStatusType.Active">
                    <div class="col-3">
                      {{ t('project.view.tasks') }}
                    </div>
                    <div class="col" :class="project.has_unfinished_tasks ? 'text-danger' : 'text-success'">
                      {{
                        project.has_unfinished_tasks
                          ? t('project.view.there_are_unfinished_tasks')
                          : t('project.view.there_are_no_unfinished_tasks')
                      }}
                    </div>
                  </div>
                  <!-- Comment -->
                  <div class="row align-items-start">
                    <div class="col-3">
                      {{ t('project.view.comment') }}
                    </div>
                    <div class="col">
                      <FormTextarea rows="6" v-model.trim="form.notes" @input="onNotesChange" />
                    </div>
                  </div>
                </div>
              </AppBoxBody>
            </AppBox>
          </div>

          <!-- Checkpoints -->
          <div class="col-md-6" v-if="showCheckpoints">
            <AppBox>
              <AppBoxBody>
                <ProjectCheckpoints
                  :title="t('project.view.checkpoints')"
                  :checkpoints="project.checkpoints"
                  :client-uuid="clientUuid"
                />
              </AppBoxBody>
            </AppBox>
          </div>
        </div>

        <div class="row d-flex mb-4">
          <div class="col-md-6">
            <AppBox style="height: 100%">
              <AppBoxBody>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h2 class="mb-0">{{ t('project.details.time_summary') }}</h2>
                  <RouterLink
                    :to="{ name: 'client.reported_time', params: { uuid: clientUuid } }"
                    custom
                    v-slot="{ navigate, href }"
                  >
                    <a @click="navigate" :href="href" target="_blank">
                      <AppButton light size="small">
                        <FontIcon name="report" />
                        {{ t('project.details.open_reported_time') }}
                      </AppButton>
                    </a>
                  </RouterLink>
                </div>
                <AppTable hoverable bordered>
                  <AppTableHead>
                    <AppTableTr>
                      <AppTableTh style="width: 20%" />
                      <AppTableTh
                        style="width: 20%"
                        nowrap
                        class="text-right"
                        v-tooltip.bottom="t('project.details.tooltips.time_summary.planned')"
                      >
                        {{ t('project.details.planned') }}
                      </AppTableTh>
                      <AppTableTh
                        style="width: 20%"
                        nowrap
                        class="text-right"
                        v-tooltip.bottom="t('project.details.tooltips.time_summary.estimated')"
                      >
                        {{ t('project.details.estimated') }}
                      </AppTableTh>
                      <AppTableTh
                        style="width: 20%"
                        nowrap
                        class="text-right"
                        v-tooltip.bottom="t('project.details.tooltips.time_summary.actual')"
                      >
                        {{ t('project.details.actual') }}
                      </AppTableTh>
                      <AppTableTh style="width: 20%" nowrap class="text-right">
                        {{ t('project.details.diff') }}
                      </AppTableTh>
                    </AppTableTr>
                  </AppTableHead>
                  <AppTableBody>
                    <AppTableTr>
                      <AppTableTd nowrap>{{ t('project.details.base_plan') }}</AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ convertMinutesToTime(details.base_plan.planned) }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ convertMinutesToTime(details.base_plan.estimated) }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ convertMinutesToTime(details.base_plan.actual) }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{
                          convertMinutesToTime(
                            Decimal.sub(details.base_plan.actual, details.base_plan.planned).toNumber(),
                          )
                        }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr>
                      <AppTableTd nowrap>{{ t('project.details.additional') }}</AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ convertMinutesToTime(details.additional.planned) }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ convertMinutesToTime(details.additional.estimated) }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ convertMinutesToTime(details.additional.actual) }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{
                          convertMinutesToTime(
                            Decimal.sub(details.additional.actual, details.additional.planned).toNumber(),
                          )
                        }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr class="font-bold">
                      <AppTableTd nowrap>{{ t('project.details.total') }}</AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{
                          convertMinutesToTime(
                            Decimal.sum(details.base_plan.planned, details.additional.planned).toNumber(),
                          )
                        }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{
                          convertMinutesToTime(
                            Decimal.sum(details.base_plan.estimated, details.additional.estimated).toNumber(),
                          )
                        }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{
                          convertMinutesToTime(
                            Decimal.sum(details.base_plan.actual, details.additional.actual).toNumber(),
                          )
                        }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{
                          convertMinutesToTime(
                            Decimal.sum(
                              Decimal.sub(details.base_plan.actual, details.base_plan.planned),
                              Decimal.sub(details.additional.actual, details.additional.planned),
                            ).toNumber(),
                          )
                        }}
                      </AppTableTd>
                    </AppTableTr>
                  </AppTableBody>
                </AppTable>
                <AppTable class="mt-4" hoverable bordered>
                  <AppTableBody>
                    <AppTableTr>
                      <AppTableTd style="width: 80%" nowrap>{{ t('project.details.extra_time_billable') }}</AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ convertMinutesToTime(details.extra_time.billable) }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr>
                      <AppTableTd nowrap>{{ t('project.details.extra_time_not_billable') }}</AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ convertMinutesToTime(details.extra_time.not_billable) }}
                      </AppTableTd>
                    </AppTableTr>
                  </AppTableBody>
                </AppTable>
                <AppTable class="mt-4" hoverable bordered>
                  <AppTableBody>
                    <AppTableTr>
                      <AppTableTd style="width: 80%" nowrap>
                        {{ t('project.details.average_revenue_per_hour_planned') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right font-bold">
                        {{ n(avgRevenuePerHourPlanned, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr>
                      <AppTableTd nowrap>{{ t('project.details.average_revenue_per_hour_actual') }}</AppTableTd>
                      <AppTableTd nowrap class="text-right font-bold">
                        {{ n(avgRevenuePerHourActual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                  </AppTableBody>
                </AppTable>
              </AppBoxBody>
            </AppBox>
          </div>
          <div class="col-md-6">
            <AppBox style="height: 100%">
              <AppBoxBody>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h2 class="mb-0">{{ t('project.details.financial_summary') }}</h2>
                  <RouterLink
                    :to="{ name: 'client.billing', params: { uuid: clientUuid } }"
                    custom
                    v-slot="{ navigate, href }"
                  >
                    <a @click="navigate" :href="href" target="_blank">
                      <AppButton light size="small">
                        <FontIcon name="receipt" />
                        {{ t('project.details.open_invoice_overview') }}
                      </AppButton>
                    </a>
                  </RouterLink>
                </div>
                <AppTable hoverable bordered>
                  <AppTableHead>
                    <AppTableTr>
                      <AppTableTh style="width: 40%" />
                      <AppTableTh
                        style="width: 30%"
                        nowrap
                        class="text-right"
                        v-tooltip.bottom="t('project.details.tooltips.financial_summary.planned')"
                      >
                        {{ t('project.details.planned') }}
                      </AppTableTh>
                      <AppTableTh
                        style="width: 30%"
                        nowrap
                        class="text-right"
                        v-tooltip.bottom="t('project.details.tooltips.financial_summary.actual')"
                      >
                        {{ t('project.details.actual') }}
                      </AppTableTh>
                    </AppTableTr>
                  </AppTableHead>
                  <AppTableBody>
                    <AppTableTr v-tooltip.bottom="t('project.details.tooltips.invoiced')">
                      <AppTableTd nowrap>
                        {{ t('project.details.invoiced') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ n(details.invoiced.planned, 'currency') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ n(details.invoiced.actual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr v-tooltip.bottom="t('project.details.tooltips.reported_time_cost')">
                      <AppTableTd nowrap>
                        {{ t('project.details.reported_time_cost') }}
                      </AppTableTd>
                      <AppTableTd
                        nowrap
                        class="text-right"
                        :class="{ 'text-danger': details.reported_time_cost.planned < 0 }"
                      >
                        {{ n(details.reported_time_cost.planned, 'currency') }}
                      </AppTableTd>
                      <AppTableTd
                        nowrap
                        class="text-right"
                        :class="{ 'text-danger': details.reported_time_cost.actual < 0 }"
                      >
                        {{ n(details.reported_time_cost.actual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr class="font-bold" v-tooltip.bottom="t('project.details.tooltips.prel_margin')">
                      <AppTableTd nowrap>
                        {{ t('project.details.prel_margin') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ n(preliminaryMarginPlanned, 'currency') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ n(preliminaryMarginActual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr class="font-bold">
                      <AppTableTd nowrap>
                        {{ t('project.details.prel_margin_percentage') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">{{ preliminaryMarginPercentagePlanned }}%</AppTableTd>
                      <AppTableTd nowrap class="text-right">{{ preliminaryMarginPercentageActual }}%</AppTableTd>
                    </AppTableTr>
                  </AppTableBody>
                </AppTable>
                <AppTable class="mt-4" hoverable bordered>
                  <AppTableBody>
                    <AppTableTr v-tooltip.bottom="t('project.details.tooltips.billed_expenses')">
                      <AppTableTd style="width: 40%" nowrap>
                        {{ t('project.details.billed_expenses') }}
                      </AppTableTd>
                      <AppTableTd style="width: 30%" nowrap class="text-right">
                        {{ n(details.billed_expenses.planned, 'currency') }}
                      </AppTableTd>
                      <AppTableTd style="width: 30%" nowrap class="text-right">
                        {{ n(details.billed_expenses.actual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr>
                      <AppTableTd nowrap>{{ t('project.details.expenses') }}</AppTableTd>
                      <AppTableTd nowrap class="text-right" :class="{ 'text-danger': details.expenses.planned < 0 }">
                        {{ n(details.expenses.planned, 'currency') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right" :class="{ 'text-danger': details.expenses.actual < 0 }">
                        {{ n(details.expenses.actual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr v-tooltip.bottom="t('project.details.tooltips.planned_estimated_invoices')">
                      <AppTableTd nowrap>
                        {{ t('project.details.planned_estimated_invoices') }}
                      </AppTableTd>
                      <AppTableTd
                        nowrap
                        class="text-right"
                        :class="{ 'text-danger': details.estimated_invoices.planned < 0 }"
                      >
                        {{ n(details.estimated_invoices.planned, 'currency') }}
                      </AppTableTd>
                      <AppTableTd
                        nowrap
                        class="text-right"
                        :class="{ 'text-danger': details.estimated_invoices.actual < 0 }"
                      >
                        {{ n(details.estimated_invoices.actual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr v-tooltip.bottom="t('project.details.tooltips.estimated_remaining_time_cost')">
                      <AppTableTd nowrap>
                        {{ t('project.details.estimated_remaining_time_cost') }}
                      </AppTableTd>
                      <AppTableTd
                        nowrap
                        class="text-right"
                        :class="{ 'text-danger': details.estimated_remaining_time_cost.planned < 0 }"
                      >
                        {{ n(details.estimated_remaining_time_cost.planned, 'currency') }}
                      </AppTableTd>
                      <AppTableTd
                        nowrap
                        class="text-right"
                        :class="{ 'text-danger': details.estimated_remaining_time_cost.actual < 0 }"
                      >
                        {{ n(details.estimated_remaining_time_cost.actual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr class="font-bold" v-tooltip.bottom="t('project.details.tooltips.total_margin')">
                      <AppTableTd nowrap>
                        {{ t('project.details.total_margin') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ n(totalMarginPlanned, 'currency') }}
                      </AppTableTd>
                      <AppTableTd nowrap class="text-right">
                        {{ n(totalMarginActual, 'currency') }}
                      </AppTableTd>
                    </AppTableTr>
                    <AppTableTr class="font-bold">
                      <AppTableTd nowrap>{{ t('project.details.total_margin_percentage') }}</AppTableTd>
                      <AppTableTd nowrap class="text-right">{{ totalMarginPercentagePlanned }}%</AppTableTd>
                      <AppTableTd nowrap class="text-right">{{ totalMarginPercentageActual }}%</AppTableTd>
                    </AppTableTr>
                  </AppTableBody>
                </AppTable>
              </AppBoxBody>
            </AppBox>
          </div>
        </div>
        <div v-if="0" class="row d-flex mb-5">
          <div class="col-md-6">
            <UserTodos
              type="todos"
              :title="t('user.todos.todos.title')"
              :user-uuid="authenticatedUser.uuid"
              :filter="(todos) => todos.filter((todo) => todo.projectId === projectId)"
            />
          </div>
          <div class="col-md-6">
            <UserTodos
              type="infos"
              :title="t('user.todos.informations.title')"
              :user-uuid="authenticatedUser.uuid"
              :filter="(todos) => todos.filter((todo) => todo.projectId === projectId)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
