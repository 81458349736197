<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';

import api from '@/services/api';
import { AppButton, AppCloseButton, FormInput, FormLabel } from '@/components';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import { IOfficeResource, IOfficeRequestBody } from '@/types/Office';
import { IUserPreviewResource, UserRole, UserSystemRole } from '@/types/User';
import useUsers from '@/composables/useUsers';

type Props = {
  office: null | IOfficeResource;
};

const { office } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [office: IOfficeResource];
  updated: [office: IOfficeResource];
  closed: [];
}>();

const loading = ref(false);

const form = reactive<IOfficeRequestBody>({
  name: '',
  manager_uuid: '',
});
const tracker = useTrackChanges(form);

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();
const { getMergedUsersList } = useUsers();

const users = ref<IUserPreviewResource[]>([]);
const usersLoading = ref(false);

const disabled = computed(() => !(form.name && form.manager_uuid));

async function submit() {
  loading.value = true;
  try {
    if (office) {
      const response = await api.offices.update(office.id, form);
      emit('updated', response.data);
    } else {
      const response = await api.offices.store(form);
      emit('created', response.data);
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

async function getUsers() {
  try {
    usersLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data.some((user) => user.system_role === UserSystemRole.Manager)
      ? response.data.filter((user) => user.system_role === UserSystemRole.Manager)
      : response.data.filter((user) => user.role === UserRole.CustomerAdmin);
  } catch (error) {
    console.error(error);
  } finally {
    usersLoading.value = false;
  }
}

onMounted(async () => {
  form.name = office?.name ?? '';
  await getUsers();
  form.manager_uuid = office?.manager.uuid ?? '';
  tracker.commit();
});

onCtrlEnter(() => {
  if (!disabled.value) {
    if (tracker.isModified.value) {
      submit();
    } else {
      emit('cancel');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <h2 v-text="office ? t('office.edit.title', { name: office.name }) : t('office.create.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Manager -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="office_manager" required>{{ t('office.attributes.manager') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <VueSelect
                :clearable="false"
                v-model="form.manager_uuid"
                :options="getMergedUsersList(users, office?.manager)"
                :loading="usersLoading"
                :disabled="usersLoading"
                label="name"
                input-id="office_manager"
                :placeholder="t('common.select')"
                required
                :reduce="(option: IUserPreviewResource) => option.uuid"
              >
                <template #no-options>
                  {{ t('common.search') }}
                </template>
              </VueSelect>
            </div>
          </div>
        </div>
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="office_name" required>{{ t('office.attributes.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="office_name" required />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton v-if="office" class="mt-2 mt-sm-0" color="success" :loading="loading" :disabled="disabled">
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
