<template>
  <div style="padding: 10px 15px">
    <div class="row d-flex align-items-center">
      <div class="col-3 font-bold">
        <slot name="name" />
      </div>
      <div class="col-2 d-flex justify-content-start gap-2">
        <slot name="actions" />
      </div>
      <div class="col-2">
        <slot name="time" />
      </div>
      <div class="col-5">
        <slot name="progress" />
      </div>
    </div>
  </div>
</template>
