export default {
  index: {
    title: 'Progress',
    help: '',
    filters: {
      customer_resp_user: 'Kundansvarig',
      service: 'Tjänst',
      report_date: 'Rapportdatum',
      creation_date: 'Skapad efter',
      fy_end_date_from: 'Bokslutsdatum från',
      fy_end_date_to: 'Bokslutsdatum till',
      show_also_closed: 'Inkludera stängda',
      report_type: 'Rapporttyp',
      typeuser: 'Ansvarig',
      typeproject: 'Projekt',
    },
  },
  attributes: {
    total: 'Totalt',
    client: 'Kundnamn',
    client_responsible: 'Kundansvarig',
    project: 'Projekt',
    planned_time: 'Planerade timmar',
    reported_time: 'Rapporterade timmar',
    ready_gp_time: 'Färdig GP tid',
    diff_budget: 'Diff mot budget',
    diff_budget_client_resp: 'Diff KAs tid',
    readiness: 'Färdig %',
    number_of_clients: 'Kunder #',
    number_of_projects: 'Projekt #',
  },
  tooltip: {
    ready_gp_time: 'Tid på klara uppgifter som ingår i grundplanen.',
    percent_complete: 'Andel tid med klarmarkerade uppgifter jämfört med planerad tid',
    diff_budget: '',
  },
};
