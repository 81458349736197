import { Editor, EditorOptions } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import { onMounted, onUnmounted, ref } from 'vue';
import { defu } from 'defu';

export default function useFormEditor(options: Partial<EditorOptions> = {}) {
  const editor = ref<Editor>();

  onMounted(() => {
    editor.value = new Editor(
      defu(options, {
        extensions: [
          StarterKit.configure({
            code: false,
            codeBlock: false,
          }),
        ],
        editorProps: {
          attributes: {
            class: 'form-editor',
          },
        },
      }),
    );
  });

  onUnmounted(() => {
    editor.value?.destroy();
  });

  return {
    editor,
  };
}
