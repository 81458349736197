export default {
  index: {
    title: 'Projects',
    help: '',
    remove: 'Delete project',
    complete: 'Complete project',
    cancel: 'Cancel project',
    create_final_invoice: 'Create final invoice',
    invoice_until_today: 'Invoice up until today',
    rollover: 'Project rollover',
    reactivate: 'Reactivate project',
    filters: {
      name_or_client: 'Project name or client name',
      name: 'Project name',
      statuses: 'Status',
      user: 'Project responsible',
      services: 'Service',
      fiscal_year_end: 'Fiscal year end',
      team_member: 'Team member',
    },
    create_modal: {
      title: 'Please select the client for which you want to create a project',
      name: 'Client name',
    },
  },
  create: {
    title: 'Plan new project for {name}',
    help: '',
  },
  edit: {
    title: 'Edit project {name}',
    help: '',
  },

  empty: 'No projects',

  step: {
    service: 'Service',
    price: 'Price',
    team: 'Team',
    planning: 'Planning',
    activate: 'Summary',
  },

  // Attributes
  attributes: {
    name: 'Project name',
    status: 'Status',
    client: 'Client',
    user: 'Project responsible',
    service: 'Service',
    financial_year: 'Fiscal year',
    start_date: 'Project start date',
    end_date: 'Project end date',
    start_end_date: 'Start / End date',
    start_and_end_date: 'Project start & Project end date',
    deadline_date: 'Deadline date',
    price_type: 'Price type',
    billing_frequency: 'Billing frequency',
    annual_price: 'Project fee',
    only_invoice_part_of_year: 'Only invoice part of year',
    number_of_months_for_invoices_per_part_of_year: 'Number of months',
    estimating_price: "This year's fee",
    discount: 'Discount %',
    this_year_fee: "This year's fee",
    estimating_annual_fee: 'Estimated project fee',
    tasks_in_portal: 'Tasks in portal',
    other_team_members: 'Other team members',
  },
  placeholders: {
    name: 'Enter name',
  },

  invoice_schedule: 'Invoice schedule',

  price: {
    fixed: 'Fixed',
    hourly: 'Hourly',
  },

  billing_frequency: {
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    'semi-annually': 'Semi-annually',
    annually: 'Annually',
  },

  add_member_title: 'Select employee to add', // 'Select employee and project role to add'
  team_members: 'Team members',
  team_member_role: {
    team_member: 'Team member',
    responsible: 'Project responsible',
  },
  project_role: 'Project role',

  planning_table: {
    summary: 'Summary',
    total_time: 'Total time',
    total_price: 'Total fee',
    margin: 'Marg%',
    this_project: 'This project',
    next_project: 'Next project',
    hour: 'h',
  },

  tasks_table: {
    name: 'Task',
    frequency: 'Frequency',
    week: 'Week',
    deadline: 'Deadline',
    estimated_time: 'Estimated time',
    team_member: 'Team member',
    charge_separately: 'Charge separately',
    price_type: 'Price type',
    sub_label: 'Sublabel',
    amount: 'Amount, SEK',
    discount: 'Discount, %',
    add_label_on_tasks: 'Add sublabel on tasks',
    restore_plan_from_default: 'Restore plan from default',
  },

  task_details_table: {
    title: 'Task details',
    task: 'Task',
    frequency: 'Frequency',
    week: 'Week',
    deadline: 'Deadline',
    est_time: 'Est time',
    team_member: 'Team member',
    price_info: 'Price info',
    production_cost: 'Production cost',
    total_this_project: 'Total this project',
    total_next_project: 'Total next project',
  },

  fee_and_invoicing_table: {
    title: 'Fee & Invoicing',
    price_type: 'Price type',
    invoice_frequency: 'Invoice frequency',
    discount: 'Discount',
  },

  invoices_table: {
    title: 'Invoices',
    amount: 'Amount',
    fee_type: 'Fee type',
    month: 'Month',
  },
  project_earnings_estimate_title: {
    title: 'Project earnings estimate',
    first_year: 'This project',
    next_year: 'Next project',
    annual_price: 'Annual price',
    annual_hours: 'Annual hours',
    revenue_hour: 'Revenue/Hour',
    production_cost: 'Production cost',
    margin: 'Marg',
    margin_percent: 'Marg%',
  },

  statuses: {
    all: 'All',
    active: 'Active',
    draft: 'Draft',
    done: 'Done',
    cancelled: 'Cancelled',
    deleted: 'Deleted',
  },

  active_project: 'Activate project',
  base_price: 'Base fee',
  separate_price: 'Separate fee',
  estimated_hourly_fee: 'Hourly fee (estimated)',

  summaries: {
    invoiced: 'Invoiced',
    scheduled: 'Scheduled',
    fee_summary: 'Fee summary',
    difference: 'Difference',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit "{name}" project',
    destroy: 'Delete "{name}" project',
    view: 'View "{name}" project',
    plan: 'Plan "{name}" project',
    cancel: 'Cancel "{name}" project',
    rollover: 'Rollover "{name}" project',
    reactivate: 'Reactivate "{name}" project',
    copy_next_week: 'Copy selected week to next task',
    copy_next_weeks: 'Copy selected week to all future tasks',
    show_events: 'Show events',
    hide_events: 'Hide events',
    end_date:
      'Project end date refers to the date when the project is to be closed and no more time is to be reported. You will receive a reminder to close the project when this date has occurred.',
    deadline_date:
      'Delivery date refers to the day on which the customer requests a report or similar, before the end date of the project. This can be used e.g. if the customer has a specified date for the annual meeting, but you want to keep the project open for additional work after that date.',
    base_plan_task_type: 'This task is created from Activated project plan',
    inactive_base_plan_task_type:
      'This task is not included in the default tasks list for this service. Click activate to include in the project plan anyway',
    additional_task_type: 'This task is added after project was planned',
    service_task_type:
      'This task is generated from service task template. Click activate to include in the project plan',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete project',
      text: 'Are you sure you want to delete <strong>{name}</strong> project from the system?',
    },
    restore_default_tasks: {
      title: 'Restore default tasks',
      text: 'Are you sure you want to restore default tasks?',
    },
  },

  messages: {
    the_fee_summary_does_not_match: {
      title: 'The fee summary does not match the project fee.',
      text: 'Update scheduled fees or project fee?',
    },
    project_dates_updated: {
      title: 'Project dates have been updated.',
      text: 'You may need to change scheduled invoices and tasks. Continue with the planning of the project to make any necessary consequential changes.',
    },
    project_has_been_activated: {
      text: 'Project has been created/updated and tasks have been scheduled.',
    },
    project_should_be_reactivated_tasks: {
      text: "NOTE! You have updated the project's tasks. You need to go to the next step and Activate the updated plan for it to update the team's planning.",
    },
    project_should_be_reactivated_billing_plans: {
      text: "NOTE! You have updated the billing plans. You need to go to the next step and Activate the updated plan for it to update the team's planning.",
    },
    tasks_were_copied: {
      text: 'These tasks have been copied from the previous project. Any tasks that were added outside of the project plan to the previous project are listed below, but set as inactive and shown in gray text. The same applies if the firm has added new standard tasks to this service.</br>You can activate these details if you want them to be included in this project plan.',
    },
    new_tasks_in_service: {
      title: 'New task templates added as default for this service.',
      text: 'This task is generated from service task template. Click activate to include in the project plan.',
    },
    deleted_tasks_from_service: {
      text: 'This task is not included in the default tasks list for this service. Click activate to include in the project plan anyway.',
    },
    week_outside_project_dates: {
      title: "Tasks marked in red above have dates outside the project's start and/or end date.",
      text: 'This means that not all tasks will be planned.<br />Change the date to include the task in the project.',
    },
  },

  view: {
    general: 'General',
    overview: 'Overview',
    status: 'Status',
    deadline: 'Deadline',
    tasks: 'Tasks',
    comment: 'Comment',
    there_are_unfinished_tasks:
      'There are scheduled tasks that are not finished. Please review before closing the project.',
    there_are_no_unfinished_tasks: 'All tasks are completed',
    cancel_all_open_tasks: 'Cancel all open tasks',
    all_tasks_must_be_completed_to_close_project: 'All project tasks must be completed to close the project',
    checkpoints: 'Checkpoints',
  },
  details: {
    open_checkpoints_overview: 'Open Checkpoints overview',
    time_summary: 'Time summary',
    open_reported_time: 'Open reported time',
    financial_summary: 'Financial summary',
    open_invoice_overview: 'Open invoice overview',
    planned: 'Planned',
    estimated: 'Estimated',
    actual: 'Actual',
    diff: 'Diff',
    base_plan: 'Base plan',
    additional: 'Additional',
    total: 'Total',
    extra_time_billable: 'Extra time - billable',
    extra_time_not_billable: 'Extra time - not billable',
    average_revenue_per_hour_planned: 'Average revenue per hour (Planned)',
    average_revenue_per_hour_actual: 'Average revenue per hour (Actual)',
    invoiced: 'Invoiced',
    reported_time_cost: 'Reported time cost',
    prel_margin: 'Prel.margin',
    prel_margin_percentage: 'Prel.margin %',
    billed_expenses: 'Billed expenses',
    expenses: 'Expenses',
    planned_estimated_invoices: 'Planned/Estimated invoices',
    estimated_remaining_time_cost: 'Estimated remaining time cost',
    total_margin: 'Total margin',
    total_margin_percentage: 'Total margin %',
    tooltips: {
      invoiced: 'The invoiced amount for our work',
      reported_time_cost: 'Cost for the resources time calculated according to internal cost',
      prel_margin: 'The margin on own work (agency income)',
      billed_expenses: 'Re-invoiced purchases/expenses',
      planned_estimated_invoices:
        'Estimated remaining billing based on billing schedule and planned future time for work billed on hourly basis',
      estimated_remaining_time_cost:
        "Remaining cost for the resources' future planned time, calculated according to internal cost",
      total_margin: 'The project margin including external costs',
      time_summary: {
        planned: 'Planned time according to original plan, until today',
        estimated:
          'Estimated total time on the project based of project plan and any additional hours reported on the project, until today',
        actual: 'Reported hours on the project, until today',
      },
      financial_summary: {
        planned: 'Values based on original project plan, until today',
        actual: 'Actual values, until today',
      },
    },
  },

  cancel_modal: {
    title: 'Close project {name}',
    message:
      'By clicking "Confirm", the project will be closed and inactivated. You can reactivate project later if you need.',
    warning:
      'The project has tasks that have not been started or completed. By closing the project, started tasks will be marked as done, and unstarted tasks will be removed from the task list and cannot be reported time on.',
    start_new_project: 'Start planning a new project by copying the plan for project {name}',
    increase_prices: 'Increase any fixed prices automatically',
    percentage_price_increase: 'Enter the percentual price increase for fixed prices ',
    //
    final_invoice: 'Create final invoice (Invoice draft will open in new tab after clicking Confirm)',
    new_project_plan: 'Start planning a new project by copying the plan for project {name}',
    auto_price_increase: 'Increase any fixed prices automatically',
    price_increase_percent: 'Enter the percentual price increase for fixed prices ',
    close_current_project: 'Close current project',
  },
  reactivate_modal: {
    title: 'Reactivate project {name}',
    message: '',
  },
  rollover_modal: {
    title: 'Project rollover {name}',
  },
};
