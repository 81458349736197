<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { AppBox, AppBoxBody, UserNotificationItem } from '@/components';
import useUserTodosStore from '@/store/UserTodosStore';

const { t } = useI18n({ useScope: 'global' });
const { userTodosCount, userTodosLoading, userInfosCount, userInfosLoading } = storeToRefs(useUserTodosStore());

function scrollToTodos(type: 'todos' | 'infos') {
  document.getElementById(`user-${type}-box`)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
}
</script>

<template>
  <AppBox shadow>
    <AppBoxBody>
      <h3 class="mb-3">{{ t('user.notifications.title') }}</h3>
      <UserNotificationItem
        :loading="userTodosLoading"
        :count="userTodosCount"
        :text="t('user.notifications.todos')"
        @click="scrollToTodos('todos')"
      />
      <UserNotificationItem
        :loading="userInfosLoading"
        :count="userInfosCount"
        :text="t('user.notifications.infos')"
        @click="scrollToTodos('infos')"
      />
    </AppBoxBody>
  </AppBox>
</template>
