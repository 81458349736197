<script setup lang="ts">
import { ComponentInstance, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { AppTableTd, FontIcon, FormMinutesDuration } from '@/components';
import useTime from '@/composables/useTime';
import useLoading from '@/composables/useLoading';
import { EventType } from '@/types/Event';
import { AddTrackedTimeEmitParams, ReportTimeEmitParams } from '@/types/TimeReportModals';
import { DateTime } from 'luxon';

type Props = {
  editable: boolean;
  eventId: number;
  eventType: EventType;
  residualTime: number;
  isQuickReportTimeAllowed: boolean;
  date: string;
  isDone: boolean;
  isAbsence: boolean;
  isFixedPrice: boolean;
  times: {
    time: number;
    note: null | string;
  }[];
};

const {
  times,
  eventId,
  eventType,
  date,
  editable,
  residualTime,
  isQuickReportTimeAllowed,
  isDone,
  isFixedPrice,
  isAbsence,
} = defineProps<Props>();

const emit = defineEmits<{
  reportTime: [params: ReportTimeEmitParams];
  addTrackedTime: [params: AddTrackedTimeEmitParams];
  openEvent: [id: number, type: EventType];
}>();

const { convertMinutesToTime } = useTime();
const { t } = useI18n({ useScope: 'global' });
const { loading, setLoading } = useLoading();
const time = ref<null | number>(null);
const timeDurationComponentRef = ref<ComponentInstance<typeof FormMinutesDuration>>();

function clearInput() {
  time.value = null;
}

function onChangeReportTime(time: null | number) {
  if (time === null) return;
  if ((time > residualTime && isFixedPrice) || !isQuickReportTimeAllowed) {
    emit('addTrackedTime', { id: eventId, type: eventType, date, time });
    clearInput();
  } else {
    emit('reportTime', {
      time,
      setLoading,
      clearInput,
      date,
    });
  }
}

function onClickCellHandler() {
  if (!canBeEdited.value) return;
  if (isDone || times.length > 0) {
    emit('openEvent', eventId, eventType);
  } else {
    timeDurationComponentRef.value?.focus();
  }
}

const canBeEdited = computed(() => editable && (!isDone || times.length > 0) && !isFutureActivity.value);

const isFutureActivity = computed(() => {
  if (eventType === EventType.ProjectTask || isAbsence) {
    return false;
  }
  return DateTime.fromISO(date) >= DateTime.now();
});

const hasInput = computed(() => times.length === 0 && editable && !isDone && !isFutureActivity.value);

const tooltip = computed(() => {
  if (times.length === 0 && !isFutureActivity.value) {
    return isDone ? t('common.this_event_is_set_to_done_reopen_it_to_report_time') : t('common.click_to_report_time');
  }
  if (isFutureActivity.value) {
    return t('common.time_future_activity');
  }
  return null;
});
</script>

<template>
  <AppTableTd
    v-tooltip.bottom="tooltip"
    class="text-center"
    :class="{ pointer: canBeEdited, 'py-0': hasInput }"
    @click.stop="onClickCellHandler"
  >
    <div v-for="(item, idx) in times" class="text-nowrap" :key="idx">
      {{ convertMinutesToTime(item.time) }}
      <FontIcon class="text-neutral-500" v-if="item.note" v-tooltip.bottom="item.note" name="info-circle-filled" />
    </div>
    <div
      class="d-flex align-items-center justify-content-center loadable"
      style="max-width: 100%"
      :class="{ 'is-loading': loading }"
      v-if="hasInput"
    >
      <FormMinutesDuration
        :disabled="loading"
        v-model="time"
        required
        :with-controls="false"
        placeholder=""
        size="small"
        style="background: none; border: none"
        nullable
        @change="onChangeReportTime"
        ref="timeDurationComponentRef"
      />
    </div>
  </AppTableTd>
</template>
