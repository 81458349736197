<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import VueSelect, { VueSelectInstance } from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import { nanoid } from 'nanoid';
import { DateTime } from 'luxon';
import { klona } from 'klona';
import { Decimal } from 'decimal.js';
import { has, get } from 'lodash';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppCloseButton,
  AppLoader,
  AppModal,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  DeleteEventModal,
  EventMileageAllowance,
  FontIcon,
  FormInput,
  FormLabel,
  FormMinutesDuration,
  FormSwitch,
  FormTextarea,
  FormWeekPicker,
  SplitEventModal,
  TimeEntryModalSplitEvents,
  TimeReportFooter,
} from '@/components';
import api from '@/services/api';
import useDate from '@/composables/useDate';
import useTime from '@/composables/useTime';
import useLoader from '@/composables/useLoader';
import useTimeEntry from '@/composables/useTimeEntry';
import useAuthStore from '@/store/AuthStore';
import useClients from '@/composables/useClients';
import useTrackChanges from '@/composables/useTrackChanges';
import { IUserPreviewResource, IVehicleResource } from '@/types/User';
import { ConfirmDialogConfirmParams, ProjectPriceType } from '@/types/Common';
import { IClientPreviewResource } from '@/types/Client';
import { IProjectPreviewResource } from '@/types/Project';
import { ProjectTaskModalProps, ProjectTaskPriceType } from '@/types/ProjectTask';
import {
  EventType,
  ISplitEventRequestBody,
  ITimeEntryProjectTaskEventResource,
  IProjectTaskEventTrackedTimeRequest,
  ISplitEventResource,
  ITimeEntryEventResource,
} from '@/types/Event';
import { IServiceTaskResource } from '@/types/Service';
import { ISalarySettingsResource } from '@/types/Salary';
import {
  TimeReportProjectTaskCreateEmit,
  TimeReportProjectTaskDeleteEmit,
  TimeReportProjectTaskForm,
  TimeReportProjectTaskSplitEmit,
  TimeReportProjectTaskUpdateEmit,
} from '@/types/TimeReportModals';

const emit = defineEmits<{
  close: [];
  closed: [];
  split: [args: TimeReportProjectTaskSplitEmit];
  created: [args: TimeReportProjectTaskCreateEmit];
  updated: [args: TimeReportProjectTaskUpdateEmit];
  deleted: [args: TimeReportProjectTaskDeleteEmit];
}>();

const projectTaskEvent = ref<ITimeEntryProjectTaskEventResource | null>(null);

const props = defineProps<ProjectTaskModalProps>();

const { t, d, n, locale } = useI18n({ useScope: 'global' });
const { getCurrentYearAndWeek, getWeekDates, isWeekGreaterThanCurrent, convertWeekNumberToDateRange, mathWeeks } =
  useDate();
const { convertMinutesToTime } = useTime();
const { authenticatedUser, isEmployeeRole } = useAuthStore();
const { clients, onSearchClients } = useClients();

const {
  clientUuid,
  projectId,
  project,
  projects,
  projectsLoading,
  getProjects,
  getProject,
  teamMembersLoading,
  teamMembers,
  getTeamMembers,
  timePeriodAvailableDates,
  getTimePeriodAvailableDates,
  frequencies,
  workingDaysLoading,
  workingDays,
  getUserWorkingDays,
} = useTimeEntry();

function onDelete() {
  const { open, close, destroy } = useModal({
    component: DeleteEventModal,
    attrs: {
      type: EventType.ProjectTask,
      hasEvents: form.default_frequency !== null,
      async onConfirm(withFutureEvents: boolean, { setLoading }: ConfirmDialogConfirmParams) {
        if (!props.id) return;
        setLoading(true);
        try {
          const { affectedWeeks } = await api.events.destroy(props.id, {
            json: { delete_all_future_events: withFutureEvents },
          });
          emit('deleted', {
            affectedWeeks,
          });
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

const totalTrackedMinutes = computed(() => form.tracked_time.reduce((total, item) => total + item.time, 0));

const isEditing = computed(() => !!props.id);
const isCreating = computed(() => !props.id);

const withExtraTime = ref(false);

const selectedUser = computed(() => {
  return teamMembers.value.find((user) => user.uuid === form.user_uuid);
});

const allEvents = computed<ISplitEventResource[] | undefined>(() => {
  const events: ISplitEventResource[] = [];
  if (projectTaskEvent?.value?.id != undefined) {
    events.push({
      id: projectTaskEvent?.value?.id != undefined ? projectTaskEvent?.value?.id : 0,
      tracked_time: totalTrackedMinutes.value,
      user: { uuid: '', name: selectedUser?.value != undefined ? selectedUser?.value?.name : '' },
      week: form.task_week ?? 0,
    });
  }
  if (projectTaskEvent?.value?.split_events != null) {
    projectTaskEvent?.value?.split_events.forEach((event) => {
      events.push(event);
    });
  }
  return events;
});

function processTimeEntriesOnWithExtraChange() {
  form.tracked_time.forEach((entry, index) => {
    if (entry.is_locked) return;
    if (withExtraTime.value) {
      entry.time_over_budget.billable = getMaxExtraTimeOfTimeEntry(entry, index);
      entry.time_over_budget.not_billable = 0;
    } else {
      form.tracked_time.forEach((entry) => {
        entry.time_over_budget.billable = 0;
        entry.time_over_budget.not_billable = 0;
        entry.time_over_budget.note = '';
      });
    }
  });
}

function setWithExtraTime(withProcess = true) {
  const prevWithExtraTime = withExtraTime.value;
  if (form.custom_price) {
    withExtraTime.value = form.custom_price_type === ProjectTaskPriceType.FIXED;
  } else {
    withExtraTime.value = props.id
      ? projectTaskEvent.value?.project.price_type === ProjectPriceType.FIXED
      : projects.value.find((project) => project.id === projectId.value)?.price_type === ProjectPriceType.FIXED;
  }

  if (withProcess && prevWithExtraTime !== withExtraTime.value) {
    processTimeEntriesOnWithExtraChange();
  }
}

const overBudgetTrackedMinutes = computed(() => {
  //console.log('totals spent aready in other = ' + projectTaskEvent.value?.split_events_time_total);
  return (
    totalTrackedMinutes.value -
    scheduledTime.value +
    (projectTaskEvent.value != null ? projectTaskEvent.value.split_events_time_total : 0)
  );
});

const totalBillable = computed(() =>
  form.tracked_time.reduce((total, item) => total + Number(item.time_over_budget.billable), 0),
);

const totalNotBillable = computed(() =>
  form.tracked_time.reduce((total, item) => total + Number(item.time_over_budget.not_billable), 0),
);

const hasCreatedTimeEntries = computed(() => form.tracked_time.some((i) => i.id));

const weekDays = computed(() => {
  const weekSource =
    isEditing.value && tracker.getOriginal(['task_week']) && hasCreatedTimeEntries.value
      ? tracker.getOriginal(['task_week'])
      : form.task_week;
  return weekSource ? getWeekDates(weekSource as number) : [];
});

const requiresVisit = computed(() => {
  if (isEditing.value) {
    return projectTaskEvent.value?.serviceTask.requires_visit;
  }
  if (serviceTaskId.value) {
    return servicesTasks.value.find(({ id }) => serviceTaskId.value === id)?.requires_visit ?? false;
  }
  return false;
});

const deletable = computed(
  () =>
    !form.tracked_time.some(({ is_locked }) => is_locked) &&
    projectTaskEvent.value &&
    !projectTaskEvent.value.is_invoiced,
);

const loader = useLoader();
const fullLoader = useLoader();
const submitLoader = useLoader();
const doneLoader = useLoader();
const splitLoader = useLoader();

const abortController = ref<null | AbortController>(null);

const clientDropdown = ref<VueSelectInstance | null>(null);
const projectDropdown = ref<VueSelectInstance | null>(null);
const taskDropdown = ref<VueSelectInstance | null>(null);

// Service task
const servicesTasks = ref<IServiceTaskResource[]>([]);
const serviceTaskId = ref<null | number>(null);
const serviceTaskLoading = ref(false);

const form = reactive<TimeReportProjectTaskForm>({
  note: '',
  user_uuid: null,
  is_done: false,
  tracked_time: [],
  default_time_budget: 0,
  default_frequency: null,
  custom_price: false,
  custom_price_type: ProjectTaskPriceType.HOURLY_DISCOUNT,
  custom_price_value: 0,
  task_week: null,
  sub_label: '',
  change_future_responsible: false,
  change_future_scheduled_time: false,
  change_future_scheduled_time_to_actual: false,
  deadline: null,
  visit_is_booked: false,
  mileage_allowance: {
    vehicle_id: null,
    mileage: 0,
    amount: 0,
  },
  // Not for request
  show_mileage: false,
});
const tracker = useTrackChanges(form);

const showChangeFutureResponsibleControl = computed(
  () =>
    isEditing.value &&
    !projectTaskEvent.value?.is_deleted_from_plan &&
    tracker.getOriginal(['user_uuid']) !== form.user_uuid,
);

const showChangeFutureScheduledTimeControl = computed(
  () =>
    isEditing.value &&
    !projectTaskEvent.value?.is_deleted_from_plan &&
    !projectTaskEvent.value?.done_at &&
    !form.is_done &&
    form.default_time_budget !== tracker.getOriginal(['default_time_budget']),
);

const showChangeFutureToActualTimeControl = computed(
  () =>
    isEditing.value &&
    !projectTaskEvent.value?.is_deleted_from_plan &&
    // !projectTaskEvent.value?.done_at &&
    // form.is_done &&
    form.default_time_budget < totalTrackedMinutes.value &&
    form.tracked_time.length > 0,
);

const isAddingTrackedTimeDisabled = computed(() => {
  if (isEditing.value) {
    return form.is_done;
  }
  if (!form.task_week) {
    return false;
  }
  return isWeekGreaterThanCurrent(form.task_week);
});

const isWeekDisabled = computed(() => {
  if (isEditing.value) {
    return form.is_done;
  }
  return false;
});

const weekMaxDate = computed(() => {
  if (isEditing.value) {
    return '';
  }
  if (form.tracked_time.length > 0) {
    return DateTime.now().endOf('week').toJSDate();
  }
  return '';
});

const disabled = computed(() => {
  return (
    !clientUuid.value ||
    !projectId.value ||
    !serviceTaskId.value ||
    !form.task_week ||
    !form.user_uuid ||
    (form.tracked_time.length > 0 && form.tracked_time.some((item) => !item.date))
  );
});

const splitDisabled = computed(() => disabled.value || teamMembersLoading.value);

async function submit(source: 'submit' | 'done') {
  try {
    if (source === 'submit') {
      submitLoader.start();
    } else if (source === 'done') {
      doneLoader.start();
    }
    if (!clientUuid.value || !projectId.value || !serviceTaskId.value) return;
    if (isEditing.value) {
      const { data: event, affectedWeeks } = await api.events.projectTasks.update(props.id!, {
        ...form,
        mileage_allowance: form.show_mileage ? form.mileage_allowance : null,
      });
      emit('updated', {
        event,
        affectedWeeks,
      });
    } else {
      const { data: event, affectedWeeks } = await api.clients.projects.tasks.store(
        clientUuid.value,
        projectId.value,
        serviceTaskId.value,
        {
          ...form,
          mileage_allowance: form.show_mileage ? form.mileage_allowance : null,
        },
      );
      emit('created', {
        event,
        affectedWeeks,
      });
    }
  } catch (error) {
    console.error(error);
    if (source === 'done') {
      form.is_done = false;
    }
  } finally {
    if (source === 'submit') {
      submitLoader.finish();
    } else if (source === 'done') {
      doneLoader.finish();
    }
  }
}

const cancelModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('time-entry.confirm.cancel.title'),
    message: t('time-entry.confirm.cancel.text'),
    onConfirm() {
      emit('close');
      cancelModal.close();
    },
    onCancel() {
      cancelModal.close();
    },
  },
});

async function getServiceTasks() {
  if (!clientUuid.value || !projectId.value) return;
  try {
    serviceTaskLoading.value = true;
    const response = await api.projects.serviceTasks.index(clientUuid.value, projectId.value);
    servicesTasks.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    serviceTaskLoading.value = false;
  }
}

function addTrackedTimeItem(defaultDate?: string, defaultTime?: number) {
  const today = DateTime.now().toFormat('yyyy-MM-dd');
  let time = 0;

  if (
    workingDays.value &&
    form.task_week &&
    workingDays.value[form.task_week] &&
    workingDays.value[form.task_week].days[today]
  ) {
    time = Math.min(
      form.default_time_budget - totalTrackedMinutes.value,
      workingDays.value[form.task_week].days[today]['100'],
    );
  }

  time = Math.max(0, time);

  const item = {
    uid: nanoid(),
    date: defaultDate ? defaultDate : weekDays.value.includes(today) ? today : '',
    time: defaultTime !== undefined && defaultTime >= 0 ? defaultTime : time,
    note: '',
    is_locked: false,
    user_uuid: authenticatedUser.uuid,
    time_over_budget: {
      billable: 0,
      not_billable: 0,
      note: '',
    },
  };
  form.tracked_time.push(item);
  if (withExtraTime.value) {
    item.time_over_budget.billable = getMaxExtraTimeOfTimeEntry(item, form.tracked_time.length - 1);
  }

  // processTimeEntriesOnWithExtraChange();
}

function removeTackedTimeItem(uid: string) {
  form.tracked_time = form.tracked_time.filter((item) => item.uid !== uid);
  if (withExtraTime.value) {
    processTimeEntriesOnWithExtraChange();
  }
}

const scheduledTime = computed(() => {
  return props.id ? Number(projectTaskEvent.value?.original_scheduled_time ?? 0) : form.default_time_budget;
});

function getTotalTrackedTimeUpTo(index: number) {
  const total = form.tracked_time.slice(0, index + 1).reduce((total, { time: t }) => total + t, 0);
  const totalLocked = form.tracked_time
    .filter(({ is_locked }) => is_locked)
    .reduce((total, { time: t }) => total + t, 0);
  return total + totalLocked;
}

function getMaxExtraTimeOfTimeEntry(item: IProjectTaskEventTrackedTimeRequest, index: number) {
  if (item.is_locked) return (item.time_over_budget.billable ?? 0) + (item.time_over_budget.not_billable ?? 0);
  // console.log('scheduled tine=' + scheduledTime.value);
  const writtenInSiblings = projectTaskEvent.value != null ? projectTaskEvent.value.split_events_time_total : 0;
  const result =
    scheduledTime.value - writtenInSiblings - getTotalTrackedTimeUpTo(index) < 0
      ? Math.min(writtenInSiblings + getTotalTrackedTimeUpTo(index) - scheduledTime.value, item.time)
      : 0;
  // console.log('getMaxExtraTimeOfTimeEntry =' + result);
  return result;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function changeTimeEntryTime(_item: IProjectTaskEventTrackedTimeRequest, _index: number) {
  if (withExtraTime.value) {
    processTimeEntriesOnWithExtraChange();
  }
}

function changeTimeEntryBillable(item: IProjectTaskEventTrackedTimeRequest, index: number) {
  item.time_over_budget.not_billable = getMaxExtraTimeOfTimeEntry(item, index) - (item.time_over_budget.billable ?? 0);
}

function changeTimeEntryNotBillable(item: IProjectTaskEventTrackedTimeRequest, index: number) {
  item.time_over_budget.billable = getMaxExtraTimeOfTimeEntry(item, index) - (item.time_over_budget.not_billable ?? 0);
}

async function onSplitEvent() {
  if (!form.task_week || !props.id) return;
  try {
    splitLoader.start();
    await api.events.projectTasks.update(props.id, {
      ...form,
      mileage_allowance: form.show_mileage ? form.mileage_allowance : null,
    });
    const maxTime =
      form.default_time_budget - totalTrackedMinutes.value <= 0
        ? 0
        : form.default_time_budget - totalTrackedMinutes.value;
    const { open, close, destroy } = useModal({
      component: SplitEventModal,
      attrs: {
        modalMode: EventType.ProjectTask,
        users: teamMembers.value,
        initialTime: maxTime,
        maxTime,
        initialWeek: mathWeeks(form.task_week, 1, 'plus'),
        minWeek: form.task_week,
        rule: null,
        async onConfirm(form: ISplitEventRequestBody, { setLoading }: ConfirmDialogConfirmParams) {
          if (!props.id) return;
          try {
            setLoading(true);
            const { data: event, affectedWeeks } = await api.events.projectTasks.split(props.id!, form);
            emit('split', {
              event,
              affectedWeeks,
            });
            await close();
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  } catch (error) {
    console.error(error);
  } finally {
    splitLoader.finish();
  }
}

function onWeekChange() {
  if (isCreating.value || (isEditing.value && !hasCreatedTimeEntries.value)) {
    form.tracked_time.forEach((item) => {
      item.date = '';
    });
  }
}

const salarySettings = ref<ISalarySettingsResource>();

async function getSalarySettings() {
  try {
    const response = await api.salary.settings.index();
    salarySettings.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

const vehicles = ref<IVehicleResource[]>([]);
const vehiclesLoading = ref(false);

async function getVehicles(userUuid: string) {
  try {
    vehiclesLoading.value = true;
    const response = await api.users.vehicles.index(userUuid);
    vehicles.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    vehiclesLoading.value = false;
  }
}

function onTrackedItemDateChange(item: IProjectTaskEventTrackedTimeRequest) {
  if (
    workingDays.value &&
    item.date &&
    form.task_week &&
    has(workingDays.value, form.task_week.toString()) &&
    !item.id &&
    item.time === 0
  ) {
    // @ts-ignore
    const dayDuration = get(workingDays.value, [form.task_week.toString(), 'days', item.date, '100'], 0) as number;
    item.time = Math.max(Math.min(form.default_time_budget - totalTrackedMinutes.value, dayDuration), 0);

    processTimeEntriesOnWithExtraChange();
  }
}

function onScheduledTimeChange() {
  if (isCreating.value) {
    processTimeEntriesOnWithExtraChange();
  }
}

watch(clientUuid, async () => {
  if (props.id) return;
  projectId.value = null;
  if (clientUuid.value) {
    await getProjects();

    if (!isEditing.value) {
      await nextTick();
      if (projects.value.length === 1) {
        projectId.value = projects.value[0].id;
      } else {
        projectDropdown.value?.searchEl.focus();
      }
    }
  } else {
    projects.value = [];
  }
});

watch(projectId, async () => {
  if (props.id) return;
  serviceTaskId.value = null;
  servicesTasks.value = [];
  if (projectId.value) {
    getServiceTasks().then(() => {
      if (!isEditing.value) {
        nextTick().then(() => {
          taskDropdown.value?.searchEl.focus();
          if (form.user_uuid === null && teamMembers.value.some((users) => users.uuid === authenticatedUser.uuid)) {
            form.user_uuid = authenticatedUser?.uuid ?? null;
          }
        });
      }
    });
    await Promise.all([getProject(), getTeamMembers()]);
  }
});

onMounted(async () => {
  loader.start();
  fullLoader.start();
  if (props.id) {
    // Edit  mode
    try {
      abortController.value = new AbortController();
      const response = await api.events.projectTasks.get(props.id, { signal: abortController.value.signal });
      projectTaskEvent.value = klona(response.data);
      form.note = response.data.note ?? '';
      form.user_uuid = response.data.user.uuid;
      form.is_done = !!response.data.done_at;
      form.default_frequency = response.data.default_frequency;
      form.default_time_budget = response.data.scheduled_time ?? 0;
      form.tracked_time = response.data.tracked_time.map((item) => ({
        uid: nanoid(),
        id: item.id,
        date: item.date,
        time: item.original_time,
        note: item.note ?? '',
        is_locked: item.is_locked,
        user_uuid: item.user.uuid,
        time_over_budget: {
          billable: item.time_over_budget.billable ?? 0,
          not_billable: item.time_over_budget.not_billable ?? 0,
          note: item.time_over_budget.note,
        },
      }));
      form.custom_price = response.data.custom_price;
      form.custom_price_type = response.data.custom_price_type ?? ProjectTaskPriceType.HOURLY_DISCOUNT;
      form.custom_price_value = response.data.custom_price_value ?? 0;
      form.task_week = response.data.week;
      form.sub_label = response.data.sub_label ?? '';
      form.deadline = response.data.deadline;
      form.visit_is_booked = response.data.visit_is_booked;
      form.mileage_allowance = {
        vehicle_id: response.data.mileage_allowance?.vehicle_id ?? null,
        mileage: new Decimal(response.data.mileage_allowance?.mileage ?? 0).toDecimalPlaces(2).toNumber(),
        amount: new Decimal(response.data.mileage_allowance?.amount ?? 0).toDecimalPlaces(2).toNumber(),
      };
      form.show_mileage = response.data.mileage_allowance !== null;

      clientUuid.value = response.data.client.uuid;
      clients.value.push(response.data.client);
      projectId.value = response.data.project.id;
      projects.value.push(response.data.project);
      project.price_type = response.data.project.price_type;
      if (project.price_type === ProjectPriceType.FIXED) {
        project.annual_price = response.data.project.annual_price;
        project.estimating_price = response.data.project.estimating_price;
      } else if (project.price_type === ProjectPriceType.HOURLY) {
        project.discount = response.data.project.discount;
      }
      serviceTaskId.value = response.data.serviceTask.id;
      servicesTasks.value.push(response.data.serviceTask);
    } catch (error) {
      console.error(error);
    } finally {
      loader.finish();
      abortController.value = null;
    }
  } else {
    // Creating mode
    form.task_week = props.initialWeek ?? getCurrentYearAndWeek();
    form.user_uuid = authenticatedUser.uuid;
    loader.finish();
  }
  await Promise.all([
    getTeamMembers(),
    getTimePeriodAvailableDates(),
    getSalarySettings(),
    getUserWorkingDays(form.user_uuid!, [form.task_week!]),
    getVehicles(form.user_uuid!),
  ]);

  if (form.mileage_allowance.vehicle_id === null && vehicles.value.length === 1) {
    form.mileage_allowance.vehicle_id = vehicles.value[0].id;
  }
  setWithExtraTime(false);
  tracker.commit();
  fullLoader.finish();
  // Set form.custom_price_value 0 on form.custom_price_type change
  watch(
    () => form.custom_price_type,
    () => {
      form.custom_price_value = 0;
    },
  );

  // Get user's working day on form.task_week or form.user_uuid change
  watch([() => form.task_week, () => form.user_uuid], () => {
    if (form.user_uuid && form.task_week && (isCreating.value || (isEditing.value && !hasCreatedTimeEntries.value))) {
      getUserWorkingDays(form.user_uuid, [form.task_week]);
    }
  });

  // Set form.change_future_scheduled_time to false on form.is_done change
  watch(
    () => form.is_done,
    (value) => {
      if (!value) {
        form.change_future_scheduled_time = false;
      }
    },
  );

  watch(serviceTaskId, (value) => {
    if (!isEditing.value && value) {
      const serviceTask = servicesTasks.value.find(({ id }) => id === value);
      if (serviceTask && serviceTask.requires_visit && !form.show_mileage) {
        form.show_mileage = true;
      }
    }
  });

  // If creating, get vehicles on form.user_uuid change
  watch(
    () => form.user_uuid,
    async () => {
      if (isCreating.value && form.user_uuid) {
        await getVehicles(form.user_uuid);
        if (form.mileage_allowance) {
          form.mileage_allowance.vehicle_id = vehicles.value.length === 1 ? vehicles.value[0].id : null;
        }
      }
    },
  );

  // Get working day on form.user_uuid change
  watch(
    () => form.user_uuid,
    async () => {
      if (form.user_uuid && form.task_week) {
        await getUserWorkingDays(form.user_uuid, [form.task_week]);
      }
      if (!showChangeFutureResponsibleControl.value) {
        form.change_future_responsible = false;
      }
    },
  );

  // Watch extra time change
  watch([() => form.custom_price, () => form.custom_price_type, projectId], () => {
    setWithExtraTime();
  });

  // Set discount
  watch(
    () => form.custom_price,
    () => {
      if (form.custom_price) {
        const p = projects.value.find(({ id }) => projectId.value === id);
        if (p) {
          form.custom_price_value = p.price_type === ProjectPriceType.FIXED ? 0 : p.discount ?? 0;
        }
      } else {
        form.custom_price_value = 0;
      }
    },
  );

  await nextTick();
  if (!isEditing.value) {
    clientDropdown.value?.searchEl.focus();
  }

  if (props.addTrackedTimeOnOpen) {
    addTrackedTimeItem(props.addTrackedTimeOnOpen.date, props.addTrackedTimeOnOpen.time);
  }
});

function onCancel() {
  if (tracker.isModified.value) {
    cancelModal.open();
  } else {
    emit('close');
  }
}

function onClientChange() {
  // Go to next relevant field when selection is made
  if (!isEditing.value) {
    nextTick(() => {
      projectDropdown.value?.searchEl.focus();
    });
  }
}

function onProjectChange() {
  // Go to next relevant field when selection is made
  if (isCreating.value) {
    nextTick(() => {
      taskDropdown.value?.searchEl.focus();
      const p = projects.value.find(({ id }) => projectId.value === id);
      if (p) {
        const isFixedProject = p.price_type === ProjectPriceType.FIXED;
        form.custom_price = isFixedProject;
        form.custom_price_type = isFixedProject ? ProjectTaskPriceType.HOURLY_DISCOUNT : null;
        form.custom_price_value = 0;
      }
    });
  }
}

async function onUserChange() {
  if (form.user_uuid && form.task_week) {
    await getUserWorkingDays(form.user_uuid, [form.task_week]);
  }
  if (!showChangeFutureResponsibleControl.value) {
    form.change_future_responsible = false;
  }
}

function showOverBudgetForm(item: IProjectTaskEventTrackedTimeRequest, index: number) {
  if (withExtraTime.value === false) return false;
  return getMaxExtraTimeOfTimeEntry(item, index) > 0;
}

function onCtrlEnter() {
  if (!disabled.value) {
    if (isEditing.value) {
      if (!tracker.isModified.value) {
        emit('close');
      } else {
        submit('submit');
      }
    } else {
      submit('submit');
    }
  }
}

function onEscape() {
  if (loader.isLoading.value || submitLoader.isLoading.value || splitLoader.isLoading.value) return;
  onCancel();
}

function onClosed() {
  if (abortController.value) {
    abortController.value.abort('Abort fetching event.');
  }
  emit('closed');
}
</script>

<template>
  <AppModal
    @keydown.ctrl.enter.stop="onCtrlEnter"
    @keydown.esc.stop="onEscape"
    size="huge"
    @closed="onClosed"
    :esc-to-close="false"
  >
    <form @submit.prevent="submit('submit')" @keydown.enter.prevent>
      <div v-if="loader.isNotLoading.value" class="modal-header">
        <h2>
          <span
            v-if="isEditing"
            v-text="t('time-entry.modal.project_task.edit_title', { name: servicesTasks[0]?.name })"
          />
          <span v-else v-text="t('time-entry.modal.project_task.create_title')" />
        </h2>
        <AppCloseButton @close="onCancel" />
      </div>
      <div class="modal-content">
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="large" />
        </div>
        <template v-else>
          <!-- Form section -->
          <div class="row">
            <div class="col-5">
              <!-- Task block -->
              <h3 v-text="t('time-entry.modal.task_block_title')" />

              <!-- Client -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="client_uuid" :required="!clientUuid">
                      <RouterLink
                        v-if="clientUuid"
                        :to="{ name: 'client.overview', params: { uuid: clientUuid } }"
                        target="_blank"
                      >
                        {{ t('time-entry.modal.attributes.client') }}
                      </RouterLink>
                      <span v-else v-text="t('time-entry.modal.attributes.client')" />
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <VueSelect
                      ref="clientDropdown"
                      :filterable="false"
                      v-model="clientUuid"
                      :options="clients"
                      label="name"
                      input-id="client_uuid"
                      :placeholder="t('common.select')"
                      :disabled="isEditing || form.is_done"
                      :reduce="(option: IClientPreviewResource) => option.uuid"
                      @update:model-value="onClientChange"
                      @search="onSearchClients"
                    >
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!clientUuid" v-bind="attributes as object" v-on="events" />
                      </template>
                      <template #no-options>{{ t('common.type_to_search') }}</template>
                    </VueSelect>
                  </div>
                </div>
              </div>

              <!-- Project -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="project_id" :required="!projectId">
                      <RouterLink
                        v-if="clientUuid && projectId"
                        :to="{
                          name: 'projects.edit',
                          params: { uuid: clientUuid, id: projectId },
                        }"
                        target="_blank"
                      >
                        {{ t('time-entry.modal.attributes.project') }}
                      </RouterLink>
                      <span v-else v-text="t('time-entry.modal.attributes.project')" />
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <VueSelect
                      ref="projectDropdown"
                      :clearable="false"
                      v-model="projectId"
                      :options="projects"
                      label="name"
                      input-id="project_id"
                      :placeholder="t('common.select')"
                      :loading="projectsLoading"
                      :disabled="clientUuid === null || isEditing || projectsLoading || form.is_done"
                      :reduce="(option: IProjectPreviewResource) => option.id"
                      @update:model-value="onProjectChange"
                    >
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!projectId" v-bind="attributes as object" v-on="events" />
                      </template>
                      <template #no-options>
                        {{ t('common.there_is_no_active_projects') }}
                        <RouterLink :to="{ name: 'projects.create', params: { uuid: clientUuid } }" target="_blank">
                          {{ t('common.create_new_project') }}
                        </RouterLink>
                      </template>
                    </VueSelect>
                  </div>
                </div>
              </div>

              <!-- Task -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="task_id" required>
                      {{ t('time-entry.modal.attributes.task') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <VueSelect
                      ref="taskDropdown"
                      :clearable="false"
                      v-model="serviceTaskId"
                      :options="servicesTasks"
                      label="name"
                      input-id="task_id"
                      :placeholder="t('common.select')"
                      :loading="serviceTaskLoading"
                      :disabled="projectId === null || isEditing || serviceTaskLoading || form.is_done"
                      :reduce="(option: IServiceTaskResource) => option.id"
                      :selectable="(option: IServiceTaskResource) => option.is_active"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!serviceTaskId"
                          v-bind="attributes as object"
                          v-on="events"
                        />
                      </template>
                    </VueSelect>
                  </div>
                </div>
              </div>

              <!-- Frequency -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="frequency" required>
                      {{ t('time-entry.modal.attributes.frequency') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <VueSelect
                      :clearable="false"
                      v-model="form.default_frequency"
                      :options="frequencies"
                      input-id="frequency"
                      :placeholder="t('common.select')"
                      label="label"
                      :reduce="(option: any) => option.value"
                      :disabled="isEditing || form.is_done"
                    />
                  </div>
                </div>
              </div>

              <!-- Sub label -->
              <div class="form-group" v-if="projectTaskEvent?.project.is_sub_label_enabled">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="sub_label">
                      {{ t('time-entry.modal.attributes.sub_label') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormInput id="sub_label" v-model="form.sub_label" :disabled="form.is_done" />
                  </div>
                </div>
              </div>

              <!-- Note -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="task_notes">
                      {{ t('time-entry.modal.attributes.task_notes') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormTextarea rows="4" id="task_notes" v-model="form.note" :disabled="form.is_done" />
                  </div>
                </div>
              </div>

              <!-- Visit is booked -->
              <div v-if="requiresVisit" class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="visit_is_booked">
                      {{ t('time-entry.modal.attributes.visit_is_booked') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormSwitch
                      :disabled="form.is_done"
                      group-class="mb-0"
                      id="visit_is_booked"
                      v-model="form.visit_is_booked"
                    />
                  </div>
                </div>
              </div>

              <!-- Mileage allowance -->
              <EventMileageAllowance
                v-if="form.mileage_allowance"
                :vehicles="vehicles"
                :payment-km-before-tax-own-car="salarySettings?.payment_km_before_tax_own_car ?? 0"
                :payment-km-before-tax-employee-car-other="
                  salarySettings?.payment_km_before_tax_employee_car_other ?? 0
                "
                :payment-km-before-tax-employee-car-diesel="
                  salarySettings?.payment_km_before_tax_employee_car_diesel ?? 0
                "
                :payment-km-before-tax-employee-car-electric="
                  salarySettings?.payment_km_before_tax_employee_car_electric ?? 0
                "
                :disabled="form.is_done"
                :vehicles-loading="vehiclesLoading"
                v-model:visibility="form.show_mileage"
                v-model:vehicleId="form.mileage_allowance.vehicle_id"
                v-model:mileage="form.mileage_allowance.mileage"
                v-model:amount="form.mileage_allowance.amount"
              />

              <!-- Price block -->
              <h3 v-text="t('time-entry.modal.price_block_title')" />

              <!-- Service price -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel>{{ t('time-entry.modal.project_base_fee') }}</FormLabel>
                  </div>
                  <div class="col-md-8">
                    <span v-if="project.price_type === ProjectPriceType.FIXED">
                      {{ t('project.price.fixed') }} - {{ t('time-entry.modal.project_fee') }}:
                      <span
                        v-if="project.only_invoice_part_of_year"
                        v-text="n(project.estimating_price ?? 0, 'currency')"
                      />
                      <span v-else v-text="n(project.annual_price ?? 0, 'currency')" />
                    </span>
                    <span v-else-if="project.price_type === ProjectPriceType.HOURLY">
                      {{ t('project.price.hourly') }} - {{ project.discount }}%
                    </span>
                    <i v-else class="text-neutral-300" v-text="t('common.empty')" />
                  </div>
                </div>
              </div>

              <!-- Charge separately -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="charge_separately">
                      {{ t('time-entry.modal.attributes.charge_separately') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormSwitch
                      :disabled="isEditing || form.is_done"
                      group-class="mb-0"
                      id="charge_separately"
                      v-model="form.custom_price"
                    />
                  </div>
                </div>
              </div>

              <!-- Price type -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="price_type">
                      {{ t('time-entry.modal.attributes.price_type') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <VueSelect
                      :clearable="false"
                      v-model="form.custom_price_type"
                      :options="[
                        { value: ProjectTaskPriceType.FIXED, label: t('common.fixed') },
                        { value: ProjectTaskPriceType.HOURLY_DISCOUNT, label: t('common.hourly') },
                      ]"
                      input-id="price_type"
                      :placeholder="t('common.select')"
                      :disabled="isEditing || form.is_done || !form.custom_price"
                      label="label"
                      :reduce="(option: any) => option.value"
                    />
                  </div>
                </div>
              </div>

              <!-- Price -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="custom_price">
                      <span
                        v-if="form.custom_price_type === ProjectTaskPriceType.FIXED"
                        v-text="t('time-entry.modal.attributes.amount')"
                      />
                      <span
                        v-if="form.custom_price_type === ProjectTaskPriceType.HOURLY_DISCOUNT"
                        v-text="t('time-entry.modal.attributes.discount')"
                      />
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormInput
                      min="0"
                      :max="form.custom_price_type === ProjectTaskPriceType.HOURLY_DISCOUNT ? 100 : 999999"
                      type="number"
                      v-model.number="form.custom_price_value"
                      id="custom_price"
                      :icon="
                        form.custom_price_type === ProjectTaskPriceType.HOURLY_DISCOUNT
                          ? 'percentage'
                          : 'currency-krone-swedish'
                      "
                      required
                      :disabled="form.is_done || isEditing || !form.custom_price"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7">
              <!-- Time planned block -->
              <div>
                <h3 v-text="t('time-entry.modal.time_planned_block_title')" />

                <!-- Employee -->
                <div class="form-group">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <FormLabel html-for="user_uuid" required>
                        {{ t('time-entry.modal.attributes.employee') }}
                      </FormLabel>
                    </div>
                    <div class="col-md-8">
                      <VueSelect
                        :clearable="false"
                        v-model="form.user_uuid"
                        :options="teamMembers"
                        label="name"
                        input-id="user_uuid"
                        :placeholder="teamMembersLoading ? t('common.loading') : t('common.select')"
                        :loading="teamMembersLoading"
                        :disabled="teamMembersLoading || form.is_done"
                        :reduce="(option: IUserPreviewResource) => option.uuid"
                        @update:model-value="onUserChange"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :required="!form.user_uuid"
                            v-bind="attributes as object"
                            v-on="events"
                          />
                        </template>
                        <!--                        <template #option="option: Employee">-->
                        <!--                          {{ option.name + (option.is_disabled ? ` (${t('common.deleted')})` : '') }}-->
                        <!--                        </template>-->
                        <!--                        <template #selected-option="option: Employee">-->
                        <!--                          {{ option.name + (option.is_disabled ? ` (${t('common.deleted')})` : '') }}-->
                        <!--                        </template>-->
                      </VueSelect>
                    </div>
                  </div>
                </div>

                <!-- Change future responsible -->
                <div v-if="fullLoader.isNotLoading.value && showChangeFutureResponsibleControl" class="form-group">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <FormLabel html-for="change_future_responsible" required>
                        {{ t('time-entry.modal.attributes.change_future_responsible') }}
                      </FormLabel>
                    </div>
                    <div class="col-md-8">
                      <FormSwitch
                        group-class="mb-0"
                        id="change_future_responsible"
                        v-model="form.change_future_responsible"
                      />
                    </div>
                  </div>
                </div>

                <!-- Week -->
                <div class="form-group">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <FormLabel html-for="time_week" required>
                        {{ t('time-entry.modal.attributes.week') }}
                      </FormLabel>
                    </div>
                    <div class="col-md-8">
                      <FormWeekPicker
                        v-model="form.task_week"
                        :disabled="isWeekDisabled"
                        :min-date="timePeriodAvailableDates?.start_date ?? ''"
                        :max-date="weekMaxDate"
                        required
                        @change="onWeekChange"
                      />
                    </div>
                  </div>
                </div>

                <!-- Deadline -->
                <div class="form-group">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <FormLabel html-for="deadline">
                        {{ t('time-entry.modal.attributes.deadline') }}
                      </FormLabel>
                    </div>
                    <div class="col-md-8">
                      <div class="form-wrapper has-icon">
                        <VueDatePicker
                          :ui="{ input: 'form-control' }"
                          :placeholder="t('common.select')"
                          v-model="form.deadline"
                          model-type="format"
                          format="yyyy-MM-dd"
                          :enable-time-picker="false"
                          :month-change-on-scroll="false"
                          auto-apply
                          text-input
                          :min-date="form.task_week ? convertWeekNumberToDateRange(form.task_week)[0] : ''"
                          clearable
                          :disabled="form.is_done"
                          week-numbers="iso"
                          :locale="locale"
                          :week-num-name="t('common.week_short')"
                          six-weeks="center"
                        >
                          <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                        </VueDatePicker>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Estimated time -->
                <div class="form-group">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <FormLabel html-for="estimated_time" required>
                        {{ t('time-entry.modal.attributes.estimated_time') }}
                      </FormLabel>
                    </div>
                    <div class="col-md-8">
                      <div class="d-flex align-items-center">
                        <FormMinutesDuration
                          class="flex-shrink-0"
                          :max="5985"
                          :disabled="form.is_done"
                          v-model="form.default_time_budget"
                          @change="onScheduledTimeChange"
                        />
                        <div
                          v-tooltip.right="t('time-entry.modal.tooltip.initial_scheduled_time')"
                          v-if="
                            isEditing &&
                            typeof projectTaskEvent?.initial_scheduled_time === 'number' &&
                            form.default_time_budget !== projectTaskEvent.initial_scheduled_time
                          "
                          class="flex-grow-1 ml-4"
                        >
                          {{ convertMinutesToTime(projectTaskEvent.initial_scheduled_time) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Change future scheduled time -->
                <div v-if="fullLoader.isNotLoading.value && showChangeFutureScheduledTimeControl" class="form-group">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <FormLabel html-for="change_future_scheduled_time" required>
                        {{ t('time-entry.modal.attributes.change_future_scheduled_time') }}
                      </FormLabel>
                    </div>
                    <div class="col-md-8">
                      <FormSwitch
                        group-class="mb-0"
                        id="change_future_scheduled_time"
                        v-model="form.change_future_scheduled_time"
                      />
                    </div>
                  </div>
                </div>

                <!-- Total time -->
                <div class="form-group">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <FormLabel>
                        {{ t('time-entry.modal.attributes.total_time') }}
                      </FormLabel>
                    </div>
                    <div class="col-md-8">
                      <span v-text="convertMinutesToTime(totalTrackedMinutes)" />
                      <strong class="text-danger-500 pl-1" v-if="overBudgetTrackedMinutes > 0">
                        ({{ convertMinutesToTime(overBudgetTrackedMinutes) }})
                      </strong>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Change future to actual time -->
              <div v-if="showChangeFutureToActualTimeControl" class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="change_future_scheduled_time" required>
                      {{ t('time-entry.modal.attributes.change_future_to_actual_time') }}
                    </FormLabel>
                    <p
                      class="d-none text-6"
                      v-text="t('time-entry.modal.attributes.change_future_to_actual_time_description')"
                    />
                  </div>
                  <div class="col-md-8">
                    <FormSwitch
                      group-class="mb-0"
                      id="change_future_scheduled_time"
                      v-model="form.change_future_scheduled_time_to_actual"
                    />
                  </div>
                </div>
              </div>

              <!-- Time entries block -->
              <div>
                <h3 v-text="t('time-entry.modal.time_entries_block_title')" />
                <div v-if="workingDaysLoading" class="text-center">
                  <AppLoader />
                </div>
                <template v-else>
                  <AppBox class="mb-2" v-for="(item, index) in form.tracked_time" :key="item.uid" border shadow>
                    <AppBoxBody class="d-flex" size="small">
                      <!-- Date -->
                      <div style="width: 235px">
                        <FormLabel :html-for="`date-${item.uid}`" required>
                          {{ t('time-entry.modal.attributes.date') }}
                        </FormLabel>
                        <div class="form-wrapper">
                          <select
                            :id="`date-${item.uid}`"
                            class="form-control"
                            v-model="item.date"
                            :disabled="form.is_done || item.is_locked || item.user_uuid !== authenticatedUser.uuid"
                            @change="onTrackedItemDateChange(item)"
                            required
                            :class="{ 'is-invalid': !item.date }"
                          >
                            <option disabled hidden value="" v-text="t('common.select')" />
                            <option
                              v-for="date in weekDays"
                              :key="date"
                              :value="date"
                              v-text="d(new Date(date), 'weekday')"
                            />
                          </select>
                        </div>
                      </div>
                      <!-- Time -->
                      <div class="flex-shrink-0 ml-3">
                        <FormLabel :html-for="`time-${item.uid}`" required>
                          {{ t('time-entry.modal.attributes.time') }}
                        </FormLabel>
                        <FormMinutesDuration
                          :id="`time-${item.uid}`"
                          :max="1440"
                          :disabled="form.is_done || item.is_locked || item.user_uuid !== authenticatedUser.uuid"
                          v-model="item.time"
                          @change="changeTimeEntryTime(item, index)"
                        />
                      </div>
                      <!-- Comment -->
                      <div class="flex-grow-1 d-flex align-items-end ml-3">
                        <div class="flex-grow-1">
                          <FormLabel :html-for="`comment-${item.uid}`">
                            {{ t('time-entry.modal.attributes.comment') }}
                          </FormLabel>
                          <div class="form-wrapper">
                            <input
                              :id="`comment-${item.uid}`"
                              class="form-control"
                              v-model="item.note"
                              :disabled="form.is_done || item.is_locked || item.user_uuid !== authenticatedUser.uuid"
                            />
                          </div>
                        </div>
                        <AppButton
                          class="flex-shrink-0 ml-3"
                          @click.prevent="removeTackedTimeItem(item.uid)"
                          color="danger"
                          circle
                          light
                          :disabled="form.is_done || item.is_locked || item.user_uuid !== authenticatedUser.uuid"
                          v-tooltip.left="t('common.delete')"
                        >
                          <FontIcon name="trash" />
                        </AppButton>
                      </div>
                    </AppBoxBody>
                    <AppBoxBody class="d-flex" size="small" v-if="showOverBudgetForm(item, index)">
                      <div class="flex-shrink-0">
                        <FormLabel :html-for="`billable-${item.uid}`">
                          {{ t('time-entry.modal.attributes.billable') }}
                        </FormLabel>
                        <FormMinutesDuration
                          :id="`billable-${item.uid}`"
                          :max="getMaxExtraTimeOfTimeEntry(item, index)"
                          :min="0"
                          :disabled="form.is_done || item.is_locked"
                          v-model="item.time_over_budget.billable"
                          @change="changeTimeEntryBillable(item, index)"
                        />
                      </div>
                      <div class="flex-shrink-0 ml-3">
                        <FormLabel :html-for="`not_billable-${item.uid}`">
                          {{ t('time-entry.modal.attributes.not_billable') }}
                        </FormLabel>
                        <FormMinutesDuration
                          :id="`not_billable-${item.uid}`"
                          :max="getMaxExtraTimeOfTimeEntry(item, index)"
                          :min="0"
                          :disabled="form.is_done || item.is_locked"
                          v-model="item.time_over_budget.not_billable"
                          @change="changeTimeEntryNotBillable(item, index)"
                        />
                      </div>
                      <div class="flex-grow-1 d-flex ml-3">
                        <div class="flex-grow-1">
                          <FormLabel :html-for="`note-${item.uid}`">
                            {{ t('time-entry.modal.attributes.note') }}
                          </FormLabel>
                          <div class="form-wrapper">
                            <input
                              :id="`note-${item.uid}`"
                              class="form-control"
                              v-model="item.time_over_budget.note"
                              :disabled="form.is_done || item.is_locked"
                            />
                          </div>
                        </div>
                      </div>
                    </AppBoxBody>
                  </AppBox>
                  <AppAlert v-if="form.tracked_time.length === 0">{{ t('common.empty') }}</AppAlert>
                  <AppButton
                    @click.prevent="addTrackedTimeItem()"
                    class="mt-2"
                    color="success"
                    circle
                    light
                    :disabled="isAddingTrackedTimeDisabled"
                    v-tooltip.right="t('common.add_time')"
                  >
                    <FontIcon name="plus" />
                  </AppButton>
                </template>
              </div>

              <!-- Time over budget block -->
              <div v-if="withExtraTime && overBudgetTrackedMinutes > 0" class="mt-3">
                <h3 v-text="t('time-entry.modal.time_over_budget_block_title')" />
                <AppTable hoverable>
                  <AppTableHead>
                    <AppTableTr>
                      <AppTableTh class="pl-0" nowrap>{{ t('time-entry.modal.attributes.billable') }}</AppTableTh>
                      <AppTableTh class="pr-0">{{ t('time-entry.modal.attributes.not_billable') }}</AppTableTh>
                    </AppTableTr>
                  </AppTableHead>
                  <AppTableBody>
                    <AppTableTr>
                      <AppTableTd class="pl-0">
                        {{ convertMinutesToTime(totalBillable) }}
                      </AppTableTd>
                      <AppTableTd class="pr-0">
                        {{ convertMinutesToTime(totalNotBillable) }}
                      </AppTableTd>
                    </AppTableTr>
                  </AppTableBody>
                </AppTable>
              </div>

              <!-- Split events -->
              <TimeEntryModalSplitEvents
                class="mt-4"
                :split-events="allEvents"
                :original-scheduled-time="projectTaskEvent?.original_scheduled_time"
              />
            </div>
          </div>
        </template>
        <slot />
      </div>
      <div v-if="loader.isNotLoading.value" class="modal-footer mt-5">
        <TimeReportFooter
          :is-editing="isEditing"
          :deletable="Boolean(deletable)"
          :disabled="disabled"
          :is-split-disabled="splitDisabled"
          :is-split-loading="splitLoader.isLoading.value"
          :is-submit-loading="submitLoader.isLoading.value"
          :is-done-loading="doneLoader.isLoading.value"
          :is-modified="tracker.isModified.value"
          :splittable="!form.is_done"
          v-model:done="form.is_done"
          @split="onSplitEvent"
          @delete="onDelete"
          @done="submit('done')"
          @cancel="onCancel"
        />
      </div>
    </form>
  </AppModal>
</template>
<style>
.table td,
.table th {
  padding-bottom: 5px;
  padding-top: 5px;
}
.form-group {
  margin-bottom: 0.6rem;
}
</style>
