<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { ref, useId, watch } from 'vue';
import { ITodoCommentResource } from '@/types/Todo';
import { AppButton, AppFormEditor, FontIcon, FormLabel, UserTodoComment } from '@/components';
import api from '@/services/api';
import useFormEditor from '@/composables/useFormEditor';

const { todoId, comments, hideFormAfterSendComment } = defineProps<{
  todoId: number;
  comments: ITodoCommentResource[];
  hideFormAfterSendComment: boolean;
}>();

const emit = defineEmits<{
  commented: [text: string];
}>();

const formVisible = defineModel<boolean>('formVisible', { required: true });

const { t } = useI18n({ useScope: 'global' });
const commentLoading = ref(false);
const id = useId();

const { editor } = useFormEditor();

async function onSendComment() {
  if (!editor.value) return;
  commentLoading.value = true;
  editor.value.setEditable(false);
  try {
    await api.todos.comments.create(todoId, editor.value.getHTML());
    if (hideFormAfterSendComment) {
      formVisible.value = false;
    }
    emit('commented', editor.value.getHTML());
    editor.value.commands.clearContent();
  } catch (error) {
    console.error(error);
  } finally {
    commentLoading.value = false;
    editor.value.setEditable(true);
  }
}

watch(formVisible, (value) => {
  if (value) {
    editor.value?.chain().focus();
  }
});
</script>

<template>
  <div class="comments">
    <ul class="comment-list" v-if="comments.length > 0">
      <UserTodoComment v-for="comment in comments" :key="comment.id" :comment="comment" />
    </ul>
    <form class="mt-3" v-if="formVisible && editor" @submit.prevent="onSendComment">
      <FormLabel :html-for="id">
        {{ t('common.comment') }}
      </FormLabel>
      <AppFormEditor :editor="editor">
        <template #bar>
          <AppButton
            class="flex-shrink-0 ml-auto"
            :disabled="editor.isEmpty || commentLoading"
            v-tooltip.bottom="t('common.send')"
            color="secondary"
            circle
            :loading="commentLoading"
          >
            <FontIcon name="send" />
          </AppButton>
        </template>
      </AppFormEditor>
    </form>
  </div>
</template>
