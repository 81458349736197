export default {
  home: { index: 'Home' },
  clients: { index: 'Customers' },
  contacts: { index: 'Contacts' },
  todos: { index: 'ToDo & Messages' },
  checkpoints: { index: 'Checkpoints' },
  deadlines: { index: 'Deadlines' },
  projects: { index: 'Projects' },
  internal_projects: { index: 'Internal projects & events' },
  resource_planning: {
    index: 'Resource planning',
    management: { index: 'Resource management' },
    my_planning: { index: 'My planning' },
    resource_overview: { index: 'Resource overview' },
    client_visits: { index: 'Client visits' },
  },
  invoices: {
    index: 'Invoices',
    all: { index: 'All invoices' },
    draft: { index: 'Invoice drafts' },
  },

  my_coworkers: 'My coworkers',
  service_organizations: 'External collaborators',
  time_sheets: {
    index: 'Time sheets',
    my_time: {
      index: 'My time',
      reported: 'My reported time',
      parental: 'My parental leave',
      childcare: 'My Child care leave',
    },
    time_sheets: { index: 'Time sheets' },
    time_periods: { index: 'Time periods' },
  },
  statistics: {
    index: 'Statistics',
    revenue: {
      index: 'Revenue & Profit',
      billing: { index: 'Billing' },
      billing_forecast: { index: 'Billing forecast' },
      profitability: { index: 'Profitability' },
      write_offs: { index: 'WriteOffs' },
      wip_fixed_prices: { index: 'WIP Fixed prices' },
      wip_hourly_prices: { index: 'WIP Hourly prices' },
    },
    planning: {
      index: 'Planning & Time',
      burndown: { index: 'Burndown' },
      progress: { index: 'Progress' },
      reported_budgeted_time: { index: 'Reported v Budgeted time' },
      education: { index: 'Education' },
      time_analysis: { index: 'Time analysis' },
      my_activities: { index: 'My activities' },
    },
  },
  settings: {
    index: 'Settings',
    services: {
      index: 'Services settings',
      services: { index: 'Services' },
      service_mapping: { index: 'Service mapping' },
      prices: { index: 'Prices' },
      fixed_price_settings: { index: 'Fixed price index' },
    },
    internal_activities: {
      index: 'Internal activities',
      templates: { index: 'Internal activity templates ' },
    },
    service_organizations: {
      index: 'External collaborators settings',
      types_and_roles: { index: 'Types and roles' },
    },
    offices: { index: 'Offices' },
    departments: { index: 'Departments' },
    work_titles: { index: 'Work titles' },
    employees: { index: 'Employees' },
    salary: { index: 'Salary settings' },
    working_schedule: {
      index: 'Working schedule',
      holidays: { index: 'Holidays' },
      working_day_duration: { index: 'Working day duration' },
    },
    invoicing: {
      index: 'Invoicing',
      accounting: { index: 'Accounting' },
    },
  },
};
