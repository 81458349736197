export default {
  index: {
    title: 'Anställda',
    create: 'Lägg till anställd',
    help: '',

    filters: {
      name: 'Namn',
    },
  },

  create: {
    title: 'Lägg till anställd',
    help: '',
  },

  edit: {
    title: 'Redigera uppgifter för: {name}',
    help: '',
    edit_in_admin: 'Redigera i adminpanelen',
  },

  form: {
    info_title: 'Info',
    settings_title: 'Inställningar',
    internal_price_changes_title: 'Justeringar självkostnad',
    external_price_changes_title: 'Justeringar utpris',
    internal_price_title: 'Självkostnad',
    external_price_title: 'Utpris',
    current_internal_price_title: 'Nuvarande självkostnad',
    current_external_price_title: 'Nuvarande utpris',
  },

  status: {
    active: 'Aktiv',
    blocked: 'Blockerad',
    invited: 'Inbjuden',
    inactive: 'Inaktiv',
  },

  role: {
    super_admin: 'Super admin',
    customer_admin: 'Kund-admin',
    user: 'Användare',
  },

  system_role: {
    employee: 'Anställd',
    manager: 'Manager',
  },

  salary_type: {
    monthly: 'Månadslön',
    hourly: 'Timanställd',
  },
  empty: 'Inga anställda',

  // Attributes
  attributes: {
    name: 'Namn',
    email: 'Email',
    phone_number: 'Telefonnummer',
    personal_number: 'Personnummer',
    system_role: 'System-roll',
    work_title: 'Jobbtitel',
    employee_number: 'Anställningsnummer',
    manager: 'Manager',
    office: 'Kontor',
    department: 'Avdelning',
    hourly_price: 'Utpris',
    internal_price: 'Självkostnad',
    work_rate: 'Nuvarande tjänstgöringsgrad',
    status: 'Status',
    firm_admin: 'Byrå-admin',
    time_balance: 'Tidbank (Flex)',
    salary_type: 'Lönetyp',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera uppgifter för: "{name}"',
    destroy: 'Ta bort "{name}"',
    additional_information_required: 'Kompletterande info krävs',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Ta bort anställd',
      text: 'Är du säker på att du vill ta bort <strong>{name}</strong>?',
    },
    destroy_price: {
      title: 'Ta bort pris',
      text: 'Är du säker på att du vill ta bort dennes pris?',
    },
  },
  portal: {
    status: {
      active: 'Aktiv',
      inactive: 'Inaktiv',
      not_invited: 'Ej inbjuden',
      invited: 'Inbjuden',
    },
    confirm: {
      activate: {
        title: 'Bjud in kontakt',
        text: 'Är du säker på att du vill bjuda in <strong>{name}</strong> till kundportalen?',
      },
      deactivate: {
        title: 'Ta bort tillgång till kundportalen',
        text: 'Är du säker på att du vill ta bort tillgång till kundportalen för <strong>{name}</strong>?',
      },
    },
  },

  // Work rates
  work_rates: {
    title: 'Tjänstgöringsgrader',
    history: {
      title: 'Historik',
    },
    create: {
      title: 'Sätt ny tjänstgöringsgrad',
    },
    edit: {
      title: 'Redigera tjänstgöringsgrad',
    },
    attributes: {
      date: 'Datum',
      rate: 'Grad',
    },
    flexible_time: {
      even_weeks: 'Jämna veckor',
      odd_weeks: 'Udda veckor',
      attributes: {
        day: 'Dag',
        default: 'Default',
        employee: 'Anställd',
        rate: 'Grad',
        total: 'Summa',
        net_total: 'Totalsumma',
      },
    },
    form: {
      date: 'Ändring från datum',
      rate: 'Tjänstgöringsgrad',
      is_flexible: 'Variabel tjänstgöringsgrad',
      is_flexible_week: 'Samma schema alla veckor',
    },
    confirm: {
      destroy: {
        title: 'Radera tjänstgöringsgrad',
        text: 'Är du säker på att du vill ta bort denna tjänstgöringsgrad?',
      },
    },
  },

  internal_price: {
    title: 'Redigera självkostnad',
    history: {
      title: 'Historik',
    },
    attributes: {
      date: 'Datum',
      price: 'Kostnad',
    },
    form: {
      date: 'Ändring från datum',
      price: 'Intern kostnad',
    },
  },

  external_price: {
    title: 'Redigera utpris',
    history: {
      title: 'Historik',
    },
    attributes: {
      date: 'Datum',
      price: 'Pris',
    },
    form: {
      date: 'Ändring från datum',
      price: 'Pris',
    },
  },

  deadlines: {
    title: 'Deadlines för v.{weekNumber}',
    show_completed: 'Visa klara uppgifter',
  },

  profile: {
    title: 'Min profil',
    contact_information: 'Kontaktinformation',
    other_info: 'Övrig information',
    my_prices: 'Mina priser',
  },

  vehicle: {
    type: {
      private_car: 'Egen bil',
      company_car_diesel: 'Företagsbil: DIESEL',
      company_car_petrol: 'Företagsbil: Bensin/ÖVRIGA',
      company_car_electric: 'Företagsbil: EL',
    },
    index: {
      title: 'Mina fordon',
      create: 'Skapa fordon',
    },
    create: {
      title: 'Skapa fordon',
    },
    edit: {
      title: 'Redigera fordon',
    },
    empty: 'Inga fordon',
    attributes: {
      name: 'Namn',
      type: 'Fordonstyp',
      created_at: 'Tillagt i min profil',
    },
    tooltip: {
      edit: 'Redigera "{name}" fordon',
      delete: 'Radera "{name}" fordon',
    },
    confirm: {
      delete: {
        title: 'Radera fordon',
        text: 'Är du säker på att du vill radera <strong>{name}</strong> fordon?',
      },
    },
  },

  todos: {
    todos: {
      title: 'Att göra',
    },
    informations: {
      title: 'Info (senaste 7 dagarna)',
    },
    tooltip: {
      show_with_deadline: 'Visa med deadline',
      show_all: 'Visa alla',
      show_hidden: 'Visa stängda',
      comment: 'Kommentera',
      complete: 'Klarmarkera',
      hide: 'Dölj för mig',
      show_history: 'Visa historik',
      answer: 'Svara',
      answered: 'Besvarad',
    },
  },

  stats: {
    title: 'WeConnect Portal',
    tasks: 'Mina uppgifter',
    messages: 'Mina meddelanden',
  },
  notifications: {
    title: 'Notifieringar',
    todos: 'Att göra',
    infos: 'Info',
  },

  time_balance: {
    title: 'Tidbank (Flex) - {name}',
    current_balance: 'Aktuellt saldo',
    manual_edit: 'Ändringar av saldo',
    attributes: {
      date: 'Datum',
      change: 'Ändring',
      balance: 'Saldo',
      description: 'Beskrivning',
    },
    type: {
      deposit: 'Lägg till timmar',
      debit: 'Dra av timmmar',
    },
    form: {
      type: 'Typ',
      sum: 'Antal timmar',
      comment: 'Beskrivning',
    },
  },
};
