import route from 'ziggy-js';
import { Options, SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IGetProjectTasksEstimatesRequestBody,
  IProjectClientTabResource,
  IProjectEstimates,
  IProjectResource,
  IProjectMargin,
  IProjectPreviewResource,
  IProjectRequestActivateStepBody,
  IProjectRequestNotes,
  IProjectRequestPriceStepBody,
  IProjectRequestServiceStepBody,
  IProjectRequestTeamStepBody,
  IProjectTaskEstimates,
  IManageProjectTasksRequest,
  IProjectTeamMemberListResource,
  IProjectTeamMemberRequestBody,
  IProjectWithTeamsResource,
  IProjectTaskGeneratedEvents,
  IProjectTaskGenerateEventsRequestBody,
  ICancelOrRolloverProjectOptions,
  IProjectDetailResource,
} from '@/types/Project';
import { IServiceTaskResource } from '@/types/Service';
import { IClientPlanningResource } from '@/types/Client';

export default {
  list(options: Options = {}) {
    return httpClient.get(route('clients.projects.list'), options).json<IResponse<IProjectPreviewResource[]>>();
  },
  index(client: string, searchParams: SearchParamsOption): Promise<IResponse<IProjectPreviewResource[]>> {
    return httpClient.get(route('clients.projects.index', { client }), { searchParams }).json();
  },
  listWithFullResource(
    client: string,
    searchParams: SearchParamsOption,
  ): Promise<IResponse<IProjectWithTeamsResource[]>> {
    return httpClient.get(route('clients.projects.list-with-full-resource', { client }), { searchParams }).json();
  },
  listProjectTabResource(
    client: string,
    searchParams: SearchParamsOption,
  ): Promise<IResponse<IProjectClientTabResource[]>> {
    return httpClient.get(route('clients.projects.list-for-project-tab', { client }), { searchParams }).json();
  },
  listForPlanning(client: string, searchParams: SearchParamsOption): Promise<IResponse<IClientPlanningResource>> {
    return httpClient.get(route('clients.projects.list-for-planning', { client }), { searchParams }).json();
  },
  get(client: string, id: number): Promise<IResponse<IProjectResource>> {
    return httpClient.get(route('clients.projects.get', { client, id })).json();
  },
  store(body: IProjectRequestServiceStepBody, client: string): Promise<IResponse<IProjectResource>> {
    return httpClient.post(route('clients.projects.store', { client }), { json: body }).json();
  },
  update(
    json:
      | IProjectRequestServiceStepBody
      | IProjectRequestPriceStepBody
      | IProjectRequestTeamStepBody
      | IProjectRequestActivateStepBody
      | IProjectRequestNotes,
    client: string,
    id: number,
    options: Options = {},
  ): Promise<IResponse<IProjectResource>> {
    return httpClient.patch(route('clients.projects.update', { client, id }), { ...options, json }).json();
  },
  cancel(client: string, id: number, body: ICancelOrRolloverProjectOptions): Promise<IResponse<IProjectResource>> {
    return httpClient.post(route('clients.projects.cancel', { client, id }), { json: body }).json();
  },
  close(client: string, id: number, json: { start_new_project: boolean }) {
    return httpClient
      .post(route('clients.projects.cancel', { client, id }), { json })
      .json<IResponse<IProjectResource>>();
  },
  rollover(client: string, id: number, body: ICancelOrRolloverProjectOptions): Promise<IResponse<IProjectResource>> {
    return httpClient.post(route('clients.projects.rollover', { client, id }), { json: body }).json();
  },
  reactivate(client: string, id: number) {
    return httpClient.post(route('clients.projects.reactivate', { client, id })).json<IResponse<IProjectResource>>();
  },
  destroy(client: string, id: number): Promise<IResponse<never>> {
    return httpClient.delete(route('clients.projects.delete', { client, id })).json();
  },
  margin(searchParams: SearchParamsOption): Promise<{ [key: number]: IProjectMargin }> {
    return httpClient.get(route('clients.projects.margin'), { searchParams }).json();
  },
  details(client: string, id: number, options: Options = {}) {
    return httpClient
      .get(route('clients.projects.details', { client, id }), options)
      .json<IResponse<IProjectDetailResource>>();
  },
  teamMember: {
    list(client: string, id: number): Promise<IResponse<IProjectTeamMemberListResource[]>> {
      return httpClient.get(route('clients.projects.team-members.index', { client, id })).json();
    },
    add(
      client: string,
      id: number,
      body: IProjectTeamMemberRequestBody,
    ): Promise<IResponse<IProjectTeamMemberListResource>> {
      return httpClient.put(route('clients.projects.team-members.add', { client, id }), { json: body }).json();
    },
    destroy(client: string, id: number, user_uuid: string): Promise<IResponse<never>> {
      return httpClient.delete(route('clients.projects.team-members.delete', { client, id, user_uuid })).json();
    },
  },
  serviceTasks: {
    index(client: string, id: number): Promise<IResponse<IServiceTaskResource[]>> {
      return httpClient.get(route('clients.projects.service-tasks.index', { client, id })).json();
    },
  },
  tasks: {
    update(client: string, id: number, body: IManageProjectTasksRequest): Promise<IResponse<IProjectResource>> {
      return httpClient.put(route('clients.projects.tasks.manage', { client, id }), { json: body }).json();
    },
    getEstimates(
      client: string,
      id: number,
      body: IGetProjectTasksEstimatesRequestBody,
    ): Promise<IProjectTaskEstimates> {
      return httpClient.post(route('clients.projects.tasks.get_estimates', { client, id }), { json: body }).json();
    },
    generateEvents(
      client: string,
      id: number,
      serviceTask: number,
      body: IProjectTaskGenerateEventsRequestBody,
    ): Promise<IProjectTaskGeneratedEvents[]> {
      return httpClient
        .post(route('clients.projects.tasks.generate_events', { client, id, serviceTask }), { json: body })
        .json();
    },
  },

  summaryEstimates(client: string, id: number, options: Options): Promise<IProjectEstimates> {
    return httpClient.post(route('clients.projects.summary_estimates', { client, id }), options).json();
  },
};
