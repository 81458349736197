<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { AppBox, AppBoxBody, AppButton, FontIcon } from '@/components';

const active = ref(false);

type Props = {
  title: string;
  opened?: boolean;
};

const props = defineProps<Props>();

onMounted(() => {
  active.value = props?.opened ?? false;
});

function toggle() {
  active.value = !active.value;
}

defineExpose({
  toggle,
});
</script>

<template>
  <AppBox shadow>
    <AppBoxBody v-auto-animate>
      <div class="d-flex align-items-center">
        <h3 class="mb-0" v-text="title" />
        <slot name="middleHeader" />
        <AppButton class="ml-auto" @click.prevent="toggle" light circle>
          <FontIcon :name="active ? 'chevron-up' : 'chevron-down'" />
        </AppButton>
      </div>

      <div v-if="active">
        <slot />
      </div>
    </AppBoxBody>
  </AppBox>
</template>
