import { IUserPreviewResource } from '@/types/User';
import { IProjectPreviewResource, ITimeEntryProjectResource, ProjectStatusType } from '@/types/Project';
import { IClientPreviewResource } from '@/types/Client';
import { IActivityTemplateResource, IMetaActivityResource } from '@/types/Activity';
import { IServiceMinimizedResource, IServicePreviewResource, IServiceTaskResource } from '@/types/Service';
import { IProjectTaskPreviewResource, ProjectTaskPriceType } from '@/types/ProjectTask';
import { RepetitionFrequencyType, ScheduledTimeUnitType } from '@/types/Common';
import { IFinancialYearResource } from '@/types/FinancialYear';
import { IInternalProjectResource } from '@/types/InternalProject';
import { ITravelResource } from '@/types/Expense';

export type EventableData = {
  id: number;
  name: string;
  note: null | string;
  project?: IProjectPreviewResource;
  template?: IActivityTemplateResource;
};

export interface IEventPreviewResource {
  id: number;
  user: IUserPreviewResource;
  scheduled_time: number;
  scheduled_time_unit: ScheduledTimeUnitType;
  original_scheduled_time: number;
  original_scheduled_time_unit: ScheduledTimeUnitType;
  week: number;
  is_global: boolean;
  done_at: null | string;
  eventable_type: EventType;
  eventable_data: EventableData;
  tracked_time: number;
  time_sheet_locked: boolean;
}

export interface IEventReportedTimeResource {
  events: {
    data: IEventReportedTimeFullResource[];
  };
  users_events: IEventReportedTimeFullResource[];
  meta: {
    total: number;
    per_page: number;
  };
}

export interface IEventReportedTimeFullResource extends IEventPreviewResource {
  billable_time: number;
  not_billable_time: number;
  billing_notes: [];
  notes: [];
}

export interface IEventTrackedTimeResource extends IEventTrackedTimePreviewResource {
  eventable_type: EventType;
  eventable_data: EventableData;
  event: IEventPreviewResource;
}

export interface ISplitEventResource {
  id: number;
  tracked_time: number;
  user: IUserPreviewResource;
  week: number;
}

export type IEventWeekRequestBody = {
  week: number;
  projectId: number;
};

export interface ISplitEventRequestBody {
  user_uuid: string;
  week: number;
  time: number;
}

export interface IPrioritiseEventsRequestBody {
  ids: number[];
  is_prioritised: boolean;
}

export interface IDoneEventRequestBody {
  done: boolean;
  report_residual_time?: boolean;
}

export interface IEventAffectedWeeks {
  affectedWeeks: number[];
}

export interface IMoveEventsRequestBody {
  ids: number[];
  week?: number;
  user_uuid?: string;
  change_future_responsible?: boolean;
}

export enum TaskStatusOptions {
  ALL = 'all',
  DONE = 'done',
  NOT_DONE = 'not_done',
}

interface IEventTimelineTime {
  estimated: number;
  finished: number;
  reported: number;
  remaining: number;
  budgeted: number;
}

export interface IEventTimelineWeek {
  activities: {
    events: {
      id: number;
      name: string;
      done_at: null | string;
      is_prioritised: boolean;
      time: IEventTimelineTime;
    }[];
    time: IEventTimelineTime;
  };
  tasks: {
    events: {
      uuid: string;
      name: string;
      time: IEventTimelineTime;
      other_information: null | string;
      responsible: IUserPreviewResource;
      active_services: IServiceMinimizedResource[];
      financial_year: IFinancialYearResource;
      projects: {
        id: number;
        name: string;
        status: ProjectStatusType;
        time: IEventTimelineTime;
        start_date: string;
        end_date: string;
        deadline_date: null | string;
        responsible: IUserPreviewResource;
        team_members: IUserPreviewResource[];
        financial_year: IFinancialYearResource;
        tasks: {
          id: number;
          name: string;
          done_at: null | string;
          note: null | string;
          time: IEventTimelineTime;
          deadline: null | string;
          sub_label: null | string;
          is_prioritised: boolean;
        }[];
      }[];
    }[];
  };
  time: IEventTimelineTime;
  time_sheet: IWeeklyReportedTimeResource;
  user_working_time_minutes: number;
  is_completed: boolean;
  week: string;
}

export enum EventType {
  ProjectTask = 'project_task',
  Activity = 'activity',
}

export interface IEventTrackedTimePreviewResource {
  id: number;
  date: string;
  time: number;
  original_time: number;
  original_time_unit: ScheduledTimeUnitType;
  note: null | string;
  is_locked: boolean;
  user: IUserPreviewResource;
}

export interface IDeadlineEventResource {
  id: number;
  deadline: null | string;
  done_at: null | string;
  user: IUserPreviewResource;
  client: IClientPreviewResource;
  project: IProjectPreviewResource;
  service: IServicePreviewResource;
  projectResponsible: IUserPreviewResource;
  task: IProjectTaskPreviewResource;
}

export interface IDeadlineEventResource {
  id: number;
  deadline: null | string;
  done_at: null | string;
  user: IUserPreviewResource;
  client: IClientPreviewResource;
  project: IProjectPreviewResource;
  service: IServicePreviewResource;
  projectResponsible: IUserPreviewResource;
  task: IProjectTaskPreviewResource;
}

export interface IClientVisitResource {
  id: number;
  week: number;
  visit_is_booked: boolean;
  user: IUserPreviewResource;
  client: IClientPreviewResource;
  projectResponsible: IUserPreviewResource;
  task: IProjectTaskPreviewResource;
  financialYear: IFinancialYearResource;
}

// Events resources
export interface IEventAdditionalFieldResource {
  id: string;
  value: string | null;
}

export interface ITimeEntryEventResource {
  id: number;
  week: number;
  scheduled_time: number;
  scheduled_time_unit: ScheduledTimeUnitType;
  original_scheduled_time: number;
  original_scheduled_time_unit: ScheduledTimeUnitType;
  done_at: null | string;
  note: null | string;
  deadline: null | string;
  visit_is_booked: boolean;
  is_prioritised: boolean;
  disabled: boolean;
  user: IUserPreviewResource;
  split_events: ISplitEventResource[];
  split_events_time_total: number;
  mileage_allowance: null | ITravelResource;
}

export interface ITimeEntryActivityEventResource extends ITimeEntryEventResource {
  activity_id: number;
  activity_name: string;
  frequency: null | RepetitionFrequencyType;
  meta: IMetaActivityResource[];
  template: IActivityTemplateResource;
  start_date: string;
  end_date: null | string;
  internal_project: null | Pick<IInternalProjectResource, 'id' | 'name'>;
  additional_fields: null | IEventAdditionalFieldResource[];
  tracked_time: IEventTrackedTimePreviewResource[];
}

export interface IEventTimeOverBudget {
  billable: null | number;
  not_billable: null | number;
  note: null | string;
}

export interface IProjectTaskEventTrackedTimePreviewResource extends IEventTrackedTimePreviewResource {
  time_over_budget: IEventTimeOverBudget;
}

export interface ITimeEntryProjectTaskEventResource extends ITimeEntryEventResource {
  client: IClientPreviewResource;
  project: ITimeEntryProjectResource;
  serviceTask: IServiceTaskResource;
  default_frequency: null | RepetitionFrequencyType;
  custom_price: boolean;
  custom_price_type: null | ProjectTaskPriceType;
  custom_price_value: null | number;
  is_deleted_from_plan: boolean;
  sub_label: null | string;
  tracked_time: IProjectTaskEventTrackedTimePreviewResource[];
  initial_scheduled_time: null | number;
  is_invoiced: boolean;
}

// Event requests
export interface IEventTrackedTimeRequest {
  id?: number;
  date: null | string;
  time: number;
  note: null | string;
  is_locked: boolean;
  user_uuid: string;
  // Not for request
  uid: string;
}

export interface IProjectTaskEventTrackedTimeRequest extends IEventTrackedTimeRequest {
  time_over_budget: IEventTimeOverBudget;
}

export interface IUpdateEventRequest {
  note: null | string;
  user_uuid: null | string;
  is_done: boolean;
  default_frequency: null | RepetitionFrequencyType;
  default_time_budget: number;
  change_future_responsible: boolean;
  change_future_scheduled_time: boolean;
  change_future_scheduled_time_to_actual: boolean;
}

export interface ITimeEntryProjectTaskEventRequest extends IUpdateEventRequest {
  custom_price: boolean;
  custom_price_type: null | ProjectTaskPriceType;
  custom_price_value: null | number;
  task_week: null | number;
  tracked_time: IProjectTaskEventTrackedTimeRequest[];
  sub_label: null | string;
  deadline: null | string;
  visit_is_booked: boolean;
  mileage_allowance: null | {
    vehicle_id: null | number;
    mileage: number;
    amount: number;
  };
}

export interface ITimeEntryActivityEventRequest extends IUpdateEventRequest {
  tracked_time: IEventTrackedTimeRequest[];
  task_week: number;
  additional_fields: null | IEventAdditionalFieldRequestBody[];
  activity: {
    start_date?: null | string;
    end_date?: null | string;
    activity_template_id?: null | number;
    internal_project_id?: null | number;
    frequency?: null | RepetitionFrequencyType;
  };
  mileage_allowance: null | {
    vehicle_id: null | number;
    mileage: number;
    amount: number;
  };
}

export interface IEventAdditionalFieldRequestBody {
  id: string;
  value: null | string;
}

export interface IWeeklyReportedTimeResource {
  events: {
    id: number;
    name: string;
    type: EventType;
    is_absence: boolean;
    total: number;
    residual_time: number;
    is_quick_report_time_allowed: boolean;
    is_fixed_price: boolean;
    done_at: null | string;
    days: {
      [key: string]: {
        time: number;
        note: null | string;
      }[];
    };
  }[];
  summary: {
    days: {
      [key: string]: number;
    };
    total: number;
  };
  working_hours: {
    days: {
      [key: string]: {
        time: number;
        is_holiday: boolean;
      };
    };
  };
  week: number;
}

export interface IResourcePlanningEmployeeEvent {
  id: number;
  week: number;
  time: number;
  done_at: string | null;
}

export interface IResourcePlanningEmployeeTask {
  id: number;
  name: string;
  frequency: null | RepetitionFrequencyType;
  events: IResourcePlanningEmployeeEvent[];
}

export interface IResourcePlanningEmployee {
  uuid: string;
  name: string;
  office: null | string;
  work_title: null | string;
  department: null | string;
  work_rate: number;
  planned: number;
  available: number;
  net: number;
  weeks: {
    week: number;
    planned: number;
    available: number;
  }[];
  projects: {
    id: number;
    name: string;
    company: {
      uuid: string;
      name: string;
    };
    weeks: {
      week: number;
      time: number;
    }[];
    tasks: IResourcePlanningEmployeeTask[];
  }[];
  activities: IResourcePlanningEmployeeTask[];
}

export interface IResourcePlanningTotal {
  planned: number;
  available: number;
  net: number;
  weeks: {
    week: number;
    planned: number;
    available: number;
  }[];
}
export interface IResourcePlanning {
  total: IResourcePlanningTotal;
  employees: IResourcePlanningEmployee[];
}

export interface IEventReportTimeRequest {
  date: string;
  user_uuid: string;
  time: number;
}
