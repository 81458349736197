<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { ref } from 'vue';
import useTime from '@/composables/useTime';
import useDate from '@/composables/useDate';
import {
  AppTable,
  AppTableHead,
  AppTableBody,
  AppTableTr,
  AppTableTh,
  AppTableTd,
  AppButton,
  FontIcon,
} from '@/components';
import { ISplitEventResource } from '@/types/Event';

const props = defineProps<{
  splitEvents: ISplitEventResource[];
  originalScheduledTime: number;
}>();

const isSplitExpanded = ref(false);

const { t } = useI18n({ useScope: 'global' });
const { convertMinutesToTime } = useTime();
const { formatYearWeek } = useDate();
const sortedEvents = computed(() => {
  return [...props.splitEvents].sort((a, b) => a.week - b.week);
});
</script>

<template>
  <div v-if="splitEvents.length > 0">
    <h3 v-text="t('time-entry.modal.split_events.title')"></h3>
    <AppTable hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTd class="pl-0" nowrap>
            {{ t('time-entry.modal.split_events.original_scheduled_time') }}
          </AppTableTd>
          <AppTableTd class="pr-0" nowrap> </AppTableTd>
          <AppTableTd nowrap>
            {{ convertMinutesToTime(originalScheduledTime) }}
          </AppTableTd>
        </AppTableTr>
        <AppTableTr>
          <AppTableTd class="pl-0">{{ t('time-entry.modal.split_events.total') }}</AppTableTd>
          <AppTableTd class="pr-0"></AppTableTd>
          <AppTableTd>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                {{ convertMinutesToTime(splitEvents.reduce((sum, item) => sum + item.tracked_time, 0)) }}
              </span>
              <AppButton @click.prevent="isSplitExpanded = !isSplitExpanded" light circle size="small">
                <FontIcon :name="isSplitExpanded ? 'chevron-up' : 'chevron-down'" />
              </AppButton>
            </div>
          </AppTableTd>
        </AppTableTr>
        <AppTableTr v-if="isSplitExpanded">
          <AppTableTh class="pl-0" nowrap>
            {{ t('time-entry.modal.split_events.week') }}
          </AppTableTh>
          <AppTableTh class="pr-0" nowrap>
            {{ t('time-entry.modal.split_events.employee') }}
          </AppTableTh>
          <AppTableTh nowrap>
            {{ t('time-entry.modal.split_events.time') }}
          </AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <template v-if="isSplitExpanded">
          <AppTableTr v-for="event in sortedEvents" :key="event.id">
            <AppTableTd class="pl-0">{{ formatYearWeek(event.week) }}</AppTableTd>
            <AppTableTd class="pr-0">{{ event.user.name }}</AppTableTd>
            <AppTableTd>{{ convertMinutesToTime(event.tracked_time) }}</AppTableTd>
          </AppTableTr>
        </template>
      </AppTableBody>
    </AppTable>
  </div>
</template>
