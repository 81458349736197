import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  ICreateFinalInvoiceRequest,
  IFetchNotInvoicedWorksRequest,
  IInvoiceCreateRequestBody,
  IInvoicePreviewResource,
  IInvoiceRequestBody,
  IInvoiceResource,
  IInvoicesIsGeneratingBody,
} from '@/types/Invoice';

export default {
  index(option: Options = {}) {
    return httpClient
      .get(route('invoices.index'), option)
      .json<IResponse<IInvoicePreviewResource[]> & { total_sum: string; total_external_sum: string }>();
  },
  get(invoice: number) {
    return httpClient
      .get(route('invoices.get', { invoice }))
      .json<IResponse<IInvoiceResource> & { next: null | number; prev: null | number }>();
  },
  store(json: IInvoiceCreateRequestBody) {
    return httpClient.post(route('invoices.store'), { json }).json<IResponse<IInvoiceResource>>();
  },
  update(invoice: number, json: IInvoiceRequestBody) {
    return httpClient.put(route('invoices.update', { invoice }), { json }).json<IResponse<IInvoiceResource>>();
  },
  destroy(invoice: number): Promise<void> {
    return httpClient.delete(route('invoices.delete', { invoice })).json();
  },
  approve(json: IInvoiceRequestBody, invoice: number) {
    return httpClient.post(route('invoices.approve', { invoice }), { json }).json<IResponse<IInvoiceResource>>();
  },
  generate(timePeriod: number) {
    return httpClient.post(route('invoices.generate', { timePeriod })).json<void>();
  },
  fetchNotInvoicedWorks(json: IFetchNotInvoicedWorksRequest, option: Options = {}) {
    return httpClient.post(route('invoices.fetchNotInvoicedWorks'), { ...option, json }).json<void>();
  },
  createFinalInvoice(json: ICreateFinalInvoiceRequest, option: Options = {}) {
    return httpClient.post(route('invoices.createFinalInvoice'), { ...option, json }).json<{ id: null | number }>();
  },
  isGenerating() {
    return httpClient.get(route('invoices.generate.generating')).json<IInvoicesIsGeneratingBody>();
  },
};
