<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import {
  AppBox,
  AppButton,
  FontIcon,
  PlanningProgressMultiBar,
  TimelineDoneButton,
  TimelinePrioritiseButton,
  TimelineReportResidualTimeButton,
  TimelineRow,
  TimelineTime,
} from '@/components';
import useTime from '@/composables/useTime';
import { IEventTimelineWeek } from '@/types/Event';

const { expanded, weekNumber, data, loadingToReportResidualTime, loadingToDone } = defineProps<{
  expanded: boolean;
  weekNumber: string;
  data: IEventTimelineWeek['activities'];
  loadingToDone: number[];
  loadingToReportResidualTime: number[];
  loadingEventsToPrioritise: number[];
}>();

const emit = defineEmits<{
  (e: 'toggle', week: string): void;
  (e: 'edit', id: number): void;
  (e: 'prioritise', id: number, prioritised: boolean): void;
  (e: 'done', id: number, done: boolean): void;
  (e: 'reportResidualTime', id: number): void;
}>();

const { convertMinutesToTime } = useTime();
const { t } = useI18n({ useScope: 'global' });

function dragstart(dragEvent: DragEvent, id: number) {
  dragEvent.stopPropagation();
  if (!dragEvent.dataTransfer) return;
  (dragEvent.target as HTMLDivElement).classList.add('dragging');
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('from_week', weekNumber);
  dragEvent.dataTransfer.setData('source', 'activity');
  dragEvent.dataTransfer.setData('events', id.toString());
}

function dragend(dragEvent: DragEvent) {
  dragEvent.stopPropagation();
  (dragEvent.target as HTMLDivElement).classList.remove('dragging');
}
</script>

<template>
  <AppBox v-if="data.events.length" style="margin-top: 1px" shadow>
    <div>
      <TimelineRow>
        <template #name>
          <h4 class="mb-0">
            {{ t('dashboard.index.firm_activities') }}
          </h4>
        </template>
        <template #actions>
          <AppButton size="small" @click.prevent="emit('toggle', weekNumber)" light circle>
            <FontIcon v-if="expanded" name="chevron-up" />
            <FontIcon v-else name="chevron-down" />
          </AppButton>
        </template>
        <template #time>
          <strong class="text-5" v-text="convertMinutesToTime(data.time.budgeted)" />
        </template>
        <template #progress>
          <PlanningProgressMultiBar
            :green="data.time.finished"
            :orange="data.time.reported"
            :gray="Math.max(data.time.remaining, 0)"
          />
        </template>
      </TimelineRow>
      <div class="mt-0" v-if="expanded">
        <TimelineRow
          v-for="activity in data.events"
          :key="activity.id"
          :class="[{ draggable: !activity.done_at }]"
          :draggable="!activity.done_at"
          @dragstart="dragstart($event, activity.id)"
          @dragend="dragend"
        >
          <template #name>
            <strong
              class="pointer text-secondary-500 text-5 pl-3"
              @click.prevent="emit('edit', activity.id)"
              v-text="activity.name"
            />
          </template>
          <template #time>
            <TimelineTime :value="activity.time.budgeted" />
          </template>
          <template #actions>
            <TimelineDoneButton
              @click.prevent="emit('done', activity.id, !Boolean(activity.done_at))"
              :is-done="!!activity.done_at"
              :loading="loadingToDone.includes(activity.id)"
            />
            <TimelinePrioritiseButton
              @click.prevent="emit('prioritise', activity.id, !activity.is_prioritised)"
              :is-prioritised="activity.is_prioritised"
              :loading="loadingEventsToPrioritise.includes(activity.id)"
            />
          </template>
          <template #progress>
            <div class="d-flex align-items-center gap-2">
              <div class="flex-grow-1">
                <PlanningProgressMultiBar
                  :green="activity.time.finished"
                  :orange="activity.time.reported"
                  :gray="Math.max(activity.time.remaining, 0)"
                />
              </div>
              <div v-if="!activity.done_at" class="flex-shrink-0">
                <TimelineReportResidualTimeButton
                  @click.prevent="emit('reportResidualTime', activity.id)"
                  :loading="loadingToReportResidualTime.includes(activity.id)"
                />
              </div>
            </div>
          </template>
        </TimelineRow>
      </div>
    </div>
  </AppBox>
</template>
