import api from '@/services/api';
import { ref } from 'vue';
import { IUserListResource, IUserPreviewResource } from '@/types/User';
import { Options } from 'ky';

type GetUsersHooks = {
  onSet?: (rawUsers: IUserListResource[]) => IUserListResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useUsers() {
  const users = ref<IUserListResource[]>([]);
  const usersLoading = ref(false);

  async function getUsers(options?: Options, hooks?: GetUsersHooks) {
    try {
      usersLoading.value = true;
      const response = await api.users.list(options);
      users.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error: unknown) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      usersLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }

  function getMergedUsersList(
    baseList: IUserPreviewResource[],
    mergeWith: IUserPreviewResource[] | IUserPreviewResource | null | undefined,
  ): IUserPreviewResource[] {
    const usersList = [...baseList];

    const mergeWithArray = !mergeWith ? [] : Array.isArray(mergeWith) ? mergeWith : [mergeWith];

    const usersToAdd =
      mergeWithArray.filter((user) => !usersList.some((addedUser) => addedUser.uuid === user.uuid)) ?? [];

    usersList.push(...usersToAdd);

    return usersList;
  }

  return {
    users,
    usersLoading,
    getUsers,
    getMergedUsersList,
  };
}
