import { NavigationGuard, RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import Projects from '@/views/Projects/Projects.vue';
import Project from '@/views/Projects/Project.vue';
import ProjectView from '@/views/Projects/ProjectView.vue';
import useClientStore from '@/store/ClientStore';

const prefix = 'projects';

const preloadClient: NavigationGuard = async (to, _from, next) => {
  if (!to.params.uuid) {
    return next();
  }
  const { fetchClient, setClient, setUserPartOfTeam } = useClientStore();
  try {
    const { data, meta } = await fetchClient(to.params.uuid as string);
    setClient(data);
    setUserPartOfTeam(meta.is_user_part_of_team);
    next();
  } catch (error) {
    next({ name: 'NotFound' });
  }
};

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}/:uuid?`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Projects,
  },
  {
    path: `/${prefix}/:uuid/:id`,
    name: `${prefix}.view`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ProjectView,
    props: (route) => ({
      ...route.params,
      id: Number(route.params.id),
    }),
    beforeEnter: preloadClient,
  },
  {
    path: `/${prefix}/:uuid/create`,
    name: `${prefix}.create`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Project,
    props: true,
    beforeEnter: preloadClient,
  },
  {
    path: `/${prefix}/:uuid/:id/edit`,
    name: `${prefix}.edit`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Project,
    props: (route) => ({
      ...route.params,
      id: Number(route.params.id),
    }),
    beforeEnter: preloadClient,
  },
];

export default routes;
