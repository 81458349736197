// Minimal stubs for missing types (adjust as needed)

import { IUserMinimal } from '@/types/User';
import { IClient } from '@/types/Client';
import { IProject } from '@/types/Project';

export enum ProgressReportType {
  user = 'user',
  project = 'project',
}

// Converted from ProjectInterface.cs :contentReference[oaicite:3]{index=3}

// Converted from StatisticProgressRow.cs :contentReference[oaicite:4]{index=4}
export interface IStatisticProgressRow {
  user?: IUserMinimal;
  client?: IClient;
  customerCount: number;
  project?: IProject;
  projectCount: number;
  plannedTime: number;
  completedTime: number;
  completedBasePlanTime: number;
  diffTime: number;
  diffTimeClientResp: number;
  completePercent: number;
}

export interface IStatisticsProgressTotal {
  plannedTime: number;
  completedTime: number;
  completedBasePlanTime: number;
  diffTime: number;
  diffTimeClientResp: number;
  completePercent: number;
}

// Converted from StatisticsProgressInterface.cs :contentReference[oaicite:6]{index=6}
export interface IStatisticsProgress {
  total: IStatisticsProgressTotal;
  rows: IStatisticProgressRow[];
  reportType: ProgressReportType;
}
