export default {
  index: {
    title: 'Progress',
    help: '',
    filters: {
      customer_resp_user: 'Customer responsible',
      service: 'Service',
      report_date: 'Report date',
      fy_end_date_from: 'Financial year end from',
      creation_date: 'Created after',
      fy_end_date_to: 'Financial year end to',
      show_also_closed: 'Include closed',
      report_type: 'Report type',
      typeuser: 'Responsible',
      typeproject: 'Project',
    },
  },
  attributes: {
    total: 'Total',
    client: 'Customer name',
    client_responsible: 'Client responsible',
    project: 'Project',
    planned_time: 'Planned time',
    reported_time: 'Reported time',
    ready_gp_time: 'Done base plan time',
    diff_budget: 'Diff from planned',
    diff_budget_client_resp: 'Diff CR time',
    readiness: 'Complete %',
    number_of_clients: 'Customers #',
    number_of_projects: 'Projects #',
  },
  tooltip: {
    ready_gp_time: 'Time on ready marked tasks included in base plan.',
    percent_complete: 'Percentage of time with completed tasks relative to planned time.',
    diff_budget: '',
  },
};
