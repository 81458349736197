import { createGlobalState } from '@vueuse/core';
import { computed, shallowRef } from 'vue';

export const useInvoiceItemsState = createGlobalState(() => {
  const items = shallowRef<string[]>([]);

  const isExpandedInvoiceItemsEmpty = computed(() => items.value.length === 0);

  function expandInvoiceItem(uid: string) {
    if (isExpandedInvoiceItem(uid)) return;
    items.value = [...items.value, uid];
  }

  function collapseInvoiceItem(uid: string) {
    if (!isExpandedInvoiceItem(uid)) return;
    items.value = items.value.filter((item) => item !== uid);
  }

  function isExpandedInvoiceItem(uid: string) {
    return items.value.some((item) => item === uid);
  }

  function toggleInvoiceItem(uid: string) {
    if (isExpandedInvoiceItem(uid)) {
      collapseInvoiceItem(uid);
    } else {
      expandInvoiceItem(uid);
    }
  }

  function toggleAllInvoiceItems(uids: string[]) {
    items.value = items.value.length === 0 ? uids : [];
  }

  return {
    isExpandedInvoiceItemsEmpty,
    expandInvoiceItem,
    collapseInvoiceItem,
    isExpandedInvoiceItem,
    toggleInvoiceItem,
    toggleAllInvoiceItems,
  };
});
