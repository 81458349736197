<script setup lang="ts">
import { Editor, EditorContent } from '@tiptap/vue-3';

import { AppButton, FontIcon } from '@/components';

type Props = {
  editor: Editor;
};

const { editor } = defineProps<Props>();

defineExpose({
  //
});
</script>

<template>
  <EditorContent class="flex-grow-1" :editor="editor" />
  <div class="d-flex align-items-center mt-2">
    <div class="d-flex gap-1 editor-controls">
      <AppButton
        circle
        light
        @click.prevent="editor.chain().focus().toggleBold().run()"
        :class="{ active: editor.isActive('bold') }"
      >
        <FontIcon name="bold" />
      </AppButton>
      <AppButton
        circle
        light
        @click.prevent="editor.chain().focus().toggleItalic().run()"
        :class="{ active: editor.isActive('italic') }"
      >
        <FontIcon name="italic" />
      </AppButton>
      <AppButton
        circle
        light
        @click.prevent="editor.chain().focus().toggleStrike().run()"
        :class="{ active: editor.isActive('strike') }"
      >
        <FontIcon name="strikethrough" />
      </AppButton>
      <AppButton
        circle
        light
        @click.prevent="editor.chain().focus().toggleOrderedList().run()"
        :class="{ active: editor.isActive('orderedList') }"
      >
        <FontIcon name="list-numbers" />
      </AppButton>
      <AppButton
        circle
        light
        @click.prevent="editor.chain().focus().toggleBulletList().run()"
        :class="{ active: editor.isActive('bulletList') }"
      >
        <FontIcon name="list" />
      </AppButton>
    </div>
    <slot name="bar" />
  </div>
</template>

<style lang="scss" scoped>
.editor-controls {
  .button {
    background-color: white;
    &.active {
      background-color: var(--color-neutral-100-hex);
    }
  }
}
</style>
