<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { debounce } from 'perfect-debounce';
import { vOnClickOutside } from '@vueuse/components';

import { AppLoader, FormInput } from '@/components';
import useLoader from '@/composables/useLoader';
import { ISearchResponse } from '@/types/Search';
import api from '@/services/api';

const { t } = useI18n();
const loader = useLoader({ useProgress: false });

const active = ref(false);
const search = ref('');
const memorizedSearch = ref('');

const results = reactive<ISearchResponse>({
  clients: [],
  contacts: [],
  service_organisations: [],
  service_organisation_contacts: [],
  employees: [],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const noResults = computed(() => Object.entries(results).every(([_, records]) => records.length === 0));

function resetResults() {
  results.clients = [];
  results.contacts = [];
  results.service_organisations = [];
  results.service_organisation_contacts = [];
  results.employees = [];
}

const onSearchInput = debounce(async () => {
  const q = search.value.trim();
  if (q.length > 2) {
    loader.start();
    activateSearch();
    try {
      const response = await api.search.index(q);
      results.clients = response.data.clients;
      results.contacts = response.data.contacts;
      results.service_organisations = response.data.service_organisations;
      results.service_organisation_contacts = response.data.service_organisation_contacts;
      results.employees = response.data.employees;
    } catch (error) {
      console.error(error);
    } finally {
      loader.finish();
    }
  } else {
    resetResults();
    disableSearch();
  }
  memorizedSearch.value = q;
}, 300);

function activateSearch() {
  active.value = true;
}

function disableSearch() {
  active.value = false;
}

function onSearchFocus() {
  if (search.value !== '') {
    activateSearch();
  }
}

function wrapSearchedTerm(text: string, tag = 'mark') {
  // eslint-disable-next-line
  const regex = new RegExp(memorizedSearch.value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'gi');
  return text.replace(regex, `<${tag}>$&</${tag}>`);
}
</script>

<template>
  <div class="search-block" v-on-click-outside="disableSearch">
    <FormInput
      type="search"
      icon="search"
      @input="onSearchInput"
      @focus="onSearchFocus"
      v-model="search"
      :placeholder="t('common.search')"
      @keydown.enter.prevent
    >
      <template v-if="active" #popup>
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="small" />
        </div>
        <div v-else class="search-results">
          <!-- Empty -->
          <div v-if="noResults" class="search-section">
            <div class="search-empty" v-text="t('common.no_results')" />
          </div>

          <!-- Clients -->
          <div v-if="results.clients.length" class="search-section">
            <h4>{{ t('search.clients') }}:</h4>
            <ul>
              <li v-for="client in results.clients" :key="client.uuid">
                <RouterLink
                  @click.prevent="disableSearch"
                  :to="{ name: 'client.overview', params: { uuid: client.uuid } }"
                >
                  <span v-html="wrapSearchedTerm(`${client.name} (${client.org_number})`)" />
                </RouterLink>
              </li>
            </ul>
          </div>

          <!-- Contacts -->
          <div v-if="results.contacts.length" class="search-section">
            <h4>{{ t('search.contacts') }}:</h4>
            <ul>
              <li v-for="contact in results.contacts" :key="contact.uuid">
                <span v-html="wrapSearchedTerm(contact.name)" />
                <ul class="pl-3" v-if="contact.clients.length">
                  <li v-for="client in contact.clients" :key="client.uuid">
                    <RouterLink
                      @click.prevent="disableSearch"
                      :to="{ name: 'client.settings', params: { uuid: client.uuid } }"
                    >
                      {{ client.name }} ({{ client.org_number }})
                    </RouterLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <!-- Service organisations -->
          <div v-if="results.service_organisations.length" class="search-section">
            <h4>{{ t('search.service_organisations') }}:</h4>
            <ul>
              <li v-for="service_organisation in results.service_organisations" :key="service_organisation.uuid">
                <RouterLink
                  @click.prevent="disableSearch"
                  :to="{ name: 'service-organizations.edit', params: { uuid: service_organisation.uuid } }"
                >
                  <span v-html="wrapSearchedTerm(service_organisation.name)" />
                </RouterLink>
              </li>
            </ul>
          </div>

          <!-- Service organisation contacts -->
          <div v-if="results.service_organisation_contacts.length" class="search-section">
            <h4>{{ t('search.service_organisation_contacts') }}:</h4>
            <ul>
              <li
                v-for="service_organisation_contact in results.service_organisation_contacts"
                :key="service_organisation_contact.uuid"
              >
                <span v-html="wrapSearchedTerm(service_organisation_contact.name)" />
                <ul class="pl-3" v-if="service_organisation_contact.service_company">
                  <li>
                    <RouterLink
                      @click.prevent="disableSearch"
                      :to="{
                        name: 'service-organizations.edit',
                        params: { uuid: service_organisation_contact.service_company.uuid },
                      }"
                    >
                      {{ service_organisation_contact.service_company.name }}
                    </RouterLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <!-- Employees -->
          <div v-if="results.employees.length" class="search-section">
            <h4>{{ t('search.employees') }}:</h4>
            <ul>
              <li v-for="employee in results.employees" :key="employee.uuid">
                <RouterLink
                  @click.prevent="disableSearch"
                  :to="{ name: 'users.edit', params: { uuid: employee.uuid } }"
                >
                  <span v-html="wrapSearchedTerm(employee.name)" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </FormInput>
  </div>
</template>
