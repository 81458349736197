<script lang="ts" setup>
import { computed, inject } from 'vue';
import { SortByProvider, SortOrder, SortOrderProvider } from '@/types/Common';
import { Decimal } from 'decimal.js';

const {
  nowrap,
  sortable,
  columnSize = 0,
  align = 'start',
} = defineProps<{
  nowrap?: boolean;
  sortable?: string;
  columnSize?: number;
  align?: string;
}>();

const sortByProvider = inject<SortByProvider | null>('sortBy', null);
const sortOrderProvider = inject<SortOrderProvider | null>('sortOrder', null);

function onSort() {
  if (!sortable || !sortByProvider || !sortOrderProvider) return;
  if (sortable === sortByProvider.sortBy.value) {
    const newSortOrder = sortOrderProvider.sortOrder.value === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc;
    sortOrderProvider.updateSortOrder(newSortOrder);
  } else {
    sortByProvider.updateSortBy(sortable);
    sortOrderProvider.updateSortOrder(SortOrder.Asc);
  }
}

const columnsSize = inject<number>('columnsSize', 24);

function getWidth() {
  return new Decimal(columnSize).times(100).div(columnsSize).toDecimalPlaces(2).toNumber();
}

const styles = computed(() => {
  if (!columnSize) return {};
  return {
    width: `${getWidth()}%`,
  };
});
</script>

<template>
  <th :class="{ nowrap }" :style="styles">
    <div
      @click.prevent.stop="onSort"
      v-if="sortable && sortByProvider && sortOrderProvider"
      class="sortable d-flex align-items-center"
      :class="`justify-content-${align}`"
    >
      <slot />
      <div v-if="sortable === sortByProvider.sortBy.value" class="ml-1">
        {{ sortOrderProvider.sortOrder.value === SortOrder.Desc ? '↓' : '↑' }}
      </div>
    </div>
    <slot v-else />
  </th>
</template>

<style scoped lang="scss">
.sortable {
  text-decoration-line: underline;
  text-decoration-color: var(--color-neutral-400-hex);
  text-decoration-style: dotted;
  text-underline-offset: 4px;
  cursor: pointer;
  &:hover {
    text-decoration-style: solid;
  }
}
</style>
