<script setup lang="ts">
import { reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';

import { AppButton, FormLabel, FormInput, FormSwitch, FormMinutesDuration } from '@/components';
import useTask from '@/composables/useTask';
import { IServiceTaskRequest, ServiceTaskForm } from '@/types/Service';
import { ISalaryCodeResource } from '@/types/Salary';

const { t } = useI18n({ useScope: 'global' });
const { taskRoleOptions, taskFrequencyOptions } = useTask();

type Props = {
  task: null | IServiceTaskRequest;
  salaryCodes: ISalaryCodeResource[];
};

const { task, salaryCodes } = defineProps<Props>();

const form = reactive<ServiceTaskForm>({
  name: '',
  default_role: null,
  default_time_budget: 0,
  default_frequency: null,
  requires_visit: false,
  auto_include_in_planning: false,
  salary_code_id: null,
  is_active: true,
});

const emit = defineEmits<{
  cancel: [];
  created: [task: ServiceTaskForm];
  updated: [task: ServiceTaskForm];
  closed: [];
}>();

function submit() {
  if (task) {
    emit('updated', form);
  } else {
    emit('created', form);
  }
}
onMounted(() => {
  form.name = task?.name ?? '';
  form.default_role = task?.default_role ?? null;
  form.default_time_budget = task?.default_time_budget ?? 0;
  form.default_frequency = task?.default_frequency ?? null;
  form.requires_visit = task?.requires_visit ?? false;
  form.auto_include_in_planning = task?.auto_include_in_planning ?? false;
  form.salary_code_id = task?.salary_code_id ?? null;
  form.is_active = task?.is_active ?? true;
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    @closed="emit('closed')"
  >
    <form @keydown.enter.prevent @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="task ? task.name : t('service.tasks.modal.add')" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-4">
              <FormLabel html-for="task_name" required>{{ t(`service.tasks.attributes.name`) }}</FormLabel>
            </div>
            <div class="col-md-8">
              <FormInput v-model="form.name" id="task_name" trim required />
            </div>
          </div>
        </div>
        <!-- Default role -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-4">
              <FormLabel html-for="task_role" required>{{ t(`service.tasks.attributes.default_role`) }}</FormLabel>
            </div>
            <div class="col-md-8">
              <VueSelect
                :clearable="false"
                :searchable="false"
                :options="taskRoleOptions"
                label="label"
                :reduce="(option: any) => option.value"
                input-id="task_role"
                :placeholder="t('common.select')"
                required
                v-model="form.default_role"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!form.default_role"
                    v-bind="attributes as object"
                    v-on="events"
                  />
                </template>
              </VueSelect>
            </div>
          </div>
        </div>
        <!-- Default frequency -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-4">
              <FormLabel html-for="task_frequency" required>
                {{ t(`service.tasks.attributes.default_frequency`) }}
              </FormLabel>
            </div>
            <div class="col-md-8">
              <VueSelect
                :clearable="false"
                :searchable="false"
                v-model="form.default_frequency"
                :options="taskFrequencyOptions"
                label="label"
                :reduce="(option: any) => option.value"
                input-id="task_frequency"
                :placeholder="t('common.select')"
                required
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!form.default_frequency"
                    v-bind="attributes as object"
                    v-on="events"
                  />
                </template>
              </VueSelect>
            </div>
          </div>
        </div>
        <!-- Salary code -->
        <div class="row align-items-center form-group">
          <div class="col-md-4">
            <FormLabel class="mb-0" html-for="salary_code" required>
              {{ t('activities.templates.attributes.salary_code') }}
            </FormLabel>
          </div>
          <div class="col-md-8">
            <VueSelect
              :clearable="false"
              v-model="form.salary_code_id"
              input-id="salary_code"
              :placeholder="t('common.select')"
              :options="salaryCodes"
              :reduce="(option: ISalaryCodeResource) => option.id"
              label="name"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.salary_code_id"
                  v-bind="attributes as object"
                  v-on="events"
                />
              </template>
            </VueSelect>
          </div>
        </div>
        <!-- Default time budget -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-4">
              <FormLabel html-for="task_time_budget" required>
                {{ t(`service.tasks.attributes.default_time_budget`) }}
              </FormLabel>
            </div>
            <div class="col-md-8">
              <FormMinutesDuration
                :step="15"
                :max="5985"
                v-model.number="form.default_time_budget"
                id="task_time_budget"
                required
              />
            </div>
          </div>
        </div>
        <!-- Default Requires visit -->
        <div class="form-group mb-0">
          <div class="row align-items-center">
            <div class="col-md-4">
              <FormLabel html-for="task_requires_visit">
                {{ t(`service.tasks.attributes.requires_visit`) }}
              </FormLabel>
            </div>
            <div class="col-md-8">
              <FormSwitch id="task_requires_visit" v-model="form.requires_visit" />
            </div>
          </div>
        </div>
        <!-- Auto-include in planning -->
        <div class="form-group mb-0">
          <div class="row align-items-center">
            <div class="col-md-4">
              <FormLabel html-for="task_auto_include_in_planning">
                {{ t(`service.tasks.attributes.auto_include_in_planning`) }}
              </FormLabel>
            </div>
            <div class="col-md-8">
              <FormSwitch id="task_auto_include_in_planning" v-model="form.auto_include_in_planning" />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton type="button" light @click.prevent="emit('cancel')">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton v-if="task" class="mt-2 mt-sm-0" color="success">
            {{ t('common.save') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary">
            {{ t('common.add') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
