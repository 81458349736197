<script lang="ts" setup>
import { AppLoader } from '@/components';

type Props = {
  loading: boolean;
  count: number;
  text: string;
};

const { loading, count, text } = defineProps<Props>();

const emit = defineEmits<{
  click: [];
}>();
</script>

<template>
  <div class="text-3 mb-2" :class="{ pointer: count > 0 }">
    <div v-if="loading" class="text-center">
      <AppLoader size="small" />
    </div>
    <div @click.stop="emit('click')" v-else>
      <span class="badge stat-badge mr-2">{{ count }}</span>
      <span :class="{ 'text-secondary-500': count > 0 }">{{ text }}</span>
    </div>
  </div>
</template>
