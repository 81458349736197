<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import { debounce } from 'perfect-debounce';
import { useRouteQuery } from '@vueuse/router';

import toast from '@/services/toast';
import api from '@/services/api';
import progress from '@/services/progress';
import useLoader from '@/composables/useLoader';
import authStore from '@/store/AuthStore';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FontIcon,
  FormInput,
  FormLabel,
  HelpInformation,
  SalaryCodeModal,
} from '@/components';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { ISalaryCodeResource, ISalarySettingsRequest, SalarySystem } from '@/types/Salary';
import { Decimal } from 'decimal.js';
import { useTitle } from '@vueuse/core';

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { isSuperAdminRole, isCustomerAdminRole } = authStore();

const settings = reactive<ISalarySettingsRequest>({
  system: null,
  payment_km_tax_free_own_car: 0,
  payment_km_before_tax_own_car: 0,
  payment_km_before_tax_employee_car_other: 0,
  payment_km_tax_free_employee_car_other: 0,
  payment_km_before_tax_employee_car_diesel: 0,
  payment_km_tax_free_employee_car_diesel: 0,
  payment_km_before_tax_employee_car_electric: 0,
  payment_km_tax_free_employee_car_electric: 0,
  own_car_tax_free_pay_type_id: '',
  own_car_taxable_pay_type_id: '',
  employee_car_other_tax_free_pay_type_id: '',
  employee_car_other_taxable_pay_type_id: '',
  employee_car_diesel_tax_free_pay_type_id: '',
  employee_car_diesel_taxable_pay_type_id: '',
  employee_car_electricity_tax_free_pay_type_id: '',
  employee_car_electricity_taxable_pay_type_id: '',
  flex_earned_pay_type: '',
  flex_withdrawal_pay_type: '',
});

const page = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(0);
const total = ref(0);

const salarySettingsExists = ref(false);
const salarySettingsLoading = ref(false);

const salaryCodes = ref<ISalaryCodeResource[]>([]);
const salaryCodesLoading = ref(false);

const canManage = computed(() => isCustomerAdminRole || isSuperAdminRole);

async function getSalarySettings() {
  try {
    salarySettingsLoading.value = true;
    const response = await api.salary.settings.index();
    salarySettingsExists.value = !!response.data;
    settings.system = response.data?.system ?? SalarySystem.OTHER;
    settings.flex_withdrawal_pay_type = response.data?.flex_withdrawal_pay_type ?? '';
    settings.flex_earned_pay_type = response.data?.flex_earned_pay_type ?? '';
    settings.payment_km_tax_free_own_car = response.data?.payment_km_tax_free_own_car ?? 0;
    settings.payment_km_before_tax_own_car = response.data?.payment_km_before_tax_own_car ?? 0;
    settings.payment_km_before_tax_employee_car_other = response.data?.payment_km_before_tax_employee_car_other ?? 0;
    settings.payment_km_tax_free_employee_car_other = response.data?.payment_km_tax_free_employee_car_other ?? 0;
    settings.payment_km_before_tax_employee_car_diesel = response.data?.payment_km_before_tax_employee_car_diesel ?? 0;
    settings.payment_km_tax_free_employee_car_diesel = response.data?.payment_km_tax_free_employee_car_diesel ?? 0;
    settings.payment_km_before_tax_employee_car_electric =
      response.data?.payment_km_before_tax_employee_car_electric ?? 0;
    settings.payment_km_tax_free_employee_car_electric = response.data?.payment_km_tax_free_employee_car_electric ?? 0;
    settings.own_car_tax_free_pay_type_id = response.data?.own_car_tax_free_pay_type_id ?? '';
    settings.own_car_taxable_pay_type_id = response.data?.own_car_taxable_pay_type_id ?? '';
    settings.employee_car_other_tax_free_pay_type_id = response.data?.employee_car_other_tax_free_pay_type_id ?? '';
    settings.employee_car_other_taxable_pay_type_id = response.data?.employee_car_other_taxable_pay_type_id ?? '';
    settings.employee_car_diesel_tax_free_pay_type_id = response.data?.employee_car_diesel_tax_free_pay_type_id ?? '';
    settings.employee_car_electricity_tax_free_pay_type_id =
      response.data?.employee_car_electricity_tax_free_pay_type_id ?? '';
    settings.employee_car_diesel_taxable_pay_type_id = response.data?.employee_car_diesel_taxable_pay_type_id ?? '';
    settings.employee_car_electricity_taxable_pay_type_id =
      response.data?.employee_car_electricity_taxable_pay_type_id ?? '';
  } catch (error) {
    console.error(error);
  } finally {
    salarySettingsLoading.value = false;
  }
}

async function updateSalarySettings() {
  try {
    progress.start();
    salarySettingsLoading.value = true;
    await api.salary.settings.update(settings);
    salarySettingsExists.value = true;
  } catch (error) {
    console.error(error);
  } finally {
    salarySettingsLoading.value = false;
    progress.done();
  }
}

async function getSalaryCodes() {
  try {
    salaryCodesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    const response = await api.salary.codes.index({ searchParams });
    salaryCodes.value = response.data;
    if (response.meta) {
      perPage.value = response.meta.per_page;
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  } finally {
    salaryCodesLoading.value = false;
  }
}

function onCreate() {
  if (settings.system === null) return;
  const { open, close, destroy } = useModal({
    component: SalaryCodeModal,
    attrs: {
      salaryCode: null,
      system: settings.system,
      async onCreated() {
        await getSalaryCodes();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEdit(salaryCode: ISalaryCodeResource) {
  if (settings.system === null) return;
  const { open, close, destroy } = useModal({
    component: SalaryCodeModal,
    attrs: {
      salaryCode,
      system: settings.system,
      async onUpdated() {
        await getSalaryCodes();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDelete(salaryCode: ISalaryCodeResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('salary.codes.destroy.title'),
      message: t('salary.codes.destroy.text', { name: salaryCode.name }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.salary.codes.delete(salaryCode.id);
          salaryCodes.value = salaryCodes.value.filter(({ id }) => id !== salaryCode.id);
          toast.success(t('common.messages.has_been_deleted', { name: salaryCode.name }));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

onMounted(async () => {
  loader.start();
  await Promise.all([getSalaryCodes(), getSalarySettings()]);
  loader.finish();

  watch(settings, debounce(updateSalarySettings, 1000), { deep: true });
});

watch(page, async () => {
  loader.start();
  await getSalaryCodes();
  loader.finish();
});

const title = useTitle(computed(() => t('salary.settings.title')));
</script>

<style>
.car-allowance-settings input {
  padding: 5px;
  max-width: 170px;
}
.car-allowance-settings th {
  padding-right: 5px;
}
.car-allowance-settings tbody td {
  padding-bottom: 5px;
}
</style>
<template>
  <div class="container-wide">
    <!-- Settings header -->
    <div class="d-flex align-items-center mb-3">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="salary.settings.help" />
    </div>
    <AppBox>
      <AppBoxBody>
        <!-- Settings -->
        <div class="mt-4">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div class="d-flex align-items-center">
              <h2 class="mb-0" v-text="t('salary.settings.attributes.system')" />
            </div>
          </div>

          <div class="form-group row d-flex align-items-center">
            <div class="col-md-2">
              <FormLabel class="mb-0" html-for="settings_system" required>
                {{ t('salary.settings.attributes.system') }}
              </FormLabel>
            </div>
            <div class="col-md-4">
              <VueSelect
                input-id="settings_system"
                v-model="settings.system"
                :options="Object.values(SalarySystem)"
                :clearable="false"
                :disabled="salarySettingsLoading"
                :get-option-label="(option: SalarySystem) => option.charAt(0).toUpperCase() + option.slice(1)"
                :placeholder="t('common.select')"
              />
            </div>
          </div>
        </div>
        <!-- Settings -->
        <div class="mt-4" v-if="settings.system === SalarySystem.VISMA">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div class="d-flex align-items-center">
              <h2 class="mb-0" v-text="t('salary.settings.attributes.flex')" />
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-6 col-lg-4">
              <FormLabel html-for="flex_earned_pay_type">{{
                t('salary.settings.attributes.flex_earned_pay_type')
              }}</FormLabel>
              <FormInput id="flex_earned_pay_type" v-model="settings.flex_earned_pay_type" />
            </div>
            <div class="form-group col-md-6 col-lg-4">
              <FormLabel html-for="flex_withdrawal_pay_type">{{
                t('salary.settings.attributes.flex_withdrawal_pay_type')
              }}</FormLabel>
              <FormInput id="flex_withdrawal_pay_type" v-model="settings.flex_withdrawal_pay_type" />
            </div>
          </div>
        </div>
        <!-- Car reinbursment -->
        <div class="mt-4">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div class="d-flex align-items-center">
              <h2 class="mb-0" v-text="t('salary.settings.attributes.car_reinbursment_heading')" />
            </div>
          </div>
          <div class="form-group row d-flex align-items-center mb-0">
            <div class="col-md-12"></div>
            <div class="col-md-12">
              <table class="car-allowance-settings">
                <thead>
                  <tr>
                    <th>{{ t('salary.settings.attributes.car_type') }}</th>
                    <th>{{ t('salary.settings.attributes.payment_km_taxable') }}</th>
                    <th>{{ t('salary.settings.attributes.payment_km_taxfree') }}</th>
                    <th>{{ t('salary.settings.attributes.salary_code_taxable') }}</th>
                    <th>{{ t('salary.settings.attributes.salary_code_taxfree') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ t('salary.settings.attributes.car_type_own_car') }}</td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="payment_km_before_tax_own_car"
                        :value="settings.payment_km_before_tax_own_car.toFixed(2)"
                        @change="
                          settings.payment_km_before_tax_own_car = new Decimal($event.target.value)
                            .toDecimalPlaces(2)
                            .toNumber()
                        "
                        :min="0"
                        :max="100000"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="payment_km_tax_free_own_car"
                        :value="settings.payment_km_tax_free_own_car.toFixed(2)"
                        @change="
                          settings.payment_km_tax_free_own_car = new Decimal($event.target.value)
                            .toDecimalPlaces(2)
                            .toNumber()
                        "
                        :min="0"
                        :max="100000"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="own_car_taxable_pay_type_id"
                        v-model="settings.own_car_taxable_pay_type_id"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="own_car_tax_free_pay_type_id"
                        v-model="settings.own_car_tax_free_pay_type_id"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ t('salary.settings.attributes.car_type_employee_diesel') }}</td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="payment_km_before_tax_employee_car_diesel"
                        :value="settings.payment_km_before_tax_employee_car_diesel.toFixed(2)"
                        @change="
                          settings.payment_km_before_tax_employee_car_diesel = new Decimal($event.target.value)
                            .toDecimalPlaces(2)
                            .toNumber()
                        "
                        :min="0"
                        :max="100000"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="payment_km_tax_free_employee_car_diesel"
                        :value="settings.payment_km_tax_free_employee_car_diesel.toFixed(2)"
                        @change="
                          settings.payment_km_tax_free_employee_car_diesel = new Decimal($event.target.value)
                            .toDecimalPlaces(2)
                            .toNumber()
                        "
                        :min="0"
                        :max="100000"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="employee_car_diesel_taxable_pay_type_id"
                        v-model="settings.employee_car_diesel_taxable_pay_type_id"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="employee_car_diesel_tax_free_pay_type_id"
                        v-model="settings.employee_car_diesel_tax_free_pay_type_id"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ t('salary.settings.attributes.car_type_employee_other') }}</td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="payment_km_before_tax_employee_car_other"
                        :value="settings.payment_km_before_tax_employee_car_other.toFixed(2)"
                        @change="
                          settings.payment_km_before_tax_employee_car_other = new Decimal($event.target.value)
                            .toDecimalPlaces(2)
                            .toNumber()
                        "
                        :min="0"
                        :max="100000"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="payment_km_tax_free_employee_car_other"
                        :value="settings.payment_km_tax_free_employee_car_other.toFixed(2)"
                        @change="
                          settings.payment_km_tax_free_employee_car_other = new Decimal($event.target.value)
                            .toDecimalPlaces(2)
                            .toNumber()
                        "
                        :min="0"
                        :max="100000"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="employee_car_other_taxable_pay_type_id"
                        v-model="settings.employee_car_other_taxable_pay_type_id"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="employee_car_other_tax_free_pay_type_id"
                        v-model="settings.employee_car_other_tax_free_pay_type_id"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ t('salary.settings.attributes.car_type_employee_electric') }}</td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="payment_km_before_tax_employee_car_electric"
                        :value="settings.payment_km_before_tax_employee_car_electric.toFixed(2)"
                        @change="
                          settings.payment_km_before_tax_employee_car_electric = new Decimal($event.target.value)
                            .toDecimalPlaces(2)
                            .toNumber()
                        "
                        :min="0"
                        :max="100000"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="payment_km_tax_free_employee_car_electric"
                        :value="settings.payment_km_tax_free_employee_car_electric.toFixed(2)"
                        @change="
                          settings.payment_km_tax_free_employee_car_electric = new Decimal($event.target.value)
                            .toDecimalPlaces(2)
                            .toNumber()
                        "
                        :min="0"
                        :max="100000"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="employee_car_electricity_taxable_pay_type_id"
                        v-model="settings.employee_car_electricity_taxable_pay_type_id"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                    <td>
                      <input
                        :length="6"
                        class="form-control"
                        id="employee_car_electricity_tax_free_pay_type_id"
                        v-model="settings.employee_car_electricity_tax_free_pay_type_id"
                        :disabled="salarySettingsLoading"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Loader -->
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="large" />
        </div>

        <!-- Salary codes -->
        <template v-else>
          <div class="mt-4" v-if="salarySettingsExists">
            <!-- Codes header -->
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div class="d-flex align-items-center">
                <h2 class="mb-0" v-text="t('salary.codes.title')" />
                <HelpInformation class="ml-1" translation="salary.codes.help" />
              </div>
              <AppButton
                v-if="canManage"
                color="secondary"
                @click.prevent="onCreate"
                :disabled="loader.isLoading.value"
              >
                {{ t('salary.codes.add_new') }}
              </AppButton>
            </div>
            <!-- Table -->
            <AppAlert v-if="salaryCodes.length === 0">
              {{ t('salary.codes.empty') }}
            </AppAlert>
            <template v-else>
              <AppTable hoverable>
                <AppTableHead>
                  <AppTableTr>
                    <AppTableTh nowrap>{{ t('salary.codes.attributes.name') }}</AppTableTh>
                    <AppTableTh nowrap class="text-right">{{ t('salary.codes.attributes.system_id') }}</AppTableTh>
                    <AppTableTh nowrap class="text-right">{{
                      t('salary.codes.attributes.separate_id_for_hourly_employed')
                    }}</AppTableTh>
                    <AppTableTh nowrap class="text-right">{{
                      t('salary.codes.attributes.hourly_system_id')
                    }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('salary.codes.attributes.type') }}</AppTableTh>
                    <!--                <AppTableTh nowrap class="text-right" v-if="settings.system === SalarySystem.VISMA">
                  {{ t('salary.codes.attributes.absence_type') }}
                </AppTableTh>-->
                    <AppTableTh nowrap class="text-right">
                      {{ t('salary.codes.attributes.is_default') }}
                    </AppTableTh>
                    <AppTableTh nowrap>{{ t('salary.codes.attributes.client_work_time') }}</AppTableTh>
                    <AppTableTh nowrap class="text-right" v-if="canManage">{{ t('common.actions') }}</AppTableTh>
                  </AppTableTr>
                </AppTableHead>
                <AppTableBody>
                  <AppTableTr v-for="salaryCode in salaryCodes" :key="salaryCode.id">
                    <AppTableTd nowrap><strong v-text="salaryCode.name" /></AppTableTd>
                    <AppTableTd nowrap class="text-right">{{ salaryCode.system_id }}</AppTableTd>
                    <AppTableTd nowrap class="text-right">{{
                      t(`common.${salaryCode.separate_id_for_hourly_employed ? 'yes' : 'no'}`)
                    }}</AppTableTd>
                    <AppTableTd nowrap class="text-right">{{ salaryCode.hourly_system_id }}</AppTableTd>
                    <AppTableTd nowrap>{{ salaryCode.type }}</AppTableTd>
                    <!--                <AppTableTd nowrap class="text-right" v-if="settings.system === SalarySystem.VISMA">
                  {{ salaryCode.absence_type }}
                </AppTableTd>-->
                    <AppTableTd nowrap class="text-right">
                      <FontIcon class="text-3" v-if="salaryCode.is_default" name="check " />
                    </AppTableTd>
                    <AppTableTd nowrap>{{ t(`common.${salaryCode.client_work_time ? 'yes' : 'no'}`) }}</AppTableTd>
                    <AppTableTd nowrap class="text-right" v-if="canManage">
                      <AppButton
                        @click.stop.prevent="onEdit(salaryCode)"
                        size="small"
                        light
                        circle
                        v-tooltip.left="t('common.tooltip.edit', { name: salaryCode.name })"
                      >
                        <FontIcon name="pencil" />
                      </AppButton>
                      <AppButton
                        v-if="!salaryCode.is_in_use"
                        @click.stop.prevent="onDelete(salaryCode)"
                        class="ml-2"
                        color="danger"
                        size="small"
                        light
                        circle
                        v-tooltip.left="t('common.tooltip.delete', { name: salaryCode.name })"
                      >
                        <FontIcon name="trash" />
                      </AppButton>
                    </AppTableTd>
                  </AppTableTr>
                </AppTableBody>
              </AppTable>
              <AppPagination :per-page="perPage" :total="total" v-model="page" />
            </template>
          </div>
          <AppAlert class="mt-3" v-else type="danger">{{ t('salary.message.system_is_not_set') }}</AppAlert>
        </template>
      </AppBoxBody>
    </AppBox>
  </div>
</template>
