<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';
import { DateTime } from 'luxon';
import VueSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import api from '@/services/api';
import {
  AppAlert,
  AppButton,
  AppCollapse,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ColumnSettings,
  FontIcon,
  FormLabel,
  FormSwitch,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useColumnSettings from '@/composables/useColumnSettings';
import useAuthStore from '@/store/AuthStore';
import { IUserListResource, UserStatusType } from '@/types/User';
import { useTitle } from '@vueuse/core';
import { IStatisticsProgress, ProgressReportType } from '@/types/Progress';
import useServices from '@/composables/useServices';
import { IServicePreviewResource } from '@/types/Service';
import { SortOrder } from '@/types/Common';
import route from 'ziggy-js';
import router from '@/router';

const { t, d, locale } = useI18n({ useScope: 'global' });
const loader = useLoader({ useProgress: false });
const { authenticatedUser } = useAuthStore();

// Events
const progress = ref<IStatisticsProgress>();
const { services, getServices, servicesLoading } = useServices();

// Columns
const { columns, isColumnEnable, enabledColumns } = useColumnSettings('progress_columns', [
  'client_responsible',
  'client',
  'project',
  'planned_time',
  'reported_time',
  'ready_gp_time',
  'diff_budget',
  'diff_budget_client_resp',
  'readiness',
]);
// Filters
type Filters = {
  customer_resp_user: null | string;
  service: null | string;
  report_date: null | string;
  creation_date: null | string;
  fy_end_date_from: null | string;
  fy_end_date_to: null | string;
  show_also_closed: boolean;
  sortOrder: SortOrder;
  sortBy: null | string;
  report_type: ProgressReportType;
};

const filters = reactive<Filters>({
  customer_resp_user: authenticatedUser.uuid,
  service: null,
  report_type: ProgressReportType.project,
  fy_end_date_from: null,
  sortOrder: SortOrder.Asc,
  sortBy: null,
  report_date: null,
  creation_date: null,
  fy_end_date_to: null,
  show_also_closed: true,
});
const filtersLoader = useLoader();

async function resetFilters() {
  filters.customer_resp_user = authenticatedUser.uuid;
  filters.service = null;
  filters.fy_end_date_from = null;
  filters.report_date = null;
  filters.creation_date = null;
  filters.fy_end_date_to = null;
  filters.sortOrder = SortOrder.Desc;
  filters.sortBy = 'user_name';
  filters.show_also_closed = true;
  filters.report_type = ProgressReportType.project;
  await onFilter();
}

watch([() => filters.sortBy, () => filters.sortOrder], async () => {
  await onFilter();
});

async function getProgress() {
  try {
    const searchParams = new URLSearchParams();
    if (filters.customer_resp_user) searchParams.append('customerResponsibleUuid', filters.customer_resp_user);
    if (filters.fy_end_date_from) searchParams.append('fromFinancialYearDate', filters.fy_end_date_from);
    if (filters.fy_end_date_to) searchParams.append('toFinancialYearDate', filters.fy_end_date_to);
    if (filters.service) searchParams.append('serviceUuid', filters.service);
    if (filters.report_date) searchParams.append('reportDate', filters.report_date);
    if (filters.creation_date) searchParams.append('creationDate', filters.creation_date);
    if (filters.sortBy) searchParams.append('sortBy', filters.sortBy);
    if (filters.sortOrder) searchParams.append('sortOrder', filters.sortOrder.toString());
    if (filters.report_type) searchParams.append('reportType', filters.report_type.toString());
    searchParams.append('showAlsoClosed', filters.show_also_closed.toString());
    progress.value = await api.statistics.progress({ searchParams });
  } catch (error) {
    console.error(error);
  }
}

async function onFilter() {
  loader.start();
  await getProgress();
  loader.finish();
}

// Users
const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('statuses[]', UserStatusType.Active);
    searchParams.append('statuses[]', UserStatusType.Invited);
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}
function handleDateChange() {
  console.log('from fy:' + filters.fy_end_date_from);
  console.log('from to:' + filters.fy_end_date_to);
  if (filters.fy_end_date_from == undefined && filters.fy_end_date_to != undefined) {
    const toDate = DateTime.fromISO(filters.fy_end_date_to);
    filters.fy_end_date_from = toDate.minus({ months: 1 }).toFormat('yyyy-MM-dd');
  }
  if (filters.fy_end_date_from != undefined && filters.fy_end_date_to == undefined) {
    const fromDate = DateTime.fromISO(filters.fy_end_date_from);
    filters.fy_end_date_to = fromDate.plus({ months: 1 }).toFormat('yyyy-MM-dd');
  }
}
onMounted(async () => {
  filtersLoader.start();
  await Promise.all([
    getUsers(),
    getServices({
      searchParams: {
        without_pagination: 1,
      },
    }),
  ]);
  filtersLoader.finish();
});

const showAlsoClosedDisabled = computed(() => {
  return filters.fy_end_date_from == undefined || filters.fy_end_date_to == undefined;
});

const title = useTitle(computed(() => t('statistics-progress.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="statistics-progress.index.help" />
      </div>
      <Dropdown class="ml-auto" placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`statistics-progress.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse opened class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-5 align-items-end">
          <!-- Customer resp -->
          <div class="form-group col">
            <FormLabel html-for="filter-assigned_user">
              {{ t('statistics-progress.index.filters.customer_resp_user') }}
            </FormLabel>
            <VueSelect
              v-model="filters.customer_resp_user"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter-assigned_user"
              :loading="usersLoading"
              :disabled="filtersLoader.isLoading.value"
              :placeholder="t('common.all')"
            />
          </div>
          <div class="form-group col">
            <FormLabel html-for="filter-assigned_user" required>
              {{ t('statistics-progress.index.filters.service') }}
            </FormLabel>
            <VueSelect
              v-model="filters.service"
              :options="services"
              :reduce="(option: IServicePreviewResource) => option.uuid"
              label="name"
              input-id="filter-service"
              :loading="servicesLoading"
              :disabled="filtersLoader.isLoading.value"
              :placeholder="t('common.search')"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!filters.service" v-bind="attributes as object" v-on="events"
              /></template>
            </VueSelect>
          </div>
          <!-- Creation date -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-creation_date">
              {{ t('statistics-progress.index.filters.creation_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter-creation_date"
                :ui="{ input: 'form-control' }"
                v-model="filters.creation_date"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :clearable="true"
                :disabled="filtersLoader.isLoading.value"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- From -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-date_from">
              {{ t('statistics-progress.index.filters.fy_end_date_from') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter-date_from"
                :ui="{ input: 'form-control' }"
                v-model="filters.fy_end_date_from"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :clearable="true"
                :disabled="filtersLoader.isLoading.value"
                @update:modelValue="handleDateChange"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- To -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-date_to">
              {{ t('statistics-progress.index.filters.fy_end_date_to') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter-date_from"
                :ui="{ input: 'form-control' }"
                v-model="filters.fy_end_date_to"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :clearable="false"
                @update:modelValue="handleDateChange"
                :disabled="filtersLoader.isLoading.value"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- Show also closed projects -->
          <div class="form-group col" v-if="!showAlsoClosedDisabled">
            <FormLabel html-for="filter-show_also_closed">
              {{ t('statistics-progress.index.filters.show_also_closed') }}
            </FormLabel>
            <FormSwitch
              id="filter-show_also_closed"
              v-model="filters.show_also_closed"
              :disabled="filtersLoader.isLoading.value || showAlsoClosedDisabled"
            />
          </div>
          <!-- Report date -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-report_date">
              {{ t('statistics-progress.index.filters.report_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter-report_date"
                :ui="{ input: 'form-control' }"
                v-model="filters.report_date"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :clearable="true"
                :disabled="filtersLoader.isLoading.value"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-report_type">
              {{ t('statistics-progress.index.filters.report_type') }}
            </FormLabel>
            <div class="d-flex">
              <div>
                <input
                  class="form-check"
                  type="radio"
                  name="reportType"
                  id="radio-check-report-type-project"
                  :value="ProgressReportType.project"
                  v-model="filters.report_type"
                  :disabled="filtersLoader.isLoading.value"
                />
                <label class="form-label" for="radio-check-report-type-project">
                  {{ t('statistics-progress.index.filters.typeproject') }}
                </label>
              </div>
              <div class="pl-3">
                <input
                  class="form-check"
                  type="radio"
                  name="reportType"
                  id="radio-check-report-type-user"
                  :value="ProgressReportType.user"
                  v-model="filters.report_type"
                  :disabled="filtersLoader.isLoading.value"
                />
                <label class="form-label" for="radio-check-report-type-user">
                  {{ t('statistics-progress.index.filters.typeuser') }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <AppButton :disabled="filtersLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filtersLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="progress?.rows.length == 0">
        {{ t('statistics-progress.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead v-model:sort-by="filters.sortBy" v-model:sort-order="filters.sortOrder">
            <AppTableTr v-if="progress?.total != undefined">
              <AppTableTh v-if="isColumnEnable('client_responsible')">{{
                t('statistics-progress.attributes.total')
              }}</AppTableTh>
              <AppTableTh v-if="isColumnEnable('client')"> </AppTableTh>
              <AppTableTh v-if="isColumnEnable('project')"> </AppTableTh>
              <AppTableTh class="text-right" v-if="isColumnEnable('planned_time')">
                {{ progress?.total.plannedTime }}
              </AppTableTh>
              <AppTableTh class="text-right" v-if="isColumnEnable('reported_time')">
                {{ progress?.total.completedTime }}
              </AppTableTh>
              <AppTableTh class="text-right" v-if="isColumnEnable('ready_gp_time')">
                {{ progress?.total.completedBasePlanTime }}
              </AppTableTh>
              <AppTableTh class="text-right" v-if="isColumnEnable('diff_budget')">
                {{ progress?.total.diffTime }}
              </AppTableTh>
              <AppTableTh class="text-right" v-if="isColumnEnable('diff_budget_client_resp')">
                {{ progress?.total.diffTimeClientResp }}
              </AppTableTh>
              <AppTableTh class="text-right" v-if="isColumnEnable('readiness')">
                {{ progress?.total.completePercent }}
              </AppTableTh>
            </AppTableTr>
            <AppTableTr>
              <AppTableTh sortable="client_responsible" nowrap v-if="isColumnEnable('client_responsible')">
                {{ t('statistics-progress.attributes.client_responsible') }}
              </AppTableTh>
              <AppTableTh sortable="client" nowrap v-if="isColumnEnable('client')">
                {{
                  progress?.reportType == ProgressReportType.project
                    ? t('statistics-progress.attributes.client')
                    : t('statistics-progress.attributes.number_of_clients')
                }}
              </AppTableTh>
              <AppTableTh sortable="project" nowrap v-if="isColumnEnable('project')">
                {{
                  progress?.reportType == ProgressReportType.project
                    ? t('statistics-progress.attributes.project')
                    : t('statistics-progress.attributes.number_of_projects')
                }}
              </AppTableTh>
              <AppTableTh
                align="end"
                sortable="planned_time"
                nowrap
                class="text-right"
                v-if="isColumnEnable('planned_time')"
              >
                {{ t('statistics-progress.attributes.planned_time') }}
              </AppTableTh>
              <AppTableTh
                align="end"
                sortable="reported_time"
                nowrap
                class="text-right"
                v-if="isColumnEnable('reported_time')"
              >
                {{ t('statistics-progress.attributes.reported_time') }}
              </AppTableTh>
              <AppTableTh
                align="end"
                sortable="compl_base"
                nowrap
                class="text-right"
                v-tooltip.right="t('statistics-progress.tooltip.ready_gp_time')"
                v-if="isColumnEnable('ready_gp_time')"
              >
                {{ t('statistics-progress.attributes.ready_gp_time') }}
              </AppTableTh>
              <AppTableTh
                align="end"
                sortable="diff"
                nowrap
                class="text-right"
                v-if="isColumnEnable('diff_budget')"
                v-tooltip.right="t('statistics-progress.tooltip.diff_budget')"
              >
                {{ t('statistics-progress.attributes.diff_budget') }}
              </AppTableTh>
              <AppTableTh
                align="end"
                sortable="diff_client_resp"
                nowrap
                class="text-right"
                v-if="isColumnEnable('diff_budget_client_resp')"
                v-tooltip.right="t('statistics-progress.tooltip.diff_budget_client_resp')"
              >
                {{ t('statistics-progress.attributes.diff_budget_client_resp') }}
              </AppTableTh>
              <AppTableTh
                align="end"
                sortable="completion"
                nowrap
                class="text-right"
                v-tooltip.right="t('statistics-progress.tooltip.percent_complete')"
                v-if="isColumnEnable('readiness')"
              >
                {{ t('statistics-progress.attributes.readiness') }}
              </AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr
              class="pointer"
              v-for="progressRow in progress?.rows"
              :key="`${progressRow.project?.id}-${progressRow.user?.uuid}`"
            >
              <AppTableTd nowrap v-if="isColumnEnable('client_responsible')">
                {{ progressRow.user?.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('client')">
                {{
                  progress?.reportType == ProgressReportType.project
                    ? progressRow.client?.name
                    : progressRow.customerCount
                }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('project')">
                <span v-if="progress?.reportType !== ProgressReportType.project">
                  {{ progressRow.projectCount }}
                </span>
                <router-link
                  v-else
                  :to="{
                    name: 'client.reported_time',
                    params: { uuid: progressRow.client?.uuid },
                    query: { project: progressRow.project?.id },
                  }"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ progressRow.project?.name }}
                </router-link>
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('planned_time')" class="text-right">
                {{ progressRow.plannedTime }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('reported_time')" class="text-right">
                {{ progressRow.completedTime }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('ready_gp_time')" class="text-right">
                {{ progressRow.completedBasePlanTime }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('diff_budget')" class="text-right">
                {{ progressRow.diffTime }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('diff_budget_client_resp')" class="text-right">
                {{ progressRow.diffTimeClientResp }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('readiness')" class="text-right">
                {{ progressRow.completePercent }}
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
      </template>
    </template>
  </div>
</template>
