<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { useTitle } from '@vueuse/core';
import { useForm } from 'vee-validate';
import VueSelect from 'vue-select';

import api from '@/services/api';
import {
  AppAlert,
  AppButton,
  AppCollapse,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ClientChooseModal,
  EmptyValue,
  FontIcon,
  FormDatePicker,
  FormInput,
  FormLabel,
  HelpInformation,
  ProjectStatus,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useProject from '@/composables/useProject';
import useAuthStore from '@/store/AuthStore';
import useUsers from '@/composables/useUsers';
import useServices from '@/composables/useServices';
import { IProjectPreviewResource, ProjectStatusType } from '@/types/Project';
import { IUserListResource, UserStatusType } from '@/types/User';
import { IServicePreviewResource } from '@/types/Service';
import usePermissions from '@/composables/usePermissions';

const projects = ref<IProjectPreviewResource[]>([]);
const currentPage = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);

const router = useRouter();
const { can } = usePermissions();
const { t, d } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { openReactivateProjectModal, openDeleteProjectModal, openCancelOrRolloverProjectModal } = useProject();
const { authenticatedUser } = useAuthStore();
const { users, usersLoading, getUsers } = useUsers();
const { services, servicesLoading, getServices } = useServices();

type FiltersForm = {
  search: string;
  statuses: ProjectStatusType[];
  user: string;
  services: string[];
  financial_year_end: null | string;
  team_member: string;
};

const { resetForm, defineField, handleSubmit, isSubmitting } = useForm<FiltersForm>({
  initialValues: {
    search: '',
    statuses: [ProjectStatusType.Active],
    user: authenticatedUser.uuid,
    services: [],
    financial_year_end: '',
    team_member: '',
  },
});
const [filterSearch] = defineField('search');
const [filterStatuses] = defineField('statuses');
const [filterUser] = defineField('user');
const [filterServices] = defineField('services');
const [filterFiscalYearEnd] = defineField('financial_year_end');
const [filterTeamMember] = defineField('team_member');

const createProjectModal = useModal({
  component: ClientChooseModal,
  attrs: {
    onConfirm(clientUuid: string) {
      router.push({ name: 'projects.create', params: { uuid: clientUuid } });
      createProjectModal.close();
    },
    onCancel() {
      createProjectModal.close();
    },
  },
});

async function getProjects() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filterSearch.value) searchParams.append('search', filterSearch.value);
    filterStatuses.value.forEach((status) => searchParams.append('statuses[]', status));
    if (filterUser.value) searchParams.append('user', filterUser.value);
    filterServices.value.forEach((uuid) => searchParams.append('services[]', uuid));
    if (filterFiscalYearEnd.value) searchParams.append('fiscal_year_end', filterFiscalYearEnd.value);
    if (filterTeamMember.value) searchParams.append('team_member', filterTeamMember.value);
    const response = await api.projects.list({ searchParams });
    projects.value = response.data;
    if (response.meta) {
      perPage.value = response.meta.per_page;
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  }
}

async function deleteProject(project: IProjectPreviewResource) {
  openDeleteProjectModal({ id: project.id, name: project.name }, project.client.uuid, {
    async onDone() {
      await getProjects();
      if (projects.value.length === 0 && currentPage.value > 1) {
        currentPage.value -= 1;
      }
    },
  });
}

function onCancelOrRollover(type: 'cancel' | 'rollover', project: IProjectPreviewResource) {
  openCancelOrRolloverProjectModal(
    type,
    {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
      service: project.service,
    },
    project.client.uuid,
    {
      onDone() {
        getProjects();
      },
    },
  );
}

function onReactivate(project: IProjectPreviewResource) {
  openReactivateProjectModal({ id: project.id, name: project.name }, project.client.uuid, {
    onDone() {
      getProjects();
    },
  });
}

const onFilter = handleSubmit(async () => {
  loader.start();
  currentPage.value = 1;
  await getProjects();
  loader.finish();
});

async function resetFilters() {
  resetForm();
  await onFilter();
}

onMounted(async () => {
  // users.value = [authenticatedUser as IUserListResource];
  loader.start();
  const searchParams = new URLSearchParams();
  searchParams.append('without_pagination', '1');
  searchParams.append('statuses[]', UserStatusType.Active);
  searchParams.append('statuses[]', UserStatusType.Invited);
  searchParams.append('statuses[]', UserStatusType.Inactive);
  await Promise.all([
    getUsers({ searchParams }),
    getServices({ searchParams: { without_pagination: 1 } }),
    getProjects(),
  ]);
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getProjects();
  loader.finish();
});

const title = useTitle(computed(() => t('project.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="project.index.help" />
      </div>
      <AppButton @click.prevent="createProjectModal.open" color="secondary">
        {{ t('client.projects.index.create') }}
      </AppButton>
    </div>
    <AppCollapse opened class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-sm-2 row-cols-md-3">
          <!-- Name -->
          <div class="col form-group">
            <FormLabel html-for="filter_name">
              {{ t('project.index.filters.name_or_client') }}
            </FormLabel>
            <FormInput type="search" v-model="filterSearch" id="filter_name" icon="search" :disabled="isSubmitting" />
          </div>
          <!-- Statuses -->
          <div class="col form-group">
            <FormLabel html-for="filter_statuses">
              {{ t('project.index.filters.statuses') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              :filterable="false"
              v-model="filterStatuses"
              :options="Object.values(ProjectStatusType)"
              :get-option-label="(option: string) => t(`project.statuses.${option}`)"
              input-id="filter_statuses"
              :placeholder="t('common.select')"
              :disabled="isSubmitting"
              multiple
            />
          </div>
          <!-- User -->
          <div class="col form-group">
            <FormLabel html-for="filter_user">
              {{ t('project.index.filters.user') }}
            </FormLabel>
            <VueSelect
              v-model="filterUser"
              :reduce="(option: IUserListResource) => option.uuid"
              :options="users"
              label="name"
              input-id="filter_user"
              :placeholder="t('common.select')"
              :disabled="usersLoading"
              :loading="usersLoading"
              :clear-search-on-blur="() => true"
            />
          </div>
          <!-- Services -->
          <div class="col form-group">
            <FormLabel html-for="filter_services">
              {{ t('project.index.filters.services') }}
            </FormLabel>
            <VueSelect
              v-model="filterServices"
              :reduce="(option: IServicePreviewResource) => option.uuid"
              :options="services"
              label="name"
              input-id="filter_services"
              :placeholder="t('common.select')"
              multiple
              :disabled="servicesLoading"
              :loading="servicesLoading"
              :clear-search-on-blur="() => true"
            />
          </div>
          <!-- Fiscal year end -->
          <div class="col form-group">
            <FormLabel html-for="filter_fiscal_year_end">
              {{ t('project.index.filters.fiscal_year_end') }}
            </FormLabel>
            <FormDatePicker id="filter_fiscal_year_end" v-model="filterFiscalYearEnd" />
          </div>
          <!-- Team members -->
          <div class="col form-group">
            <FormLabel html-for="filter_team_member">
              {{ t('project.index.filters.team_member') }}
            </FormLabel>
            <VueSelect
              v-model="filterTeamMember"
              :reduce="(option: IUserListResource) => option.uuid"
              :options="users"
              label="name"
              input-id="filter_team_member"
              :placeholder="t('common.select')"
              :disabled="usersLoading"
              :loading="usersLoading"
              :clear-search-on-blur="() => true"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="isSubmitting" :loading="isSubmitting">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="isSubmitting">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="projects.length === 0">
        {{ t('project.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('project.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('project.attributes.client') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('project.attributes.status') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('project.attributes.start_end_date') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('project.attributes.deadline_date') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('project.attributes.user') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr
              @click="
                can('projects.edit', project.status)
                  ? router.push({
                      name: 'projects.edit',
                      params: { uuid: project.client.uuid, id: project.id },
                    })
                  : void 0
              "
              :class="{ pointer: can('projects.edit', project.status) }"
              v-for="project in projects"
              :key="project.id"
            >
              <AppTableTd nowrap>
                <strong v-text="project.name" />
              </AppTableTd>
              <AppTableTd nowrap>
                {{ project.client?.name }}
              </AppTableTd>
              <AppTableTd nowrap>
                <ProjectStatus :status="project.status" />
              </AppTableTd>
              <AppTableTd nowrap> {{ d(project.start_date) }} - {{ d(project.end_date) }} </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="project.deadline_date" v-text="d(project.deadline_date)" />
                <EmptyValue :text="t('common.none')" v-else />
              </AppTableTd>
              <AppTableTd nowrap>{{ project.user.name }}</AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  v-if="can('projects.view', project.status)"
                  v-tooltip.left="t('project.tooltip.view', { name: project.name })"
                  @click.stop="
                    router.push({
                      name: 'projects.view',
                      params: { uuid: project.client.uuid, id: project.id },
                    })
                  "
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="eye" />
                </AppButton>
                <AppButton
                  v-if="can('projects.edit', project.status)"
                  v-tooltip.left="t('project.tooltip.edit', { name: project.name })"
                  @click.stop="
                    router.push({
                      name: 'projects.edit',
                      params: { uuid: project.client.uuid, id: project.id },
                    })
                  "
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-if="can('projects.delete', project.status)"
                  v-tooltip.left="t('project.tooltip.destroy', { name: project.name })"
                  @click.stop="deleteProject(project)"
                  class="ml-2"
                  size="small"
                  color="danger"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
                <AppButton
                  v-if="can('projects.cancel', project.status)"
                  v-tooltip.left="t('project.tooltip.cancel', { name: project.name })"
                  @click.stop="onCancelOrRollover('cancel', project)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="ban" />
                </AppButton>
                <AppButton
                  v-if="can('projects.cancel', project.status)"
                  v-tooltip.left="t('project.tooltip.rollover', { name: project.name })"
                  @click.stop="onCancelOrRollover('rollover', project)"
                  class="ml-2"
                  color="secondary"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="reload" />
                </AppButton>
                <AppButton
                  v-if="can('projects.reactivate', project.status)"
                  v-tooltip.left="t('project.tooltip.reactivate', { name: project.name })"
                  @click.stop="onReactivate(project)"
                  class="ml-2"
                  color="success"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="arrow-back-up" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
      </template>
    </template>
  </div>
</template>
