import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import route from 'ziggy-js';
import { IStatisticsProgress } from '@/types/Progress';

export default {
  progress(options: Options = {}) {
    return httpClient.get(route('statistics.progress'), options).json<IStatisticsProgress>();
  },
};
