import api from '@/services/api';
import { ref } from 'vue';
import { Options } from 'ky';
import { IServiceCompanyContactPreviewResource } from '@/types/ServiceCompany';

type GetServiceCompaniesHooks = {
  onSet?: (
    rawServiceCompanyContacts: IServiceCompanyContactPreviewResource[],
  ) => IServiceCompanyContactPreviewResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useServiceCompanyContacts() {
  const serviceCompanyContacts = ref<IServiceCompanyContactPreviewResource[]>([]);
  const serviceCompanyContactsLoading = ref(false);

  async function getServiceCompanyContacts(
    serviceCompanyUuid: string,
    options?: Options,
    hooks?: GetServiceCompaniesHooks,
  ) {
    try {
      serviceCompanyContactsLoading.value = true;
      const response = await api.serviceCompanies.contacts.list(serviceCompanyUuid, options);
      serviceCompanyContacts.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error: unknown) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      serviceCompanyContactsLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }

  return {
    serviceCompanyContacts,
    serviceCompanyContactsLoading,
    getServiceCompanyContacts,
  };
}
