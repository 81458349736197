<script lang="ts" setup>
import { useAttrs, provide } from 'vue';

type Props = {
  loading?: boolean;
  columnsSize?: number;
};

const { loading, columnsSize = 24 } = defineProps<Props>();

const attrs = useAttrs();

provide('columnsSize', columnsSize);
</script>

<template>
  <tr :class="{ loading }" v-bind="attrs">
    <slot />
  </tr>
</template>

<style lang="scss" scoped>
tr.loading {
  opacity: 0.5;
  cursor: wait;
  pointer-events: none;
  touch-action: none;
  user-select: none;
}
</style>
