<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import {
  AppAlert,
  AppButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FontIcon,
  InvoiceExternalStatus,
  InvoiceInternalStatus,
  InvoiceSyncStatus,
} from '@/components';
import { IInvoicePreviewResource } from '@/types/Invoice';
import { computed } from 'vue';
import { useModal } from 'vue-final-modal';
import api from '@/services/api';
import toast from '@/services/toast';
import { Decimal } from 'decimal.js';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import usePermissions from '@/composables/usePermissions';

type TableColumn =
  | 'id'
  | 'client'
  | 'user'
  | 'amount'
  | 'created_at'
  | 'internal_status'
  | 'external_status'
  | 'synced_at'
  | 'sync_status'
  | 'external_amount'
  | 'difference'
  | 'actions';

type Props = {
  invoices: IInvoicePreviewResource[];
  total: number;
  totalExternal: number;
  columns?: TableColumn[];
};

const {
  total,
  totalExternal,
  columns = [
    'id',
    'client',
    'user',
    'amount',
    'created_at',
    'internal_status',
    'external_status',
    'synced_at',
    'sync_status',
    'external_amount',
    'difference',
    'actions',
  ],
} = defineProps<Props>();

const emit = defineEmits<{
  onDeleted: [id: number];
}>();

const { t, d, n } = useI18n({ useScope: 'global' });
const router = useRouter();
const { can } = usePermissions();

const isColumnEnable = computed(() => (columnName: TableColumn) => columns.includes(columnName));

function onDelete(invoice: IInvoicePreviewResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('invoice.confirm.delete.title'),
      message: t('invoice.confirm.delete.text'),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.invoices.destroy(invoice.id);
          toast.success(t('common.messages.has_been_deleted', { name: t('invoice.resource.name') }));
          emit('onDeleted', invoice.id);
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}
</script>

<template>
  <AppAlert v-if="invoices.length === 0">
    {{ t('invoice.empty') }}
  </AppAlert>
  <AppTable v-else hoverable>
    <AppTableHead>
      <AppTableTr>
        <AppTableTh v-if="isColumnEnable('id')"></AppTableTh>
        <AppTableTh nowrap>
          <h4 class="mb-0">{{ t('common.total') }}</h4>
        </AppTableTh>
        <AppTableTh nowrap colspan="2" class="text-right">
          <h4 class="mb-0">{{ n(total, 'currency') }}</h4>
        </AppTableTh>
        <template v-if="isColumnEnable('external_amount')">
          <AppTableTh nowrap colspan="4" />
          <AppTableTh nowrap v-if="isColumnEnable('external_amount')" colspan="2" class="text-right">
            <h4 class="mb-0">{{ n(totalExternal, 'currency') }}</h4>
          </AppTableTh>
          <AppTableTh nowrap colspan="2" />
        </template>
        <AppTableTh v-else nowrap colspan="3" />
      </AppTableTr>
      <AppTableTr>
        <AppTableTh nowrap v-if="isColumnEnable('id')">ID</AppTableTh>
        <AppTableTh nowrap v-if="isColumnEnable('client')">{{ t('invoice.attributes.client') }}</AppTableTh>
        <AppTableTh nowrap v-if="isColumnEnable('user')">{{ t('invoice.attributes.user') }}</AppTableTh>
        <AppTableTh class="text-right" nowrap v-if="isColumnEnable('amount')">
          {{ t('invoice.attributes.amount') }}
        </AppTableTh>
        <AppTableTh nowrap v-if="isColumnEnable('created_at')">{{ t('invoice.attributes.created_at') }}</AppTableTh>
        <AppTableTh nowrap v-if="isColumnEnable('synced_at')">{{ t('invoice.attributes.synced_at') }}</AppTableTh>
        <AppTableTh nowrap v-if="isColumnEnable('sync_status')">{{ t('invoice.attributes.sync_status') }}</AppTableTh>
        <AppTableTh nowrap v-if="isColumnEnable('internal_status')">
          {{ t('invoice.attributes.internal_status') }}
        </AppTableTh>
        <AppTableTh nowrap v-if="isColumnEnable('external_status')">
          {{ t('invoice.attributes.external_status') }}
        </AppTableTh>
        <AppTableTh class="text-right" nowrap v-if="isColumnEnable('external_amount')">
          {{ t('invoice.attributes.external_amount') }}
        </AppTableTh>
        <AppTableTh class="text-right" nowrap v-if="isColumnEnable('difference')">
          {{ t('invoice.attributes.difference') }}
        </AppTableTh>
        <AppTableTh class="text-right" nowrap v-if="isColumnEnable('actions')">
          {{ t('common.actions') }}
        </AppTableTh>
      </AppTableTr>
    </AppTableHead>
    <AppTableBody>
      <AppTableTr
        v-for="invoice in invoices"
        :key="invoice.id"
        @click="router.push({ name: 'invoices.edit', params: { id: invoice.id } })"
        class="pointer"
      >
        <AppTableTd class="font-bold" nowrap v-if="isColumnEnable('id')">{{ invoice.id }}</AppTableTd>
        <AppTableTd nowrap v-if="isColumnEnable('client')">{{ invoice.client.name }}</AppTableTd>
        <AppTableTd nowrap v-if="isColumnEnable('user')">
          <span v-if="invoice.user" v-text="invoice.user.name" />
          <i v-else class="text-neutral-300" v-text="t('common.empty')" />
        </AppTableTd>
        <AppTableTd class="text-right" nowrap v-if="isColumnEnable('amount')">
          {{ n(invoice.price ?? 0, 'currency') }}
        </AppTableTd>
        <AppTableTd nowrap v-if="isColumnEnable('created_at')">{{ d(invoice.created_at) }}</AppTableTd>
        <AppTableTd nowrap v-if="isColumnEnable('synced_at')">
          <span v-if="invoice.sync_at" v-text="d(invoice.sync_at)" />
          <i v-else class="text-neutral-300" v-text="t('common.empty')" />
        </AppTableTd>
        <AppTableTd nowrap v-if="isColumnEnable('sync_status')">
          <InvoiceSyncStatus :status="invoice.sync_status" />
        </AppTableTd>
        <AppTableTd nowrap v-if="isColumnEnable('internal_status')">
          <InvoiceInternalStatus :status="invoice.internal_status" />
        </AppTableTd>
        <AppTableTd nowrap v-if="isColumnEnable('external_status')">
          <InvoiceExternalStatus :status="invoice.external_status" />
        </AppTableTd>
        <AppTableTd class="text-right" nowrap v-if="isColumnEnable('external_amount')">
          {{ n(invoice.external_amount ?? 0, 'currency') }}
        </AppTableTd>
        <AppTableTd class="text-right" nowrap v-if="isColumnEnable('difference')">
          <span
            v-if="invoice.external_amount != null && invoice.price - invoice.external_amount !== 0"
            class="text-danger-500"
          >
            {{
              n(
                new Decimal(invoice.price ?? 0)
                  .minus(invoice.external_amount ?? 0)
                  .toDecimalPlaces(2)
                  .toNumber(),
                'currency',
              )
            }}
          </span>
        </AppTableTd>
        <AppTableTd class="text-right" nowrap v-if="isColumnEnable('actions')">
          <AppButton
            v-tooltip.left="t('invoice.tooltip.edit')"
            @click.stop.prevent="router.push({ name: 'invoices.edit', params: { id: invoice.id } })"
            size="small"
            light
            circle
          >
            <FontIcon name="pencil" />
          </AppButton>
          <AppButton
            v-if="can('invoices.delete', invoice.internal_status)"
            v-tooltip.left="t('invoice.tooltip.delete')"
            @click.stop.prevent="onDelete(invoice)"
            size="small"
            light
            circle
            class="ml-2"
            color="danger"
          >
            <FontIcon name="trash" />
          </AppButton>
        </AppTableTd>
      </AppTableTr>
    </AppTableBody>
  </AppTable>
</template>
