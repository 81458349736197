export default {
  index: {
    title: 'Resource planning',
    my_planning_title: 'My planning',
    resource_overview_title: 'Resource overview',
    help: 'This page displays the planned time for employees. If they have reported and marked tasks as completed, the actual repoted time will be shown, instead of the planned time.',
  },
  filters: {
    from: 'From week',
    to: 'To week',
    offices: 'Offices',
    departments: 'Departments',
    work_titles: 'Titles',
    employees: 'Employees',
  },
  attributes: {
    total: 'Total',
    name: 'Name',
    office: 'Office',
    department: 'Division',
    work_rate: 'Workrate',
    planned: 'Planned H',
    available: 'Available H',
    net: 'Net H',
  },
};
