import { Decimal } from 'decimal.js';
import { InvoiceCustomItemForm, InvoiceItemForm } from '@/types/Invoice';
import { calculateAmount, calculatePricePerUnit } from '@/services/invoices';
import { useInvoiceItemsState } from '@/store/InvoiceItemsState';

export default function useInvoiceItem() {
  const { expandInvoiceItem, collapseInvoiceItem } = useInvoiceItemsState();

  function parseValue(value: string, defaultValue: number) {
    const rawValue = new Decimal(value ? value : 0);
    let results = 0;
    if (rawValue.isNaN() || rawValue.lessThanOrEqualTo(0)) {
      results = defaultValue;
    } else {
      results = rawValue.toDecimalPlaces(2).toNumber();
    }
    return results;
  }

  function onCustomInvoicingQtyChange(invoiceItem: InvoiceCustomItemForm, event: Event) {
    const input = event.target as HTMLInputElement;
    invoiceItem.quantity = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();
    invoiceItem.price = Decimal.mul(invoiceItem.price_per_unit ?? 0, invoiceItem.quantity)
      .toDecimalPlaces(2)
      .toNumber();
    input.value = invoiceItem.quantity.toString();
  }

  function onCustomPricePerUnitChange(invoiceItem: InvoiceCustomItemForm, event: Event) {
    const input = event.target as HTMLInputElement;
    invoiceItem.price_per_unit = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();
    if (!invoiceItem.quantity && invoiceItem.price_per_unit > 0) {
      invoiceItem.quantity = 1;
    }
    invoiceItem.price = Decimal.mul(invoiceItem.quantity, invoiceItem.price_per_unit).toDecimalPlaces(2).toNumber();
    input.value = invoiceItem.price_per_unit.toString();
  }

  function onCustomInvoicingSumChange(invoiceItem: InvoiceCustomItemForm, event: Event) {
    const input = event.target as HTMLInputElement;
    invoiceItem.price = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();
    if (!invoiceItem.quantity && invoiceItem.price > 0) {
      invoiceItem.quantity = 1;
    }
    if (invoiceItem.price === 0) {
      invoiceItem.quantity = 0;
      invoiceItem.price_per_unit = 0;
    } else {
      invoiceItem.price_per_unit = Decimal.div(invoiceItem.price, invoiceItem.quantity).toDecimalPlaces(2).toNumber();
    }
    input.value = invoiceItem.price.toString();
  }

  function onDiscountChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;
    const discount = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();

    invoiceItem.discount = Math.min(discount, 100);
    const totalPrice = calculateAmount(invoiceItem.quantity, invoiceItem.price_per_unit, invoiceItem.discount);
    setItemTotalPrice(totalPrice, invoiceItem);
    input.value = invoiceItem.discount.toString();
  }

  function getInvoicingSum(invoiceItem: InvoiceItemForm | InvoiceCustomItemForm) {
    return calculateAmount(invoiceItem.quantity, invoiceItem.price_per_unit, invoiceItem.discount);
  }

  function onInvoicingQtyChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;

    invoiceItem.quantity = parseValue(input.value, 1);
    const newPrice = new Decimal(invoiceItem.quantity).mul(invoiceItem.price_per_unit).toDecimalPlaces(2).toNumber();
    input.value = invoiceItem.quantity.toString();
    setItemTotalPrice(newPrice, invoiceItem);
  }

  function onInvoicingPricePerUnitChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;

    invoiceItem.price_per_unit = parseValue(input.value, 1);
    const newPrice = new Decimal(invoiceItem.quantity).mul(invoiceItem.price_per_unit).toDecimalPlaces(2).toNumber();
    input.value = invoiceItem.price_per_unit.toString();
    setItemTotalPrice(newPrice, invoiceItem);
  }

  function calculateAndSetPricePerUnit(invoiceItem: InvoiceItemForm): void {
    // Recalculate price per unit based on the new total price.
    // Note: Using the same property (pricePerUnit) rather than creating a new one.

    invoiceItem.price_per_unit = calculatePricePerUnit(invoiceItem.quantity, invoiceItem.price, invoiceItem.discount);
  }

  function setItemTotalPrice(totalPrice: number, invoiceItem: InvoiceItemForm) {
    invoiceItem.price = totalPrice;
    calculateAndSetPricePerUnit(invoiceItem);
    if (!invoiceItem.custom) {
      if (totalPrice >= invoiceItem.original_price) {
        fillPostpone(invoiceItem, 0, 0);
        fillWriteOff(invoiceItem, 0);
        collapseInvoiceItem(invoiceItem.uid);
      } else {
        proposePostponed(invoiceItem);
        expandInvoiceItem(invoiceItem.uid);
      }
    }
  }

  function proposePostponed(invoiceItem: InvoiceItemForm): void {
    const sum = Math.max(invoiceItem.original_price - invoiceItem.price - invoiceItem.write_off.sum, 0);
    invoiceItem.postpone.sum = new Decimal(sum).toDecimalPlaces(2).toNumber();
    invoiceItem.postpone.quantity =
      invoiceItem.quantity < 1 || (invoiceItem.quantity > 1 && invoiceItem.original_quantity > 1)
        ? invoiceItem.original_quantity - invoiceItem.quantity
        : 1;

    invoiceItem.postpone.discount = invoiceItem.discount;
    const discountFactor: number = 1.0 - invoiceItem.discount / 100.0;
    if (invoiceItem.postpone.quantity !== 0) {
      invoiceItem.postpone.price_per_unit = new Decimal(
        invoiceItem.postpone.sum / invoiceItem.postpone.quantity / discountFactor,
      )
        .toDecimalPlaces(2)
        .toNumber();
    } else {
      invoiceItem.postpone.price_per_unit = 0;
    }
    expandInvoiceItem(invoiceItem.uid);
  }
  function onInvoicingSumChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;
    const value = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();

    setItemTotalPrice(value, invoiceItem);

    input.value = invoiceItem.price.toString();
  }

  function fillInvoicing(invoiceItem: InvoiceItemForm, quantity: number, sum: number, pricePerUnit?: number) {
    if (sum === 0) {
      invoiceItem.quantity = 0;
      invoiceItem.price_per_unit = 0;
      invoiceItem.price = 0;
      return;
    }
    const pricePerUnitToSet = pricePerUnit
      ? pricePerUnit
      : new Decimal(sum).div(quantity).toDecimalPlaces(2).toNumber();

    invoiceItem.quantity = quantity;
    invoiceItem.price_per_unit = pricePerUnitToSet;
    invoiceItem.price = sum;
  }

  function fillPostpone(invoiceItem: InvoiceItemForm, quantity: number, sum: number, pricePerUnit?: number) {
    if (sum === 0) {
      invoiceItem.postpone.quantity = 0;
      invoiceItem.postpone.price_per_unit = 0;
      invoiceItem.postpone.sum = 0;
      return;
    }
    const pricePerUnitToSet = pricePerUnit
      ? pricePerUnit
      : new Decimal(sum).div(quantity).toDecimalPlaces(2).toNumber();

    invoiceItem.postpone.quantity = quantity;
    invoiceItem.postpone.price_per_unit = pricePerUnitToSet;
    invoiceItem.postpone.sum = sum;
  }

  function fillWriteOff(invoiceItem: InvoiceItemForm, sum: number) {
    if (sum === 0) {
      invoiceItem.write_off.quantity = 0;
      invoiceItem.write_off.price_per_unit = 0;
      invoiceItem.write_off.sum = 0;
    } else {
      invoiceItem.write_off.quantity = 1;
      invoiceItem.write_off.price_per_unit = sum;
      invoiceItem.write_off.sum = sum;
    }
  }

  function onWriteOff(invoiceItem: InvoiceItemForm) {
    fillWriteOff(invoiceItem, new Decimal(invoiceItem.write_off.sum).add(invoiceItem.price).toNumber());
    fillInvoicing(invoiceItem, 0, 0, 0);
    expandInvoiceItem(invoiceItem.uid);
  }

  function discardWriteOff(invoiceItem: InvoiceItemForm) {
    fillInvoicing(
      invoiceItem,
      invoiceItem.quantity ? invoiceItem.quantity : 1,
      new Decimal(invoiceItem.price).add(invoiceItem.write_off.sum).toNumber(),
    );

    fillWriteOff(invoiceItem, 0);
  }

  function onPostpone(invoiceItem: InvoiceItemForm) {
    fillPostpone(invoiceItem, 1, new Decimal(invoiceItem.postpone.sum).add(invoiceItem.price).toNumber());
    fillInvoicing(invoiceItem, 0, 0, 0);
    expandInvoiceItem(invoiceItem.uid);
  }

  function discardPostpone(invoiceItem: InvoiceItemForm) {
    fillInvoicing(
      invoiceItem,
      invoiceItem.quantity ? invoiceItem.quantity : 1,
      new Decimal(invoiceItem.price).add(invoiceItem.postpone.sum).toNumber(),
    );

    fillPostpone(invoiceItem, 0, 0, 0);
  }

  function onPostponeQtyChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;

    const quantityRaw = new Decimal(input.value ? input.value : 0);
    if (quantityRaw.isNaN() || quantityRaw.lessThanOrEqualTo(0)) {
      invoiceItem.postpone.quantity = 1;
    } else {
      invoiceItem.postpone.quantity = quantityRaw.toDecimalPlaces(2).toNumber();
    }

    input.value = invoiceItem.postpone.quantity.toString();
    setPostponedItemTotal(invoiceItem, invoiceItem.postpone.quantity * invoiceItem.postpone.price_per_unit);
  }

  function proposeWriteOff(invoiceItem: InvoiceItemForm) {
    if (invoiceItem.original_price > invoiceItem.price) {
      invoiceItem.write_off.sum = invoiceItem.original_price - invoiceItem.price - invoiceItem.postpone.sum;
      invoiceItem.write_off.quantity = 1;
      invoiceItem.write_off.price_per_unit = invoiceItem.write_off.sum;
    } else {
      invoiceItem.write_off.sum = 0;
      invoiceItem.write_off.price_per_unit = 0;
      invoiceItem.write_off.quantity = 1;
    }
  }

  function isInvoiceItemValid(invoiceItem: InvoiceItemForm | InvoiceCustomItemForm) {
    if (invoiceItem.custom) return true;

    const currentSum = invoiceItem.price + invoiceItem.postpone.sum + invoiceItem.write_off.sum;

    if (currentSum < invoiceItem.original_price) {
      return false;
    }

    if (invoiceItem.price > invoiceItem.original_price) {
      if (invoiceItem.postpone.sum > 0 || invoiceItem.write_off.sum > 0) {
        return false;
      }
    }

    return true;
  }

  function onPostponePricePerUnitChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;

    const pricePerUnitRaw = new Decimal(input.value ? input.value : 0);
    if (pricePerUnitRaw.isNaN() || pricePerUnitRaw.lessThanOrEqualTo(0)) {
      invoiceItem.postpone.price_per_unit = 1;
    } else {
      invoiceItem.postpone.price_per_unit = pricePerUnitRaw.toDecimalPlaces(2).toNumber();
    }

    setPostponedItemTotal(invoiceItem, invoiceItem.postpone.price_per_unit * invoiceItem.postpone.quantity);

    input.value = invoiceItem.postpone.price_per_unit.toString();
  }

  function calculateTotal(quantity: number, pricePerUnit: number, discount: number): number {
    const discountFactor: number = 1.0 - discount / 100.0;
    return quantity * pricePerUnit * discountFactor;
  }

  function onPostponeDiscountChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;

    const pricePerUnitRaw = new Decimal(input.value ? input.value : 0);
    if (pricePerUnitRaw.isNaN() || pricePerUnitRaw.lessThanOrEqualTo(0)) {
      invoiceItem.postpone.price_per_unit = 1;
    } else {
      invoiceItem.postpone.price_per_unit = pricePerUnitRaw.toDecimalPlaces(2).toNumber();
    }

    setPostponedItemTotal(
      invoiceItem,
      calculateTotal(invoiceItem.postpone.quantity, invoiceItem.postpone.price_per_unit, invoiceItem.postpone.discount),
    );

    input.value = invoiceItem.postpone.discount.toString();
  }

  function setPostponedItemTotal(invoiceItem: InvoiceItemForm, totalPrice: number): void {
    invoiceItem.postpone.sum = totalPrice;
    const discountFactor: number = 1.0 - invoiceItem.postpone.discount / 100.0;
    if (invoiceItem.postpone.quantity !== 0) {
      invoiceItem.postpone.price_per_unit = invoiceItem.postpone.sum / invoiceItem.postpone.quantity / discountFactor;
    } else {
      invoiceItem.postpone.price_per_unit = 0;
    }
    if (invoiceItem.postpone.sum + invoiceItem.price <= invoiceItem.original_price) {
      proposeWriteOff(invoiceItem);
    }
  }

  function onPostponeSumChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;
    const value = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();

    setPostponedItemTotal(invoiceItem, value);

    input.value = value.toString();
  }

  function onWriteOffSumChange(invoiceItem: InvoiceItemForm, event: Event) {
    const input = event.target as HTMLInputElement;
    const value = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();

    invoiceItem.write_off.sum = value;
    invoiceItem.write_off.quantity = 1;
    invoiceItem.write_off.price_per_unit = invoiceItem.write_off.sum;

    input.value = value.toString();
  }

  return {
    onCustomInvoicingQtyChange,
    onCustomPricePerUnitChange,
    onCustomInvoicingSumChange,

    onDiscountChange,

    getInvoicingSum,
    fillInvoicing,
    onInvoicingQtyChange,
    onInvoicingPricePerUnitChange,
    onInvoicingSumChange,

    fillPostpone,
    onPostpone,
    discardPostpone,
    onPostponeQtyChange,
    onPostponePricePerUnitChange,
    onPostponeSumChange,

    fillWriteOff,
    onWriteOff,
    discardWriteOff,
    onWriteOffSumChange,

    isInvoiceItemValid,
  };
}
