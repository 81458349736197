// @ts-ignore
import.meta.glob(['../images/**']);
import { createApp } from 'vue';
import { createVfm } from 'vue-final-modal';
import VueDragscroll from 'vue-dragscroll';
import { vTooltip, vClosePopper } from 'floating-vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import * as ConfirmDialog from 'vuejs-confirm-dialog';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import 'vue-final-modal/style.css';
import 'floating-vue/dist/style.css';

import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import '@wesoft/we-components/dist/style.css';

import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n';

const vfm = createVfm();

createApp(App)
  .use(vfm)
  .use(ConfirmDialog)
  .use(autoAnimatePlugin)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueDragscroll)
  .use(PerfectScrollbar)
  .directive('tooltip', vTooltip)
  .directive('close-popper', vClosePopper)
  .mount('#app');
