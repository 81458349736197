<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import VueSelect from 'vue-select';
import api from '@/services/api';
import {
  AppAlert,
  AppButton,
  AppCollapse,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ChangeFutureResponsibleModal,
  EmptyValue,
  FontIcon,
  FormLabel,
  FormWeekPicker,
  HelpInformation,
  ResourceManagementTask,
} from '@/components';
import { useTitle } from '@vueuse/core';
import useWorkTitles from '@/composables/useWorkTitles';
import useOffices from '@/composables/useOffices';
import useDepartments from '@/composables/useDepartments';
import useUsers from '@/composables/useUsers';
import useLoader from '@/composables/useLoader';
import useExpandedList from '@/composables/useExpandedList';
import { IUserListResource, UserStatusType } from '@/types/User';
import { IOfficeListResource } from '@/types/Office';
import { IDepartmentResource } from '@/types/Department';
import { IWorkTitleResource } from '@/types/WorkTitle';
import {
  IMoveEventsRequestBody,
  IResourcePlanning,
  IResourcePlanningEmployee,
  IResourcePlanningEmployeeEvent,
  IResourcePlanningEmployeeTask,
  IResourcePlanningTotal,
} from '@/types/Event';
import useTime from '@/composables/useTime';
import { Decimal } from 'decimal.js';
import toast from '@/services/toast';
import { useModal } from 'vue-final-modal';
import useTimeReportModal from '@/composables/useTimeReportModal';
import useAuthStore from '@/store/AuthStore';
import { ResourcePlanningViewType } from '@/types/ResourcePlanning';

const { t } = useI18n({ useScope: 'global' });
const { authenticatedUser } = useAuthStore();
type Filters = {
  from: number;
  to: number;
  offices: number[];
  work_titles: number[];
  departments: number[];
  employees: string[];
};

const dropLoader = useLoader();
const filtersLoader = useLoader();
const { convertMinutesToTime } = useTime();
const loader = useLoader({ useProgress: false });

const { isExpanded: isExpandedEmployee, toggle: toggleEmployee, reset: resetExpandedEmployees } = useExpandedList();
const { isExpanded: isExpandedProject, toggle: toggleProject, reset: resetExpandedProjects } = useExpandedList();
const selectedRow = ref('');
const filters = reactive<Filters>({
  from: Number(DateTime.now().toFormat('kkkkWW')),
  to: Number(DateTime.now().plus({ week: 3 }).toFormat('kkkkWW')),
  offices: [],
  work_titles: [],
  departments: [],
  employees: [],
});

const props = defineProps<{ planningType: ResourcePlanningViewType }>();

const { workTitles, workTitlesLoading, getWorkTitles } = useWorkTitles();

const { offices, officesLoading, getOffices } = useOffices();
const { departments, departmentsLoading, getDepartments } = useDepartments();
const { users, usersLoading, getUsers } = useUsers();
const { openProjectTaskEditModal, openActivityTaskEditModal } = useTimeReportModal();

async function resetFilters() {
  filters.from = Number(DateTime.now().toFormat('kkkkWW'));
  filters.to = Number(DateTime.now().plus({ week: 3 }).toFormat('kkkkWW'));
  filters.offices = [];
  filters.work_titles = [];
  filters.departments = [];
  filters.employees = [];
  await onFilter();
}

async function onFilter() {
  filtersLoader.start();
  await getResourcePlanning();
  filtersLoader.finish();
}

const total = ref<IResourcePlanningTotal>();

const employees = ref<IResourcePlanningEmployee[]>([]);

async function getResourcePlanning() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('week_from', filters.from.toString());
    searchParams.append('week_to', filters.to.toString());
    const currentUrl = new URL(window.location.href);
    console.log('currenturl=' + currentUrl);
    if (props.planningType == ResourcePlanningViewType.MyPlan) {
      searchParams.append('employee[]', authenticatedUser.uuid.toString());
    } else {
      filters.offices.forEach((id) => searchParams.append('offices[]', id.toString()));
      filters.departments.forEach((id) => searchParams.append('departments[]', id.toString()));
      filters.work_titles.forEach((id) => searchParams.append('work_titles[]', id.toString()));
      filters.employees.forEach((id) => searchParams.append('employee[]', id.toString()));
    }
    const response = await api.events.resourcePlanning.index({ searchParams });
    total.value = response.total;
    employees.value = response.employees;
    selectedRow.value = '';
  } catch (error) {
    console.error(error);
  }
}

async function loadData() {
  loader.start();

  const searchParams = new URLSearchParams();
  searchParams.append('without_pagination', '1');
  searchParams.append('statuses[]', UserStatusType.Active);
  searchParams.append('statuses[]', UserStatusType.Invited);

  // Handle preset of filter
  if (props.planningType == ResourcePlanningViewType.MyPlan) {
    await Promise.all([getResourcePlanning()]);
    loader.finish();
  } else {
    filters.employees.push(authenticatedUser.uuid);
    await Promise.all([
      getResourcePlanning(),
      getUsers({ searchParams }),
      getWorkTitles({
        searchParams: {
          without_pagination: 1,
        },
      }),
      getOffices({
        searchParams: {
          without_pagination: 1,
        },
      }),
      getDepartments({
        searchParams: {
          without_pagination: 1,
        },
      }),
    ]);
    loader.finish();
  }
}

onMounted(async () => {
  await loadData();
});

watch(
  () => props.planningType,
  (newPlanning, oldPlanning) => {
    console.log(`Planning type changed changed from ${oldPlanning} to ${newPlanning}`);
    resetExpandedEmployees();
    resetExpandedProjects();
    total.value = undefined;
    employees.value = [];

    resetFilters().finally(() => loadData());
  },
);

const title = useTitle(
  computed(() => {
    if (props.planningType == ResourcePlanningViewType.MyPlan) {
      return t('resource-management.index.my_planning_title');
    } else if (props.planningType == ResourcePlanningViewType.ResourcePlanning) {
      return t('resource-management.index.title');
    } else {
      return t('resource-management.index.resource_overview_title');
    }
  }),
);

function getTimeColor(available: number, planned: number) {
  const difference = planned - available;
  const tolerance = available * 0.1;
  if (Math.abs(difference) <= tolerance) {
    return 'text-success-500';
  } else if (planned < available - tolerance) {
    return 'text-warning-500';
  }
  return 'text-danger-500';
}

function isProjectDraggable(project: IResourcePlanning['employees'][0]['projects'][0]) {
  return project.tasks.some((task) => task.events.some((event) => event.done_at === null));
}

const sourceTaskIdDragging = ref(0);
const sourceWeekDragging = ref(0);

function onDragEventStart(
  dragEvent: DragEvent,
  event: IResourcePlanningEmployeeEvent,
  sourceTask: IResourcePlanningEmployeeTask,
  employeeUuid: string,
) {
  if (!dragEvent.dataTransfer) {
    return;
  }
  dragEvent.stopPropagation();
  (dragEvent.target as HTMLDivElement).classList.add('dnd-dragging');
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('events', event.id.toString());
  dragEvent.dataTransfer.setData('source_event_week', event.week.toString());
  dragEvent.dataTransfer.setData('source_task_id', sourceTask.id.toString());
  dragEvent.dataTransfer.setData('source_employee_uuid', employeeUuid);
  dragEvent.dataTransfer.setData('drag_type', 'event');

  sourceTaskIdDragging.value = sourceTask.id;
  sourceWeekDragging.value = event.week;
}

function onDragEventEnd(dragEvent: DragEvent) {
  (dragEvent.target as HTMLDivElement).classList.remove('dnd-dragging');
  sourceTaskIdDragging.value = 0;
  sourceWeekDragging.value = 0;
}

function onDragover(dragEvent: DragEvent, targetTask: IResourcePlanningEmployeeTask, targetWeek: number) {
  if (
    !dragEvent.dataTransfer ||
    sourceTaskIdDragging.value !== targetTask.id ||
    targetWeek < sourceWeekDragging.value
  ) {
    return;
  }
  (dragEvent.target as HTMLElement).closest('td.dnd-droppable')?.classList.add('over');
}

function onDragleave(dragEvent: DragEvent) {
  (dragEvent.target as HTMLElement).closest('td.dnd-droppable')?.classList.remove('over');
}

// Drag and drop tasks
function onDragTaskStart(
  dragEvent: DragEvent,
  sourceTask: IResourcePlanningEmployeeTask,
  sourceEmployee: IResourcePlanning['employees'][0],
) {
  if (!dragEvent.dataTransfer) {
    return;
  }
  dragEvent.stopPropagation();
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('events', sourceTask.events.map(({ id }) => id).join(','));
  dragEvent.dataTransfer.setData('changeable_future_responsible', String(sourceTask.frequency !== null));
  dragEvent.dataTransfer.setData('source_employee_uuid', sourceEmployee.uuid);
  dragEvent.dataTransfer.setData('drag_type', 'task');
  (dragEvent.target as HTMLDivElement).classList.add('dnd-dragging');
}

function onDragTaskEnd(dragEvent: DragEvent) {
  (dragEvent.target as HTMLDivElement).classList.remove('dnd-dragging');
}

// Drag and drop project
function onDragProjectStart(
  dragEvent: DragEvent,
  project: IResourcePlanning['employees'][0]['projects'][0],
  employee: IResourcePlanning['employees'][0],
) {
  if (!dragEvent.dataTransfer) {
    return;
  }
  dragEvent.stopPropagation();
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('drag_type', 'project');
  dragEvent.dataTransfer.setData(
    'events',
    project.tasks
      .reduce((ids, task) => {
        return [...ids, ...task.events.filter((event) => event.done_at === null).map((event) => event.id)];
      }, [] as number[])
      .join(','),
  );
  dragEvent.dataTransfer.setData(
    'changeable_future_responsible',
    String(project.tasks.some((task) => task.frequency !== null)),
  );
  dragEvent.dataTransfer.setData('source_employee_uuid', employee.uuid);
  (dragEvent.target as HTMLDivElement).classList.add('dnd-dragging');
}

function onDragProjectEnd(dragEvent: DragEvent) {
  (dragEvent.target as HTMLDivElement).classList.remove('dnd-dragging');
}

// Dragover and dragleave employee
function dragoverEmployee(dragEvent: DragEvent) {
  (dragEvent.target as HTMLElement).closest('tr.dnd-droppable')?.classList.add('over');
}

function dragleaveEmployee(dragEvent: DragEvent) {
  (dragEvent.target as HTMLElement).closest('tr.dnd-droppable')?.classList.remove('over');
}

async function move(body: IMoveEventsRequestBody) {
  try {
    dropLoader.start();
    await api.events.move(body);
    toast.success(t('common.updated'));
    await getResourcePlanning();
  } catch (error) {
    console.error(error);
  } finally {
    dropLoader.finish();
  }
}

async function onDropEvents(
  dragEvent: DragEvent,
  targetWeek: number,
  opts: { dropType: 'employee'; employeeUuid: string } | { dropType: 'task' },
) {
  dragEvent.preventDefault();
  dragEvent.stopPropagation();
  (dragEvent.target as HTMLElement).closest('.dnd-droppable')?.classList.remove('over');
  if (!dragEvent.dataTransfer || !dragEvent.dataTransfer.getData('events')) {
    toast.error(t('common.messages.forbidden_to_move'));
    return;
  }
  const ids = dragEvent.dataTransfer.getData('events').split(',').map(Number);
  const changeableFutureResponsible = dragEvent.dataTransfer.getData('changeable_future_responsible') === 'true';
  const sourceEmployeeUuid = dragEvent.dataTransfer.getData('source_employee_uuid');
  const sourceEventWeek = Number(dragEvent.dataTransfer?.getData('source_event_week'));
  const dragType = dragEvent.dataTransfer.getData('drag_type') as 'event' | 'task' | 'project';
  if (
    (opts.dropType === 'task' && dragType === 'event' && sourceEventWeek === targetWeek) ||
    (opts.dropType === 'task' && (dragType === 'task' || dragType === 'project')) ||
    (opts.dropType === 'employee' && sourceEmployeeUuid === opts.employeeUuid)
  ) {
    //toast.error(t('common.messages.forbidden_to_move'));
    return;
  }
  const body: IMoveEventsRequestBody = {
    ids,
  };
  if (opts.dropType === 'employee') {
    if (dragType === 'event') {
      body.week = sourceEventWeek;
    }
    // body.week = dragType === 'event' ? sourceEventWeek : targetWeek;
    body.user_uuid = opts.employeeUuid;
  } else {
    body.week = targetWeek;
  }
  if (changeableFutureResponsible) {
    const { open, close, destroy } = useModal({
      component: ChangeFutureResponsibleModal,
      attrs: {
        onConfirm({ changeFutureResponsible }: { changeFutureResponsible: boolean }) {
          body.change_future_responsible = changeFutureResponsible;
          move(body);
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  } else {
    await move(body);
  }
}

function onProjectTaskEdit(id: number) {
  openProjectTaskEditModal(
    { id },
    {
      onUpdated({ close }) {
        getResourcePlanning();
        close();
      },
      onSplit({ close }) {
        getResourcePlanning();
        close();
      },
      onDeleted({ close }) {
        getResourcePlanning();
        close();
      },
    },
  );
}

function onActivityEdit(id: number) {
  openActivityTaskEditModal(
    { id },
    {
      onSplit({ close }) {
        getResourcePlanning();
        close();
      },
      onUpdated({ close }) {
        getResourcePlanning();
        close();
      },
      onDeleted({ close }) {
        getResourcePlanning();
        close();
      },
    },
  );
}
const fromDate = computed(() => {
  if (props.planningType == ResourcePlanningViewType.EmployeeOverview) {
    return DateTime.now().toJSDate();
  } else {
    return DateTime.now().minus({ month: 1 }).toJSDate();
  }
});
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="resource-management.index.help" />
    </div>
    <AppCollapse opened class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-3 align-items-start">
          <!-- From -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-from" required>
              {{ t('resource-management.filters.from') }}
            </FormLabel>
            <FormWeekPicker
              id="filter-from"
              v-model="filters.from"
              :disabled="filtersLoader.isLoading.value"
              :min-date="fromDate"
              :max-date="DateTime.fromFormat(filters.to.toString(), 'kkkkWW').endOf('week').toJSDate()"
            />
          </div>
          <!-- To -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-to" required>
              {{ t('resource-management.filters.to') }}
            </FormLabel>
            <FormWeekPicker
              id="filter-to"
              v-model="filters.to"
              :disabled="filtersLoader.isLoading.value"
              :min-date="DateTime.fromFormat(filters.from.toString(), 'kkkkWW').startOf('week').toJSDate()"
            />
          </div>
          <!-- Offices -->
          <div class="form-group col" v-if="props.planningType != ResourcePlanningViewType.MyPlan">
            <FormLabel html-for="filter_offices">
              {{ t('resource-management.filters.offices') }}
            </FormLabel>
            <VueSelect
              v-model="filters.offices"
              :options="offices"
              :reduce="(option: IOfficeListResource) => option.id"
              label="name"
              input-id="filter_offices"
              :placeholder="t('common.all')"
              :disabled="filtersLoader.isLoading.value"
              :loading="officesLoading"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
          <!-- Departments -->
          <div class="form-group col" v-if="props.planningType != ResourcePlanningViewType.MyPlan">
            <FormLabel html-for="filter_departments">
              {{ t('resource-management.filters.departments') }}
            </FormLabel>
            <VueSelect
              v-model="filters.departments"
              :options="departments"
              :reduce="(option: IDepartmentResource) => option.id"
              label="name"
              input-id="filter_departments"
              :placeholder="t('common.all')"
              :disabled="filtersLoader.isLoading.value"
              :loading="departmentsLoading"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
          <!-- Work titles -->
          <div class="form-group col" v-if="props.planningType != ResourcePlanningViewType.MyPlan">
            <FormLabel html-for="filter_work_titles">
              {{ t('resource-management.filters.work_titles') }}
            </FormLabel>
            <VueSelect
              v-model="filters.work_titles"
              :options="workTitles"
              :reduce="(option: IWorkTitleResource) => option.id"
              label="name"
              input-id="filter_work_titles"
              :placeholder="t('common.all')"
              :disabled="filtersLoader.isLoading.value"
              :loading="workTitlesLoading"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
          <!-- Employees -->
          <div class="form-group col" v-if="props.planningType != ResourcePlanningViewType.MyPlan">
            <FormLabel html-for="filter_employees">
              {{ t('resource-management.filters.employees') }}
            </FormLabel>
            <VueSelect
              v-model="filters.employees"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter_employees"
              :placeholder="t('common.all')"
              :disabled="filtersLoader.isLoading.value"
              :loading="usersLoading"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="filtersLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filtersLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="employees.length === 0">
        {{ t('common.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr v-if="total">
              <AppTableTh colspan="3">{{ t('resource-management.attributes.total') }}</AppTableTh>
              <AppTableTh class="text-right">
                {{ new Decimal(total.planned).div(60).toDecimalPlaces(2).toNumber() }}
              </AppTableTh>
              <AppTableTh class="text-right">
                {{ new Decimal(total.available).div(60).toDecimalPlaces(2).toNumber() }}
              </AppTableTh>
              <AppTableTh class="text-right">
                <span :class="[getTimeColor(total.available, total.planned)]">
                  {{ Decimal.sub(total.available, total.planned).div(60).toDecimalPlaces(2).toNumber() }}
                </span>
              </AppTableTh>
              <AppTableTh v-for="item in total.weeks" :key="item.week" class="text-center">
                <span :class="[getTimeColor(item.available, item.planned)]">
                  {{ Decimal.sub(item.available, item.planned).div(60).toDecimalPlaces(2).toNumber() }}
                </span>
              </AppTableTh>
              <AppTableTh />
            </AppTableTr>
            <AppTableTr>
              <AppTableTh>{{ t('resource-management.attributes.name') }}</AppTableTh>
              <AppTableTh>{{ t('resource-management.attributes.office') }}</AppTableTh>
              <AppTableTh>{{ t('resource-management.attributes.department') }}</AppTableTh>
              <AppTableTh class="text-right" nowrap>{{ t('resource-management.attributes.planned') }}</AppTableTh>
              <AppTableTh class="text-right" nowrap>{{ t('resource-management.attributes.available') }}</AppTableTh>
              <AppTableTh class="text-right">{{ t('resource-management.attributes.net') }}</AppTableTh>
              <AppTableTh class="text-center" v-for="item in employees[0].weeks" :key="item.week">
                {{ t('common.week_short') }}.{{ item.week }}
              </AppTableTh>
              <AppTableTh />
            </AppTableTr>
          </AppTableHead>
          <AppTableBody
            v-for="employee in employees"
            :key="employee.uuid"
            :class="{ 'is-expanded': isExpandedEmployee(employee.uuid) }"
          >
            <AppTableTr
              @dragenter.prevent
              @drop.prevent="onDropEvents($event, filters.from, { dropType: 'employee', employeeUuid: employee.uuid })"
              @dragover.prevent="dragoverEmployee"
              @dragleave.prevent="dragleaveEmployee"
              class="dnd-droppable"
            >
              <AppTableTd nowrap>
                <AppButton
                  @click.prevent="toggleEmployee(employee.uuid)"
                  v-show="props.planningType != ResourcePlanningViewType.EmployeeOverview"
                  size="small"
                  light
                  circle
                >
                  <FontIcon :name="isExpandedEmployee(employee.uuid) ? 'chevron-down' : 'chevron-right'" />
                </AppButton>
                <span class="ml-2">{{ employee.name }}</span>
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="employee.office" v-text="employee.office" />
                <EmptyValue v-else />
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="employee.department" v-text="employee.department" />
                <EmptyValue v-else />
              </AppTableTd>
              <AppTableTd class="text-right">
                {{ new Decimal(employee.planned).div(60).toDecimalPlaces(2).toNumber() }}
              </AppTableTd>
              <AppTableTd class="text-right">
                {{ new Decimal(employee.available).div(60).toDecimalPlaces(2).toNumber() }}
              </AppTableTd>
              <AppTableTd class="text-right">
                <span :class="[getTimeColor(employee.available, employee.planned)]">
                  {{ Decimal.sub(employee.available, employee.planned).div(60).toDecimalPlaces(2).toNumber() }}
                </span>
              </AppTableTd>
              <AppTableTd v-for="item in employee.weeks" :key="item.week" class="text-center">
                <span :class="[getTimeColor(item.available, item.planned)]">
                  {{ Decimal.sub(item.available, item.planned).div(60).toDecimalPlaces(2).toNumber() }}
                </span>
              </AppTableTd>
              <AppTableTd class="text-right">
                <RouterLink custom to="/" v-slot="{ href, navigate }">
                  <a @click="navigate" :href="href" target="_blank" class="disabled">
                    <AppButton circle light size="small">
                      <FontIcon name="chart-line" />
                    </AppButton>
                  </a>
                </RouterLink>
              </AppTableTd>
            </AppTableTr>
            <template v-if="isExpandedEmployee(employee.uuid)">
              <template v-for="project in employee.projects" :key="project.id">
                <AppTableTr>
                  <AppTableTd colspan="3" />
                  <AppTableTd colspan="3">
                    <AppButton
                      @click.prevent="toggleProject(`${employee.uuid}-${project.id}`)"
                      size="small"
                      light
                      circle
                    >
                      <FontIcon
                        :name="isExpandedProject(`${employee.uuid}-${project.id}`) ? 'chevron-down' : 'chevron-right'"
                      />
                    </AppButton>
                    <strong
                      :draggable="isProjectDraggable(project)"
                      @dragstart="onDragProjectStart($event, project, employee)"
                      @dragend="onDragProjectEnd($event)"
                      :class="{ pointer: isProjectDraggable(project) }"
                      class="ml-2"
                    >
                      {{ project.company.name }} | {{ project.name }}
                    </strong>
                  </AppTableTd>
                  <AppTableTd class="text-center" v-for="item in project.weeks" :key="item.week">
                    <span v-if="item.time > 0">
                      {{ convertMinutesToTime(item.time) }}
                    </span>
                  </AppTableTd>
                  <AppTableTd />
                </AppTableTr>
                <template v-if="isExpandedProject(`${employee.uuid}-${project.id}`)">
                  <ResourceManagementTask
                    v-for="task in project.tasks"
                    :key="task.id"
                    :task="task"
                    :weeks="employee.weeks.map(({ week }) => week)"
                    :employee-uuid="employee.uuid"
                    :selected="(id: string) => id === selectedRow"
                    task-draggable
                    event-draggable
                    @select="selectedRow = $event"
                    @dragstart="onDragEventStart"
                    @dragend="onDragEventEnd"
                    @drop="onDropEvents"
                    @dragover="onDragover"
                    @dragleave="onDragleave"
                    @dragstart-task="onDragTaskStart($event, task, employee)"
                    @dragend-task="onDragTaskEnd($event)"
                    @time-report="onProjectTaskEdit($event)"
                  />
                </template>
              </template>
              <ResourceManagementTask
                v-for="activity in employee.activities"
                :key="activity.id"
                :task="activity"
                :employee-uuid="employee.uuid"
                :weeks="employee.weeks.map(({ week }) => week)"
                :selected="(id: string) => id === selectedRow"
                :task-draggable="false"
                event-draggable
                @select="selectedRow = $event"
                @dragstart="onDragEventStart"
                @dragend="onDragEventEnd"
                @drop="onDropEvents"
                @dragover="onDragover"
                @dragleave="onDragleave"
                @time-report="onActivityEdit($event)"
              />
            </template>
          </AppTableBody>
        </AppTable>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.is-expanded {
  outline: 1px solid var(--color-neutral-400-hex);
}
</style>
