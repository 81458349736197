<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';

import api from '@/services/api';
import { AppLoader, AppPagination, HelpInformation, InboxFree, InvoicesTable } from '@/components';
import useLoader from '@/composables/useLoader';
import { IInvoicePreviewResource, InvoiceInternalStatusType } from '@/types/Invoice';
import useAuthStore from '@/store/AuthStore';
import { useTitle } from '@vueuse/core';

const currentPage = useRouteQuery('page', '1', { transform: Number });
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { authenticatedUser, isCustomerAdminRole, isSuperAdminRole, isManagerRole } = useAuthStore();

const totalSum = ref(0);
const totalExternalSum = ref(0);
const perPage = ref(20);
const total = ref(0);

const invoices = ref<IInvoicePreviewResource[]>([]);

const canSeeAll = computed(() => isManagerRole || isSuperAdminRole || isCustomerAdminRole);

// Filters
const filterShowAll = useRouteQuery<number>('show_all', 0, { transform: Number });

const filtersUntouched = computed(() => filterShowAll.value === 0);

async function getInvoices() {
  try {
    loader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('order_by_client', '1');
    searchParams.append('page', currentPage.value.toString());
    searchParams.append('user_uuid[]', authenticatedUser!.uuid);
    searchParams.append('internal_status[]', InvoiceInternalStatusType.DRAFT);
    searchParams.append('internal_status[]', InvoiceInternalStatusType.ACTION_REQUIRED);
    searchParams.append('internal_status[]', InvoiceInternalStatusType.UNMATCHED);
    if (filterShowAll.value && canSeeAll.value) {
      searchParams.delete('user_uuid[]');
    }
    const response = await api.invoices.index({ searchParams });
    invoices.value = response.data;
    totalSum.value = parseInt(response.total_sum);
    totalExternalSum.value = parseInt(response.total_external_sum);
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

onMounted(getInvoices);

watch(currentPage, getInvoices);

const title = useTitle(computed(() => t('invoice.drafts.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end mb-3">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="invoice.drafts.help" />
    </div>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <InboxFree v-if="invoices.length === 0 && filtersUntouched" :text="t('invoice.drafts.inbox_free')" />
      <template v-else>
        <div class="form-group" v-if="canSeeAll">
          <input
            id="filter_show_all"
            type="checkbox"
            class="form-check"
            v-model="filterShowAll"
            :true-value="1"
            :false-value="0"
            @change="getInvoices"
          />
          <label for="filter_show_all" class="form-label" v-text="t('invoice.filters.show_all')" />
        </div>
        <InvoicesTable
          :total="totalSum"
          :total-external="totalExternalSum"
          :invoices="invoices"
          :columns="['id', 'client', 'user', 'amount', 'created_at', 'internal_status', 'actions']"
          @onDeleted="getInvoices"
        />
        <template v-if="perPage && total">
          <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
        </template>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.inbox-free-icon {
  max-width: 260px;
}
</style>
