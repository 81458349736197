import api from '@/services/api';
import { ref } from 'vue';
import { Options } from 'ky';
import { IServiceCompanyListResource } from '@/types/ServiceCompany';

type GetServiceCompaniesHooks = {
  onSet?: (rawServiceCompanies: IServiceCompanyListResource[]) => IServiceCompanyListResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useServiceCompanies() {
  const serviceCompanies = ref<IServiceCompanyListResource[]>([]);
  const serviceCompaniesLoading = ref(false);

  async function getServiceCompanies(options?: Options, hooks?: GetServiceCompaniesHooks) {
    try {
      serviceCompaniesLoading.value = true;
      const response = await api.serviceCompanies.list(options);
      serviceCompanies.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error: unknown) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      serviceCompaniesLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }

  return {
    serviceCompanies,
    serviceCompaniesLoading,
    getServiceCompanies,
  };
}
