<script setup lang="ts">
import { ref, computed, onMounted, provide } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useRouteQuery } from '@vueuse/router';
import useLoader from '@/composables/useLoader';
import useProjectStore from '@/store/ProjectStore';
import {
  AppLoader,
  AppButton,
  ProjectStatus,
  HelpInformation,
  ClientHeader,
  ClientNavigation,
  FontIcon,
} from '@/components';
import api from '@/services/api';
import { IProjectResource, ProjectStep } from '@/types/Project';
import ProjectService from '@/components/Projects/ProjectService.vue';
import ProjectPrice from '@/components/Projects/ProjectPrice.vue';
import ProjectTeam from '@/components/Projects/ProjectTeam.vue';
import ProjectPlanning from '@/components/Projects/ProjectPlanning.vue';
import ProjectActivate from '@/components/Projects/ProjectActivate.vue';
import useProject from '@/composables/useProject';
import useClientStore from '@/store/ClientStore';
import { useTitle } from '@vueuse/core';
import usePermissions from '@/composables/usePermissions';

const router = useRouter();
const projectStore = useProjectStore();
const { projectSteps, openReactivateProjectModal, openDeleteProjectModal, openCancelOrRolloverProjectModal } =
  useProject();
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { can } = usePermissions();

const { setProject, unsetProject } = projectStore;
const { project, lockedTabs } = storeToRefs(projectStore);

const props = defineProps<{
  id?: number;
  uuid: string;
}>();

const step = useRouteQuery<ProjectStep>('step', ProjectStep.Service);
const loaded = ref(false);

const clientStore = useClientStore();
const route = useRoute();
const { client, isUserPartOfTeam } = storeToRefs(clientStore);

const title = computed(() => {
  if ('rollover_project_name' in route.query) {
    return t('project.rollover_modal.title', { name: route.query.rollover_project_name });
  }
  return props.id
    ? t('project.edit.title', { name: project.value?.name })
    : t('project.create.title', { name: client.value.name });
});

const editMode = computed(() => !!props.id);

function onCancelOrRollover(type: 'cancel' | 'rollover', project: IProjectResource) {
  openCancelOrRolloverProjectModal(
    type,
    {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
      service: project.service,
    },
    props.uuid,
    {
      onDone(_updateProject, { newProjectPlan }) {
        if (!newProjectPlan) {
          window.location.reload();
        }
      },
    },
  );
}

function reactivateProject(project: IProjectResource) {
  openReactivateProjectModal({ id: project.id, name: project.name }, props.uuid, {
    onDone() {
      getProject();
    },
  });
}

function deleteProject(project: IProjectResource) {
  openDeleteProjectModal({ id: project.id, name: project.name }, props.uuid, {
    onDone() {
      router.push({ name: 'client.projects', params: { uuid: props.uuid } });
    },
  });
}

async function getProject() {
  loader.start();
  if (props.id) {
    try {
      const response = await api.projects.get(props.uuid, props.id);
      setProject(response.data);
    } catch (error) {
      console.error(error);
    }
  } else {
    unsetProject();
  }
  loader.finish();
  loaded.value = true;
}

onMounted(async () => {
  await getProject();
});

provide('projectId', props.id);
provide('clientUuid', props.uuid);
provide('editMode', editMode.value);

useTitle(computed(() => title.value));
</script>

<template>
  <div class="container-fluid px-md-5">
    <ClientHeader :client="client" />
    <ClientNavigation :client="client" :is-user-part-of-team="isUserPartOfTeam" />

    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-if="loaded">
      <!-- Project header -->
      <div class="my-4 d-flex d-flex align-items-center flex-nowrap">
        <div class="d-flex align-items-center flex-nowrap">
          <div class="d-flex align-items-end">
            <h2 class="mb-0" v-text="title" />
            <HelpInformation class="ml-1" :translation="editMode ? 'project.edit.help' : 'project.create.help'" />
          </div>
        </div>
        <template v-if="project">
          <ProjectStatus class="mx-3" :status="project.status" />
          <div class="ml-auto">
            <AppButton
              v-if="can('projects.reactivate', project.status)"
              @click.prevent="reactivateProject(project)"
              class="mr-2"
              color="success"
              light
            >
              {{ t('project.index.reactivate') }}
            </AppButton>
            <AppButton
              v-if="can('projects.cancel', project.status)"
              @click.prevent="onCancelOrRollover('cancel', project)"
              class="mr-2"
              color="danger"
              light
            >
              <FontIcon name="ban" />
              {{ t('project.index.cancel') }}
            </AppButton>
            <AppButton
              v-if="can('projects.cancel', project.status)"
              @click.prevent="onCancelOrRollover('rollover', project)"
              class="mr-2"
              color="secondary"
              light
            >
              <FontIcon name="reload" />
              {{ t('project.index.rollover') }}
            </AppButton>
            <AppButton
              v-if="can('projects.delete', project.status)"
              @click.prevent="deleteProject(project)"
              color="danger"
              light
            >
              <FontIcon name="trash" />
              {{ t('project.index.remove') }}
            </AppButton>
          </div>
        </template>
      </div>
      <!-- / Project header -->

      <!-- Project tabs -->
      <ul class="tabs">
        <template v-for="(options, name) in projectSteps" :key="name">
          <template v-if="options.visible">
            <li
              class="tab-item"
              :class="{
                active: step === name,
                disabled:
                  lockedTabs ||
                  (!editMode && name !== ProjectStep.Service) ||
                  options.order > projectSteps[project?.step || ProjectStep.Service].order,
              }"
            >
              <a v-if="editMode" class="tab-link" @click.prevent="step = name" href="#">
                <i v-if="options.order > projectSteps[project?.step || ProjectStep.Service].order" class="ti ti-lock" />
                <FontIcon v-else :name="options.icon" />
                {{ t(`project.step.${name}`) }}
              </a>
              <span v-else class="tab-link">
                <i v-if="name !== ProjectStep.Service" class="ti ti-lock" />
                <FontIcon v-else :name="options.icon" />
                {{ t(`project.step.${name}`) }}
              </span>
            </li>
          </template>
        </template>
      </ul>
      <!-- / Project tabs -->

      <div class="mt-4">
        <ProjectService v-if="step === ProjectStep.Service" @change-step="(event) => (step = event)" />
        <ProjectPrice v-if="step === ProjectStep.Price" @change-step="(event) => (step = event)" />
        <ProjectTeam v-if="step === ProjectStep.Team" @change-step="(event) => (step = event)" />
        <ProjectPlanning v-if="step === ProjectStep.Planning" @change-step="(event) => (step = event)" />
        <ProjectActivate v-if="step === ProjectStep.Activate" @change-step="(event) => (step = event)" />
      </div>
    </template>
  </div>
</template>
