export default {
  resource: {
    name: 'Invoice | Invoices',
  },
  index: {
    title: 'Invoices',
    help: '',
    create_periodical_invoices: 'Generate invoices',
    create_separate_invoice: 'Create separate invoice',
  },

  create_final_invoice: {
    title: 'Final invoicing of projects',
    add_not_invoiced_prices: 'Do you want to add fees for all performed, but not invoiced work, up until today?',
    add_future_fixed_prices: 'Do you want to add any fixed price fees that are scheduled for future periods?',
    add_to_existing_invoices: 'Add prices to any existing unsent invoice',
  },

  drafts: {
    title: 'Invoice drafts',
    help: '',
    inbox_free: 'No more invoices. Well done!',
  },

  create_periodical_invoices: {
    title: 'Generate invoices',
    description:
      'You as an admin can generate the firms’ invoices here, for the month you select. An invoice is created for all customers who either have reported time in the month preceding the selected period and those who have an invoice scheduled for the selected month, or not invoiced prices from previous months.<br><br>If you regenerate invoices for a month, any additional time or prices will be added.',
    generating_warn:
      'Invoices are already being generated. Please start the generation process only if there have been any changes.',
  },
  create_separate_invoice: {
    title: 'Create separate invoice',
  },

  edit: {
    title: 'Edit invoice: (id#{id})',
    help: '',
  },

  fetch_not_invoiced: {
    title: 'Fetch not invoiced amounts',
    text: 'There are multiple projects for this client. Choose which projects you want to invoice.',
    add_to_existing_invoices: 'Add prices to any existing not sent invoice',
  },

  form: {
    name: 'Name',
  },

  empty: 'No invoices',

  // Attributes
  attributes: {
    client: 'Client name',
    client_reference: 'Client reference',
    user: 'Our reference',
    amount: 'Amount',
    created_at: 'Creation date',
    updated_at: 'Updated at',
    internal_status: 'Internal status',
    external_status: 'External status',
    synced_at: 'Sync date',
    sync_status: 'Sync status',
    external_amount: 'External amount',
    difference: 'Difference',
    invoice_language: 'Invoice language',
    other_information: 'Other invoice information',
    billing_adress: 'Client billing adress',
    internal_note: 'Internal note',
    invoice_text: 'Invoice text',
  },

  not_settled_prepayments: 'Not settled prepayments',
  not_settled_prepayments_text:
    'Previously invoiced, but not settled remaining advance: <strong>{value}</strong>.<br>Add a new line with the price type Advance and enter a negative amount to net the fee against the advance.',
  negative_advance:
    'You have made deductions for advances with a higher amount than the balance. As a suggestion you could use price type “Deduction” to reduce invoice amount.',
  no_positive_prepayment:
    'There is no prepayment to deduct negative prepayment amount from. Use price type “Deduction” to reduce invoice amount.',
  price_doesnt_much_original: 'The total amount does not match the original price. Please check and adjust the values.',
  something_off: 'Something seems off. The invoice item has an inconsistent state. Please review the details.',

  details: {
    project: 'Project',
    estimated_margin_percent: 'Marg%',
    price_type: 'Price type',
    description: 'Description',
    article: 'Article',
    qty: 'Qty',
    unit: 'Unit',
    price_unit: 'Price/Unit',
    discount: 'Discount, %',
    amount: 'Amount',
    postpone_to_next_month: 'Postpone',
    invoicing: 'Invoicing',
    write_off: 'Write-off',
    total: 'Original',
    total_invoice_amount: 'Total invoice amount',
    fetch_not_invoiced_prices: 'Fetch all not invoiced prices',
    approve_and_sync: 'Approve & Sync',
    save_and_sync: 'Save & Sync',
  },

  filters: {
    clients: 'By client',
    users: 'By our reference',
    internal_statuses: 'By internal status',
    external_statuses: 'By external status',
    from_date: 'Created from date',
    to_date: 'Created to date',
    show_deleted: 'Show deleted',
    credited_or_deleted: 'Show credited/deleted',
    show_all: 'Show all',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit invoice',
    delete: 'This deletes the invoice and all invoice items from the system, and can not be recreated later.',
    postpone: 'Postpone',
    write_off: 'Write-off',
    expand: 'Expand',
    minimize: 'Minimize',
    expand_all: 'Expand all',
    minimize_all: 'Minimize all',
    report_link: 'View reported time on project (opens in a new window)',
    billing_link: 'View billing overview (opens in new window)',
    project_link: 'Project overview page (opens in new window)',
  },

  // Confirms
  confirm: {
    delete: {
      action: 'Delete invoice',
      title: 'Delete invoice',
      text: 'Are you sure you want to delete invoice?',
    },
  },

  unit: {
    pieces: 'Pieces',
    hours: 'Hours',
  },

  internal_status: {
    draft: 'Draft',
    approved: 'Approved',
    deleted: 'Deleted',
    unmatched: 'Unmatched',
    matched: 'Matched',
    postponed: 'Postponed',
    action_required: 'Action Required',
    empty: 'Empty',
  },

  external_status: {
    NotSent: 'Not sent',
    Unpaid: 'Unpaid',
    OverdueReminderSent: 'Overdue reminder sent',
    OverdueReminderNotSent: 'Overdue reminder not sent',
    Paid: 'Paid',
    Cancelled: 'Cancelled',
    Collection: 'Collection',
    Expires: 'Expired',
  },

  sync_status: {
    error: 'Error',
    success: 'Success',
  },

  price_type: {
    fixed: 'Fixed',
    hourly: 'Hourly',
    advance: 'Advance',
    extra_fee: 'Extra fee',
    discount: 'Discount',
    outlays: 'Outlays',
  },

  unsaved_modal: {
    title: 'Current account has unsaved changes. What do you want to do?',
    save_continue: 'Save changes and continue',
    discard_continue: 'Discard changes and continue',
  },

  distribution: {
    e_invoice: 'E-Invoice',
    email: 'Email',
    mail: 'Mail',
  },

  unmatched_error:
    'The amount on the sent invoice does not match to the total below. Invoice amount is {external_amount}',

  customer_settings_incomplete: {
    text: "The customer's invoice settings are incomplete.",
    link: 'Update them here.',
  },

  final_invoice_has_been_created: {
    text: 'A final invoice has already been sent for this project.',
  },
};
