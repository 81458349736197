<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import { AppButton, AppCloseButton, FormLabel, FormSwitch } from '@/components';
import { useForm } from 'vee-validate';
import { ICreateFinalInvoiceRequest } from '@/types/Invoice';
import useModalFeatures from '@/composables/useModalFeatures';
import api from '@/services/api';

type Props = {
  projectId: number;
  clientUuid: string;
};

const { projectId, clientUuid } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [id: null | number];
  closed: [];
}>();

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

const { resetForm, defineField, handleSubmit, isSubmitting } = useForm<ICreateFinalInvoiceRequest>({
  initialValues: {
    project_id: projectId,
    client_uuid: clientUuid,
    add_not_invoiced_prices: true,
    add_future_fixed_prices: true,
    add_to_existing_invoices: true,
  },
});

const [addNotInvoicedPrices] = defineField('add_not_invoiced_prices');
const [addFutureFixedPrices] = defineField('add_future_fixed_prices');
const [addToExistingInvoices] = defineField('add_to_existing_invoices');

const submit = handleSubmit(async (values) => {
  try {
    const { id } = await api.invoices.createFinalInvoice(values);
    emit('created', id);
    resetForm();
  } catch (error) {
    console.error(error);
  }
});

onCtrlEnter(() => {
  submit();
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    @closed="emit('closed')"
    v-slot="{ close }"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2>{{ t('invoice.create_final_invoice.title') }}</h2>
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Question 1 -->
        <div class="d-flex align-items-center mb-3">
          <FormSwitch group-class="mb-0" id="add_not_invoiced_prices" v-model="addNotInvoicedPrices" />
          <FormLabel html-for="add_not_invoiced_prices">
            {{ t('invoice.create_final_invoice.add_not_invoiced_prices') }}
          </FormLabel>
        </div>

        <!-- Question 2 -->
        <div class="d-flex align-items-center mb-3">
          <FormSwitch group-class="mb-0" id="add_future_fixed_prices" v-model="addFutureFixedPrices" />
          <FormLabel html-for="add_future_fixed_prices">
            {{ t('invoice.create_final_invoice.add_future_fixed_prices') }}
          </FormLabel>
        </div>

        <!-- Question 3 -->
        <div class="d-flex align-items-center mb-3">
          <FormSwitch group-class="mb-0" id="add_to_existing_invoices" v-model="addToExistingInvoices" />
          <FormLabel html-for="add_to_existing_invoices">
            {{ t('invoice.create_final_invoice.add_to_existing_invoices') }}
          </FormLabel>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex justify-content-between">
          <AppButton light @click.prevent="close" :disabled="isSubmitting">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton color="secondary" :loading="isSubmitting">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
