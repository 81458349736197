import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import Progress from '@/views/Progress.vue';

const prefix = 'statistics';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}/progress`,
    name: 'statistics.progress',
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Progress,
  },
];

export default routes;
