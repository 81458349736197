<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { KyInstance } from 'ky';
import { WeFiles, WeTemplates } from '@wesoft/we-components';
import useClientStore from '@/store/ClientStore';

type Props = { uuid: string; visibility: boolean };
const { locale } = useI18n({ useScope: 'global' });
const { uuid, visibility } = defineProps<Props>();
const clientStore = useClientStore();
const { filesSettings } = storeToRefs(clientStore);

const extendWeFilesHttpClient = (kyInstance: KyInstance) => {
  return kyInstance.extend({
    prefixUrl: filesSettings.value.api_base_url,
    headers: {
      Authorization: `Bearer ${filesSettings.value.token}`,
      Accept: 'application/json',
    },
  });
};
</script>

<template>
  <div :class="{ hidden: !visibility }">
    <WeTemplates
      :extend-we-files-http-client="extendWeFilesHttpClient"
      :default-expanded="true"
      :locale="locale"
      :customer-uuid="uuid"
    />

    <WeFiles
      class="mt-5"
      :extend-we-files-http-client="extendWeFilesHttpClient"
      :locale="locale"
      :customer-uuid="uuid"
    />
  </div>
</template>

<style scoped>
.hidden {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
</style>
