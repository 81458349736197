import { IEventAffectedWeeks } from '@/types/Event';
import httpClient from '@/services/api/ky';
import route from 'ziggy-js';

export default {
  delete(userUuid: string, activityId: number): Promise<IEventAffectedWeeks> {
    return httpClient
      .delete(route('activities.users.delete', { user: userUuid, activity: activityId }))
      .json<IEventAffectedWeeks>();
  },
};
