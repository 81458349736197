import { defineStore } from 'pinia';
import { ref } from 'vue';

const useUserTodosStore = defineStore('UserTodosStore', () => {
  const userTodosCount = ref(0);
  function setUserTodosCount(count: number) {
    userTodosCount.value = count;
  }
  const userTodosLoading = ref(false);
  function setUserTodosLoading(value: boolean) {
    userTodosLoading.value = value;
  }

  const userInfosCount = ref(0);
  function setUserInfosCount(count: number) {
    userInfosCount.value = count;
  }
  const userInfosLoading = ref(false);
  function setUserInfosLoading(value: boolean) {
    userInfosLoading.value = value;
  }
  return {
    userTodosCount,
    setUserTodosCount,
    userTodosLoading,
    setUserTodosLoading,

    userInfosCount,
    setUserInfosCount,
    userInfosLoading,
    setUserInfosLoading,
  };
});

export default useUserTodosStore;
